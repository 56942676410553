import $ from "jquery";
import "intersection-observer";
import scrollama from "scrollama";

let throttler;

/*
 * Stats
 * Provides scroll effects the stats component
 */
export class Stats {
  constructor(el) {
    this.el = el;
    this.scroller = scrollama();



    $(this.el).each(function() {
      if ($(this).offset().top > $(window).scrollTop()) {
        $(this).addClass("is-shifted");
      }
    });

    this.scroller.setup({
      step: this.el,
      progress: true,
      offset:  ($(window).height() - 100) / $(window).height(),
      debug: false,
      threshold: 1
    })
      .onStepEnter(this.handleStepEnter);

    this.bindEvents();
  }

  bindEvents() {
    let thisScroller = this;

    /* Reset trigger point on resize */
    $(window).on("resize", event => {
      if (throttler) {
        window.clearTimeout(throttler);
      }
      throttler = setTimeout(() => {
        thisScroller.scroller.offsetTrigger(($(window).height() - 100) / $(window).height());
        thisScroller.scroller.resize();
      }, 400);
    });
  }

  handleStepEnter(response) {
    response.element.classList.remove("is-shifted");
  }
}
