import $ from "jquery";
import "intersection-observer";
import scrollama from "scrollama";
import { scaleLinear } from "d3-scale";

let throttler;


/*
 * AnimatedHero
 * Provides scroll effects for the Drexel Core Home Hero hero__overlay
 * Note that this is very specific to this design element, and is not generalized
 */
export class AnimatedHero {
  constructor(el) {
    this.el = el;
    this.scroller = scrollama();
    /* Force wordmark out of view */
    $(this.el).find(".animated-hero__overlay").css({
      opacity: 0,
      height: "300%",
      width: "300%"
    });

    this.scroller.setup({
      step: this.el,
      progress: true,
      offset: $(this.el).offset().top / $(window).height(),
      debug: false,
      threshold: 1
    })
      .onStepProgress(this.handleStepProgress)
      .onStepEnter(this.handleStepEnter);

    this.bindEvents();
  }

  bindEvents() {
    let thisScroller = this;

    /* Reset trigger point on resize */
    $(window).on("resize", event => {
      if (throttler) {
        window.clearTimeout(throttler);
      }
      throttler = setTimeout(() => {
        thisScroller.scroller.offsetTrigger($(thisScroller.el).offset().top / $(window).height());
        thisScroller.scroller.resize();
      }, 400);
    });
  }

  handleStepProgress(response) {
    let $image = $(response.element).find("img");
    let ratio = $image.width() / $image.height();
    /* Differently proportioned images benefit from different timing here */
    let domainstart = ratio > 1.5 ? 0.05 : 0.1;
    let domainend = ratio > 1.5 ? 0.5 : 0.6;

    /* Scale the progress value based on animation start and end points */
    let scale = scaleLinear()
      .domain([domainstart, domainend])
      .range([0, 1])
      .clamp(true);

    let progressShifted = scale(response.progress);

    $(response.element).find(".animated-hero__overlay").css({
      opacity: progressShifted * 1,
      height: (300 - (progressShifted * 200)) + "%",
      width: (300 - (progressShifted * 200)) + "%",
      bottom: (100 - (progressShifted * 100)) + "%"
    });
  }

  handleStepEnter(response) {
    if (response.direction === "up") {
      /* Reset everything to make certain the animation doesn't get "stuck" */
      $(response.element).find(".animated-hero__overlay").css({
        opacity: 0,
        height: "300%",
        width: "300%",
        bottom: "100%"
      });
    }
  }
}
