export class Tabs {
  constructor(el) {
    this.tabPanels = el.querySelectorAll("[role='tabpanel']");
    this.tablistTabs = el.querySelectorAll("[role='tab']");

    this.tablistTabs.forEach((tab) => {
      tab.addEventListener("click", (e) => {
        const thisTab = e.target;
        const thisTabPanel = el.querySelector(
          "#" + thisTab.getAttribute("aria-controls"),
        );

        this.tabPanels.forEach((tabPanel) => {
          if (tabPanel !== thisTabPanel) {
            tabPanel.classList.remove("is-active");
            tabPanel.setAttribute("aria-hidden", true);
          } else {
            tabPanel.classList.add("is-active");
            tabPanel.setAttribute("aria-hidden", false);
          }
        });

        this.tablistTabs.forEach((tab) => {
          if (tab !== thisTab) {
            tab.classList.remove("is-active");
            tab.setAttribute("aria-selected", false);
          } else {
            tab.classList.add("is-active");
            tab.setAttribute("aria-selected", true);
          }
        });
      });
    });
  }
}
