import $ from "jquery";
// Removing the mapbox import so that it doesn't get bundled into libraries-generated.js and break all functionality for IE 11.  Mapbox is now loaded via a CDN script link in the page directly.
import "intersection-observer";

// Control implemented as ES6 class
class ResetZoomControl {
  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    const fitButtonEl = document.createElement("button");
    fitButtonEl.className = "mapboxgl-ctrl-shrink js-map-zoom-fit";
    fitButtonEl.setAttribute("type", "button");
    fitButtonEl.setAttribute("title", "Zoom to fit");
    fitButtonEl.setAttribute("aria-label", "Zoom to fit");

    const iconEl = document.createElement("span");
    iconEl.className = "mapboxgl-ctrl-icon";
    iconEl.setAttribute("aria-hidden", "true");
    fitButtonEl.append(iconEl);

    const resetButtonEl = document.createElement("button");
    resetButtonEl.className = "mapboxgl-ctrl-geolocate js-map-reset";
    resetButtonEl.setAttribute("type", "button");
    resetButtonEl.setAttribute("title", "Reset map");
    resetButtonEl.setAttribute("aria-label", "Reset map");

    const resetIconEl = document.createElement("span");
    resetIconEl.className = "mapboxgl-ctrl-icon";
    resetIconEl.setAttribute("aria-hidden", "true");
    resetButtonEl.append(resetIconEl);

    this._container.append(fitButtonEl);
    this._container.append(resetButtonEl);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export class ExploreMap {
  constructor($el) {
    this.$el = $el;

    // Tabs (mostly vanilla JS to stay in sync with Tabs.js)
    this.tabParent = this.$el.closest(".js-explore-map__tabs")[0];
    this.tabPanels = this.tabParent.querySelectorAll("[role='tabpanel']");
    this.tablistTabs = this.tabParent.querySelectorAll("[role='tab']");

    // Map
    this.endpoint = this.$el.data("endpoint"); // API endpoint
    this.apiToken = this.$el.data("apiToken"); // API token
    this.json = this.$el.data("json");
    this.$tourwrapper = this.$el.closest(".js-tour"); // Wrapper for the entire tour, map included
    this.$tourstops = this.$tourwrapper.find(".js-tour-stops"); // Wrapper for the tour stops
    this.$tourlist = this.$tourwrapper.find(".js-tour-list"); // Wrapper for the map list

    this.id = this.$el.attr("id"); // Map wrapper id, for MapBox
    this.constraints = this.$el.data("constraints").split(","); // Starting map bounding box
    this.zoom = this.$el.data("zoom"); // Starting map zoom

    this.bounds = new mapboxgl.LngLatBounds();

    // Set mapping constraints
    this.swLat = this.constraints[1];
    this.neLat = this.constraints[3];
    this.swLong = this.constraints[0];
    this.neLong = this.constraints[2];

    this.map = new mapboxgl.Map({
      container: this.id, // MapBox wants an ID, so give it one
      style: "mapbox://styles/mapbox/streets-v9",
      interactive: true,
      accessToken: this.apiToken,
    });

    this.map.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      "top-left",
    );

    this.map.addControl(new ResetZoomControl(), "top-left");

    let thisMap = this;

    if (this.endpoint) {
      fetch(this.endpoint)
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          thisMap.geojson = myJson;
          thisMap.buildMap();
          thisMap.buildList();
        });
    } else if (this.json) {
      this.geojson = this.json;
      this.buildMap();
      this.buildList();
    }
  }

  buildMap() {
    let thisMap = this;

    // add markers to map
    thisMap.geojson.features.forEach(function (marker, index) {
      thisMap.bounds.extend(marker.geometry.coordinates);

      // create a HTML element for each feature
      var el = document.createElement("a");
      el.innerHTML =
        '<span class="visually-hidden">Jump to tour stop</span>' + (index + 1);
      el.setAttribute("id", marker.triggerId + "-dot");
      el.setAttribute("data-target", marker.triggerId);
      el.setAttribute("href", "#explore-map");
      el.setAttribute("title", "Jump to tour stop " + marker.triggerId);
      el.className += " tour__map-marker js-map-marker";

      if (marker.startingPoint) {
        thisMap.startingPoint = marker.triggerId;
      }

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .addTo(thisMap.map);
    });

    thisMap.map.scrollZoom.disable();

    // Make sure the important part of the map is visible
    thisMap.map.fitBounds(
      [
        [thisMap.swLong, thisMap.swLat],
        [thisMap.neLong, thisMap.neLat],
      ],
      { duration: 0, padding: { top: 0, bottom: 0, left: 100, right: 0 } },
    );

    thisMap.starterZoom = thisMap.map.getZoom();
    thisMap.startCenter = thisMap.map.getCenter();
    thisMap.centerLatOffset =
      (thisMap.constraints[3] - thisMap.constraints[1]) / 2;

    thisMap.bindEvents();

    thisMap.$el.find("canvas").attr("title", "Map of " + $("h1").text());

    // Put focus on starting point to start
    let startingPoint = thisMap.geojson.features.find(
      (feature) => feature.startingPoint,
    );
    thisMap.handleStepEnter(startingPoint.triggerId);
  }

  // A clickable list of tour stops that can live alongside the map
  buildList() {
    let thisMap = this;

    // add markers to map
    thisMap.$tourlist.each(function () {
      let thisTourList = this;

      thisMap.geojson.features
        .sort(function (a, b) {
          return b.properties.title > a.properties.title
            ? -1
            : a.properties.title > b.properties.title
            ? 1
            : 0;
        })
        .forEach(function (marker, index) {
          // create a list element for each tour stop
          var el = document.createElement("li");
          el.innerHTML =
            '<button aria-label="' +
            marker.properties.title +
            '"><span class="explore-map__list-item-title">' +
            marker.properties.title +
            '</span><span class="explore-map__list-item-description">' +
            marker.properties.description +
            "</span></button>";
          el.classList.add("explore-map__list-item");
          el.setAttribute("href", "#explore-map");
          el.setAttribute("data-target", marker.triggerId + "-dot");
          el.className += " js-list-marker";
          el.className += marker.startingPoint ? " is-active" : "";

          thisTourList.append(el);
        });
    });
  }

  closeDropdownList() {
    let thisMap = this;
    const tourListOpen = thisMap.$tourwrapper.find(".js-tour-list-open");

    $(document.body).css({ overflow: "auto" });

    thisMap.$tourwrapper
      .find(".js-tour-list-dropdown")
      .removeClass("is-visible");

    $(tourListOpen).trigger("focus");
  }

  bindEvents() {
    const thisMap = this;

    // Tab events
    thisMap.tablistTabs.forEach((tab) => {
      tab.addEventListener("click", (e) => {
        const thisTab = e.target;
        const thisTabPanel = thisMap.tabParent.querySelector(
          "#" + thisTab.getAttribute("aria-controls"),
        );

        thisMap.tabPanels.forEach((tabPanel) => {
          if (tabPanel !== thisTabPanel) {
            tabPanel.classList.remove("is-active");
            tabPanel.setAttribute("aria-hidden", true);
          } else {
            tabPanel.classList.add("is-active");
            tabPanel.setAttribute("aria-hidden", false);
          }
        });

        thisMap.tablistTabs.forEach((tab) => {
          if (tab !== thisTab) {
            tab.classList.remove("is-active");
            tab.setAttribute("aria-selected", false);
          } else {
            tab.classList.add("is-active");
            tab.setAttribute("aria-selected", true);
          }
        });
      });
    });

    // Handle clicking on map pins
    thisMap.$el.on("click", ".js-map-marker", function () {
      thisMap.handleStepEnter($(this).data("target"));
      const idString = $(this).data("target");
      setTimeout(function () {
        document.getElementById(idString).focus();
      }, 10);
    });

    thisMap.$el.on("focus", ".js-map-marker", function () {
      thisMap.handleFlyTo($(this).data("target"));
    });

    // Click on corresponding map pins when map list items are clicked
    thisMap.$tourwrapper.on("click", ".js-list-marker button", function () {
      // Click the map pin when clicking the associated list item
      thisMap.$el
        .find("#" + $(this).closest(".js-list-marker").data("target"))
        .eq(0)
        .click();

      // If we're in the (mobile) dropdownlist, close it
      if ($(this).closest(".explore-map__dropdown-list")) {
        thisMap.closeDropdownList();
      }
    });

    thisMap.$tourwrapper.on("click", ".js-tour-list-close", function () {
      thisMap.closeDropdownList();
    });

    thisMap.$tourwrapper.on("click", ".js-map-zoom-fit", function () {
      thisMap.map.fitBounds(thisMap.bounds, { padding: 100 });
    });

    thisMap.$tourwrapper.on("click", ".js-map-reset", function () {
      let startingPoint = thisMap.geojson.features.find(
        (feature) => feature.startingPoint,
      );
      thisMap.$tourwrapper.find("#" + startingPoint.triggerId + "-dot").click();
    });

    thisMap.$tourwrapper.on("click", ".js-tour-list-open", function () {
      thisMap.$tourwrapper
        .find(".js-tour-list-dropdown")
        .addClass("is-visible");

      // Focus the first item in the list when opening the dropdown
      setTimeout(function () {
        thisMap.$tourwrapper
          .find(".explore-map__dropdown-list .explore-map__list-item button")
          .eq(0)
          .trigger("focus");
      }, 10);

      // Prevent scrolling the body while list is open
      $(document.body).css({ overflow: "hidden" });
    });
  }

  handleFlyTo(id) {
    if (!this.starterZoom) {
      this.starterZoom = this.map.getZoom();
      this.startCenter = this.map.getCenter();
      this.centerLatOffset = (this.constraints[3] - this.constraints[1]) / 2;
    }

    let thisData = this.geojson.features.find((stop) => stop.triggerId === id);
    let zoom = parseInt(this.starterZoom) + parseInt(thisData.zoomModifier);

    let flyTo = {
      center: thisData.geometry.coordinates,
      zoom: zoom,
      padding: { top: 0, bottom: 0, left: 100, right: 0 },
    };

    this.map.flyTo(flyTo);
  }

  handleStepEnter(id) {
    let dots = $(".js-map-marker");

    // Close all playing videos
    $(".js-video-accordion__play.is-open").click();

    dots.each(function () {
      if ($(this).attr("id") != id + "-dot") {
        $("#" + $(this).data("target")).removeClass("is-active");

        $(this).css({
          backgroundColor: "rgba(7, 41, 77, 0.8)",
          color: "#FFF",
          height: 1.714 + "rem",
          width: 1.714 + "rem",
          fontSize: 1 + "rem",
        });
      } else {
        $("#" + $(this).data("target")).addClass("is-active");

        $(this).css({
          backgroundColor: "rgba(255, 198, 0, 0.8)",
          color: "#000",
          height: 1.25 * 1.714 + "rem",
          width: 1.25 * 1.714 + "rem",
          fontSize: 1.25 + "rem",
        });
      }
    });

    if (this.geojson) {
      /* There's an off chance this wasn't set before the scroller loads */
      this.handleFlyTo(id);

      let thisData = this.geojson.features.find(
        (stop) => stop.triggerId === id,
      );

      // Add active class to chosen list item
      this.$tourwrapper.find(".js-list-marker").each(function () {
        if ($(this).data("target") === thisData.triggerId + "-dot") {
          $(this).addClass("is-active");
        } else {
          $(this).removeClass("is-active");
        }
      });
    }
  }
}
