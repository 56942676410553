import $ from "jquery";

/* Simple helper to grab q parameter out of current URL and pass it along with the current link */
export class GrabQuery {
  constructor($el) {
    this.$el = $el;
    this.href = $el.attr("href");
    this.delimeter = this.href.includes("?") ? "&" : "?"; // Already has query string, so append with care

    this.bindEvents();
  }


  bindEvents() {
    let thisLink = this;

    thisLink.$el.on("click", function(e) {
      e.preventDefault();

      const q = qs("q");

      if (q) {
        window.location = thisLink.href + thisLink.delimeter + "q=" + q;
      }
      else {
        window.location = thisLink.href;
      }
    });
  }
}

function qs(key) {
  key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&");
  var match = location.search.match(new RegExp("[?&]"+key+"=([^&]+)(&|$)"));
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}
