import $ from "jquery";
// Removing the mapbox import so that it doesn't get bundled into libraries-generated.js and break all functionality for IE 11.  Mapbox is now loaded via a CDN script link in the page directly.
import { breakpoint } from "../breakpoint";
import { ie } from "../ie";
import "intersection-observer";
import scrollama from "scrollama";
import { scaleLinear } from "d3-scale";

let throttler;
const keyCodes = {
  tab: 9,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  enter: 13,
  space: 32,
  escape: 27,
};

export class Tour {
  constructor($el) {
    this.$el = $el;
    this.endpoint = this.$el.data("endpoint"); // API endpoint
    this.apiToken = this.$el.data("apiToken"); // API token
    this.json = this.$el.data("json");
    this.$tourwrapper = this.$el.closest(".js-tour"); // Wrapper for the entire tour, map included
    this.$tourstops = this.$tourwrapper.find(".js-tour-stops"); // Wrapper for the tour stops

    this.id = this.$el.attr("id"); // Map wrapper id, for MapBox
    this.constraints = this.$el.data("constraints").split(","); // Starting map bounding box
    this.zoom = this.$el.data("zoom"); // Starting map zoom

    this.scroller = scrollama(); // Init scroll trigger points

    /* Scroller will trigger at halfway point of the page */
    this.scroller
      .setup({
        step: ".js-tour-stop",
        container: ".js-tour",
        progress: true,
        offset: 0.5,
        debug: false,
        threshold: 1,
      })
      .onStepProgress(this.handleStepProgress)
      .onStepEnter(this.handleStepEnter.bind(this))
      .onStepExit(this.handleStepExit.bind(this));

    // Set mapping constraints
    this.swLat = this.constraints[1];
    this.neLat = this.constraints[3];
    this.swLong = this.constraints[0];
    this.neLong = this.constraints[2];

    this.map = new mapboxgl.Map({
      container: this.id, // MapBox wants an ID, so give it one
      style: "mapbox://styles/mapbox/streets-v9",
      interactive: false,
      accessToken: this.apiToken,
      preserveDrawingBuffer: true,
    });

    let thisMap = this;

    if (this.endpoint) {
      fetch(this.endpoint)
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          thisMap.geojson = myJson;
          thisMap.buildMap();
        });
    } else if (this.json) {
      this.geojson = this.json;
      this.buildMap();
    }
  }

  buildMap() {
    let thisMap = this;

    thisMap.addMapMarkers();
    thisMap.map.scrollZoom.disable();
    thisMap.adjustMapBounds();

    thisMap.bindEvents();
    thisMap.scroller = scrollama();

    thisMap.$el.find("canvas").attr("title", "Map of " + $("h1").text());
  }

  adjustMapBounds(leftOffset = 100) {
    let thisMap = this;

    // Make sure the important part of the map is visible
    thisMap.map.fitBounds(
      [
        [thisMap.swLong, thisMap.swLat],
        [thisMap.neLong, thisMap.neLat],
      ],
      {
        duration: 0,
        padding: { top: 0, bottom: 0, left: leftOffset, right: 0 },
      },
    );

    thisMap.starterZoom = thisMap.map.getZoom();
    thisMap.startCenter = thisMap.map.getCenter();
    thisMap.centerLatOffset =
      (thisMap.constraints[3] - thisMap.constraints[1]) / 2;
  }

  addMapMarkers() {
    let thisMap = this;

    // add markers to map
    thisMap.geojson.features.forEach(function (marker, index) {
      // create a HTML element for each feature
      var el = document.createElement("a");
      el.innerHTML =
        '<span class="visually-hidden">Jump to tour stop</span>' + (index + 1);
      el.setAttribute("id", marker.triggerId + "-dot");
      el.setAttribute("data-target", marker.triggerId);
      el.setAttribute("href", "#" + marker.triggerId);
      el.setAttribute("title", "Jump to tour stop " + marker.triggerId);
      el.className = "tour__map-marker";

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .addTo(thisMap.map);
    });
  }

  bindEvents() {
    let thisMap = this;

    thisMap.$el.on("click", ".tour__map-marker", function () {
      $("html, body").animate(
        { scrollTop: $("#" + $(this).data("target")).offset().top },
        1000,
      );
    });

    window.onbeforeprint = (event) => {
      thisMap.handlePrintSetup();
    };

    window.onafterprint = (event) => {
      thisMap.handlePrintExit();
    };
  }

  handlePrintSetup() {
    let thisMap = this;

    // Adjust map height for printing
    thisMap.$el.css("height", "50vh");
    thisMap.map.resize();

    // Reset zoom and center accordingly
    thisMap.adjustMapBounds(0);
  }

  handlePrintExit() {
    let thisMap = this;

    // Undo the map height, let CSS take over
    thisMap.$el.css("height", "");
    thisMap.map.resize();

    // Reset zoom and center accordingly
    thisMap.adjustMapBounds();
  }

  handleStepExit(response) {
    if (
      (response.index === 0 && response.direction === "up") ||
      (response.index + 1 === this.geojson.features.length &&
        response.direction === "down")
    ) {
      // Reset zoom and center
      this.map.fitBounds(
        [
          [this.swLong, this.swLat],
          [this.neLong, this.neLat],
        ],
        { duration: 500, padding: { top: 0, bottom: 0, left: 100, right: 0 } },
      );
    }
  }

  handleStepEnter(response) {
    if (this.geojson) {
      /* There's an off chance this wasn't set before the scroller loads */
      if (!this.starterZoom) {
        this.starterZoom = this.map.getZoom();
        this.startCenter = this.map.getCenter();
        this.centerLatOffset = (this.constraints[3] - this.constraints[1]) / 2;
      }

      let id = $(response.element).attr("id");
      let thisData = this.geojson.features.find(
        (stop) => stop.triggerId === id,
      );
      let zoom = parseInt(this.starterZoom) + parseInt(thisData.zoomModifier);

      let flyTo = {
        center: thisData.geometry.coordinates,
        zoom: zoom,
        padding: { top: 0, bottom: 0, left: 100, right: 0 },
      };

      this.map.flyTo(flyTo);
    }
  }

  handleStepProgress(response) {
    let dot = $("#" + $(response.element).attr("id") + "-dot");

    /* Calculate the color */
    let bgcolorScale = scaleLinear()
      .domain([0, 0.1, 0.9, 1])
      .range([
        "rgba(7, 41, 77, 0.8)",
        "rgba(255, 198, 0, 0.8)",
        "rgba(255, 198, 0, 0.8)",
        "rgba(7, 41, 77, 0.8)",
      ])
      .clamp(true);

    let colorScale = scaleLinear()
      .domain([0, 0.1, 0.9, 1])
      .range(["#FFF", "#000", "#000", "#FFF"])
      .clamp(true);

    let progressValue =
      response.progress < 0.25
        ? response.progress * 0.5 + 1
        : response.progress > 0.75
        ? (1 - response.progress) * -0.5 + 1
        : 1.25;

    dot.css({
      backgroundColor: bgcolorScale(response.progress),
      color: colorScale(response.progress),
      height: progressValue * 1.714 + "rem",
      width: progressValue * 1.714 + "rem",
      fontSize: progressValue + "rem",
    });
  }
}
