// Adapted from the University of York's filterable table scripting (https://www.york.ac.uk/pattern-library/js-modules/filterable-tables-module.html)

import { InteractiveTable } from "../common/InteractiveTable.js";
import { updateAriaLive } from "../../a11y/ariaLiveUtils";
import * as DOMPurify from "dompurify";

export class FilterableTable extends InteractiveTable {
  constructor($el) {
    super($el); // to access the parent InteractiveTable constructor

    // Consolidating instructions, labels, and messages, in case we need to import them from a Sitecore dictionary in future
    this.instructionSearchScreenReader = "(table data will update as you type)";
    this.labelClearAll = "clear all";
    this.labelSearchPlaceholder = "Search";
    this.legendFiltersScreenreader = "Filter table by:";
    this.messageNoResultsVisual =
      "Sorry, there are no results matching your filters.";

    // It's important that screenreader messages be short (or they may be cut off by screenreaders), and meaningful.
    this.messageNoResultsScreenreader = "No results found.";
    this.messageResultsFilteredByScreenreader = "Table results filtered by ";
    this.messageTableFiltersClearedScreenreader = "Table filters cleared.";

    this.filters = {};
    this.fields = this.readFilterableFields();
    this.data = this.readFilterableData();
    this.ariaLiveMessage = "";
    this.$noResults = document.createElement("div");
    this.$noResults.innerHTML = `<p><i>${DOMPurify.sanitize(
      this.messageNoResultsVisual,
    )}</i></p>`;
    this.storageKey =
      "tablefilters__" + this.$table.id + "__" + window.location.href;
    this.renderForm();
    this.setInitialState();
  }

  // Updates the form and table with any previously saved filters
  // Gets info from URL params or localStorage
  setInitialState() {
    const UrlSearchParams = new URLSearchParams(window.location.search);
    const savedFilters = this.getSavedFilters();

    // Loop through the filter fields, see if they were saved via URL/localStorage
    for (let n = 0; n < this.fields.length; n++) {
      const thisFilterField = this.fields[n];
      const savedFilter =
        savedFilters[thisFilterField.id] ??
        UrlSearchParams.get(thisFilterField.id + "_filter");

      // If this is a saved filter, update the filter field and update
      if (savedFilter !== null) {
        switch (thisFilterField.type) {
          case "select":
            thisFilterField.$control.querySelector(
              'option[value="' + savedFilter + '"]',
            ) &&
              ((thisFilterField.$control.querySelector("select").value =
                savedFilter),
              this.update(thisFilterField.$control));
            break;
          default:
            (thisFilterField.$control.querySelector("input[type=text]").value =
              savedFilter),
              this.update(thisFilterField.$control);
        }
      }
    }
  }

  // Reads all filterable table data
  // Returns an array of arrays
  readFilterableData() {
    var tableData = [],
      tableRows = this.$table.querySelectorAll("tbody > tr");
    for (var rowIndex = 0; rowIndex < tableRows.length; rowIndex++) {
      var rowCells = tableRows[rowIndex].querySelectorAll("td"),
        rowValues = {};
      for (var cellIndex = 0; cellIndex < rowCells.length; cellIndex++) {
        if (typeof this.fields[cellIndex] == "object") {
          var headerIndex = this.fields[cellIndex].id,
            thisCell = rowCells[headerIndex],
            cellRawContents,
            cellContents;

          if (thisCell.getAttribute("data-value")) {
            cellRawContents = thisCell.getAttribute("data-value");
          } else if (
            this.fields[cellIndex].type === "select" &&
            thisCell.hasChildNodes()
          ) {
            // If the filter type is "select", and there is a UL in the cell,
            // that UL should be the source of the filterable options
            if (thisCell.childNodes[0].nodeName === "UL") {
              const thisList = thisCell.children[0];
              const listItems = thisList.children;

              listItems.forEach(function (item, index) {
                if (index === 0) {
                  cellRawContents = item.innerText;
                } else {
                  cellRawContents += "|" + item.innerText;
                }
              });
            } else {
              cellRawContents = thisCell.innerHTML;
            }
          } else {
            cellRawContents = thisCell.innerHTML;
          }

          cellContents =
            cellRawContents != "" ? cellRawContents.split("|") : [];
          rowValues[headerIndex] = cellContents;
        }
      }
      tableData.push({
        $row: tableRows[rowIndex],
        values: rowValues,
      });
    }
    return tableData;
  }

  // Reads all the filters (from table headers with data-filterable)
  // Returns an array of objects, each representing a filter
  readFilterableFields = function () {
    const fieldData = [],
      tableHeaders = this.$table.querySelectorAll("thead > tr > th");

    tableHeaders.forEach((tableHeader, headerIndex) => {
      tableHeader.matches("[ data-filterable ]") &&
        fieldData.push({
          id: headerIndex,
          name: tableHeader.getAttribute("data-filter-name")
            ? tableHeader.getAttribute("data-filter-name")
            : tableHeader.innerHTML,
          type: tableHeader.getAttribute("data-filter-type"),
          modifier: tableHeader.getAttribute("data-filter-modifier")
            ? tableHeader.getAttribute("data-filter-modifier")
            : "default",
        });
    });

    return fieldData;
  };

  // Renders the filter form (appears on both desktop and mobile)
  renderForm = function () {
    var outerThis = this;

    var fieldset = document.createElement("fieldset");
    fieldset.setAttribute(
      "class",
      "responsive-table__fieldset responsive-table__fieldset--filterable",
    ),
      this.$form.appendChild(fieldset);

    var legend = document.createElement("legend");
    legend.setAttribute("class", "visually-hidden"),
      (legend.innerHTML = DOMPurify.sanitize(this.legendFiltersScreenreader)),
      fieldset.appendChild(legend);

    for (var i = 0; i < this.fields.length; i++) {
      var inputId = this.$table.id + "__" + i,
        uniqueValues = this.uniqueValues(this.fields[i]);
      if (!(uniqueValues.length < 2)) {
        var fieldsetItem = document.createElement("div");
        fieldsetItem.setAttribute("class", "responsive-table__fieldset-item"),
          fieldsetItem.setAttribute("data-fieldindex", i),
          fieldset.appendChild(fieldsetItem);

        var label = document.createElement("label");
        switch (
          (label.setAttribute("class", "responsive-table__label"),
          label.setAttribute("for", inputId),
          (label.innerHTML = DOMPurify.sanitize(this.fields[i].name)),
          this.fields[i].type)
        ) {
          case "select":
            fieldsetItem.appendChild(label);

            var select = document.createElement("select");
            select.setAttribute("id", inputId),
              select.setAttribute(
                "class",
                "responsive-table__input responsive-table__input--select",
              ),
              select.setAttribute("autocomplete", "on"),
              fieldsetItem.appendChild(select);

            var selectOptionDefault = document.createElement("option");
            selectOptionDefault.setAttribute("value", ""),
              (selectOptionDefault.innerHTML = DOMPurify.sanitize("Any")),
              select.appendChild(selectOptionDefault);

            for (var d = 0; d < uniqueValues.length; d++) {
              if (uniqueValues[d] != "") {
                var selectOption = document.createElement("option");
                selectOption.setAttribute("value", uniqueValues[d]),
                  (selectOption.innerHTML = DOMPurify.sanitize(
                    uniqueValues[d],
                  )),
                  select.appendChild(selectOption);
              }
            }
            select.addEventListener("change", function (event) {
              outerThis.update(event.target);
            });
            break;
          default:
            fieldsetItem.appendChild(label);

            var input = document.createElement("input");
            input.setAttribute("id", inputId),
              input.setAttribute(
                "class",
                "responsive-table__input responsive-table__input--text",
              ),
              input.setAttribute("type", "text"),
              input.setAttribute(
                "placeholder",
                DOMPurify.sanitize(this.labelSearchPlaceholder),
              ),
              input.setAttribute("autocomplete", "on"),
              input.setAttribute("aria-describedby", inputId + "-description"),
              fieldsetItem.appendChild(input),
              input.addEventListener("input", function (event) {
                outerThis.update(event.target);
              });

            var inputDescription = document.createElement("span");
            inputDescription.setAttribute("id", inputId + "-description"),
              inputDescription.setAttribute("class", "visually-hidden"),
              (inputDescription.innerText = DOMPurify.sanitize(
                this.instructionSearchScreenReader,
              )),
              fieldsetItem.appendChild(inputDescription);
        }
        this.fields[i].$control = fieldsetItem;
      }
    }

    // Clear all button
    var clearAllButton = document.createElement("button");
    clearAllButton.setAttribute("type", "button"),
      (clearAllButton.innerHTML = DOMPurify.sanitize(this.labelClearAll)),
      clearAllButton.setAttribute(
        "class",
        "responsive-table__button--clear-all",
      ),
      fieldset.appendChild(clearAllButton);
    clearAllButton.addEventListener("click", function () {
      outerThis.clearChangeHandler();
      updateAriaLive(
        DOMPurify.sanitize(outerThis.messageTableFiltersClearedScreenreader),
        "polite",
      );
    });
  };

  // Update table and ARIA-live region
  update(field) {
    var fieldsetItem = field.closest(".responsive-table__fieldset-item");
    var fieldIndex = fieldsetItem.getAttribute("data-fieldindex"),
      inputValue = !1;

    switch (this.fields[fieldIndex].type) {
      case "select":
        inputValue = fieldsetItem.querySelector(
          ".responsive-table__input--select",
        ).value;
        break;
      default:
        inputValue = fieldsetItem.querySelector(
          ".responsive-table__input--text",
        ).value;
    }

    /* eslint-disable yoda */
    void 0 !== inputValue && "" != inputValue
      ? (this.filters[fieldIndex] = inputValue)
      : delete this.filters[fieldIndex],
      this.saveFilters();
    /* eslint-enable yoda */

    // Looping through the table data
    for (var i = 0, rowIndex = 0; rowIndex < this.data.length; rowIndex++) {
      for (
        var tableRow = this.data[rowIndex],
          rowMatch = !0,
          filterKeys = this.filters ? Object.keys(this.filters) : {},
          rowLoopIndex = 0;
        rowLoopIndex < filterKeys.length;
        rowLoopIndex++
      ) {
        for (
          var filterText = this.filters[filterKeys[rowLoopIndex]]
              .toLowerCase()
              .trim()
              .normalize("NFKD")
              .replace(/[\u0300-\u036f]/g, ""),
            filterObj = this.fields[filterKeys[rowLoopIndex]],
            tableCellValues = tableRow.values[filterObj.id],
            filterMatch = !1,
            cellValueLoopIndex = 0;
          cellValueLoopIndex < tableCellValues.length;
          cellValueLoopIndex++
        ) {
          var tableCellText = String(tableCellValues[cellValueLoopIndex])
            .toLowerCase()
            .trim()
            .normalize("NFKD")
            .replace(/[\u0300-\u036f]/g, "");
          switch (filterObj.type) {
            case "select":
              tableCellText == filterText && (filterMatch = true);
              break;
            default:
              var words = filterText.split(" ");
              filterMatch = !0;
              /* eslint-disable yoda */
              for (var g = 0; g < words.length; g++)
                filterMatch *= -1 != tableCellText.indexOf(words[g]);
            /* eslint-enable yoda */
          }
        }
        rowMatch *= filterMatch;
      }
      rowMatch
        ? tableRow.$row.classList.remove("is-hidden--filters")
        : (tableRow.$row.classList.add("is-hidden--filters"), i++);
    }

    if (i == this.data.length) {
      // No results!
      this.$noResults.parentNode ||
        this.$table.parentNode.insertBefore(
          this.$noResults,
          this.$table.nextSibling,
        );
      updateAriaLive(
        DOMPurify.sanitize(this.messageNoResultsScreenreader),
        "assertive",
      );
    } else {
      // Results returned
      this.$noResults.parentNode &&
        this.$table.parentNode.removeChild(this.$noResults);

      if (this.fields[fieldIndex].type === "select") {
        if (inputValue !== "") {
          const ariaLiveMessage =
            DOMPurify.sanitize(this.messageResultsFilteredByScreenreader) +
            DOMPurify.sanitize(this.fields[fieldIndex].name) +
            ": " +
            DOMPurify.sanitize(inputValue);
          updateAriaLive(ariaLiveMessage, "polite");
        }
      }
      // No need to update found search results via ARIA-live because the search input description makes it clear that the results will change.
    }

    if (void 0 !== this.$table.classList) {
      this.$table.classList.remove("effect-flash-in");
      var v = this.$table;
      requestAnimationFrame(function () {
        requestAnimationFrame(function () {
          v.classList.add("effect-flash-in");
        });
      });
    }
  }

  // Captures unique values from the table data (no duplicates) into an array
  // Returns sorted array of unique values
  uniqueValues = function (e) {
    var t = [];
    for (var n = 0; n < this.data.length; n++) {
      for (var i = this.data[n].values[e.id], r = 0; r < i.length; r++) {
        var o = i[r];
        /* eslint-disable yoda */
        -1 == t.indexOf(o) && t.push(o);
        /* eslint-enable yoda */
      }
    }

    return t.sort(), t;
  };

  // Look for saved filters in session storage
  getSavedFilters() {
    try {
      return JSON.parse(sessionStorage.getItem(this.storageKey)) || {};
    } catch (e) {
      return {};
    }
  }

  // Saves filters to session storage
  saveFilters() {
    try {
      let saveObj = {};
      let activeFilterIndexes = Object.keys(this.filters);

      // Loop through the active filters
      for (let n = 0; n < activeFilterIndexes.length; n++) {
        var activeFilterIndex = activeFilterIndexes[n];
        saveObj[this.fields[activeFilterIndex].id] =
          this.filters[activeFilterIndex];
      }
      sessionStorage.setItem(this.storageKey, JSON.stringify(saveObj));
    } catch (e) {}
  }
}
