import $ from "jquery";
import getScrollBehavior from "../a11y/getScrollBehavior.js";

let throttler;

/*
 * Shows and Hides return to top link as appropriate, as this link is not needed until we scroll
 */
export class ReturnToTop {
  constructor($el) {
    this.$el = $el;
    this.scrollToTopLink = document.querySelector(".return-to-top__link");

    if ($(window).scrollTop() < 200) {
      this.$el.animate({ opacity: 0 }, 0);
      this.$el.hide();
    }

    this.bindEvents();
  }

  bindEvents() {
    let thisReturn = this;

    // Get the scroll behavior (a11y, smooth or not)
    const scrollBehavior = getScrollBehavior();

    // Reveal the back to top component after a certain amount of scrolling
    $(window).on("scroll", function () {
      if (throttler) {
        window.clearTimeout(throttler);
      }
      throttler = setTimeout(() => {
        if ($(window).scrollTop() > $(window).height()) {
          thisReturn.$el.css("display", "");
          thisReturn.$el.animate({ opacity: 1 }, 300);
        } else {
          thisReturn.$el.animate({ opacity: 0 }, 300, function () {
            thisReturn.$el.hide();
          });
        }
      }, 200);
    });

    // Scroll to top behavior (reducing jQuery dependency)
    thisReturn.scrollToTopLink.addEventListener("click", function (event) {
      // Prevent link from adding the # to the URL
      event.preventDefault();

      // Scroll to the top
      window.scrollTo({
        top: 0,
        behavior: scrollBehavior,
      });

      // Remove keyboard focus for this element (Safari)
      document.activeElement.blur();

      // Return keyboard focus to the top (for a11y)
      document.body.focus();
    });
  }
}
