import R from "ramda";
import * as RA from "ramda-adjunct";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";



export default class InTheNewsResultItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemWrapper = null;
  }

  componentDidMount() {
    this.itemWrapper.className = this.itemWrapper.className + " is-visible";
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return moment(date).format("MMMM D, YYYY");
  }

  renderExternalLinkIcon() {
    return (
      <svg id="externalLinkIconSvg" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
        <path className="externalLinkIconStyles" d="M221.84,111.54h-119.77s-45.35-1.48-45.35,44.36v241.51s2.46,46.33,38.94,46.33h247.92c12.19,0,46.08-3.94,46.08-46.08v-120.02"/>
        <polyline className="externalLinkIconStyles" points="328.42 56.26 443.28 56.26 188.42 311.12"/>
        <line className="externalLinkIconStyles" x1="443.28" y1="166.8" x2="443.28" y2="56.26"/>
      </svg>
    );
  };

  renderImage() {
    const { image, contentUrl, title } = this.props;
    if (image) {
      return (
        <figure className="search-item__media">
          <a href={contentUrl}>
            <img src={image} alt={title} />
          </a>
        </figure>
      );
    } else {
      return null;
    }
  }

  renderEyebrow() {
    const { publication, date, taxonomyUrl } = this.props;

    const transformedDate = date ? this.formatDate(date) : "";
    const transformedPublication =
      publication.length > 0
        ? R.compose(
            R.splitEvery(2),
            R.intersperse(", "),
            RA.compact,
          )([...publication])
        : "";

    const itemPairs = R.compose(
      R.splitEvery(2),
      R.intersperse("|"),
      RA.compact,
    )([ transformedDate ]);

    if (!itemPairs.length) {
      return;
    }

    const items = RA.mapIndexed(
      ([val, sep], idx) => (
        <React.Fragment key={idx}>
          <span className="search-item__eyebrow-item">{val}</span>
        </React.Fragment>
      ),
      itemPairs,
    );

    return <div className="search-item__eyebrow">{items}</div>;
  }

  render() {
    const { title, publication, body, date, taxonomyUrl, contentUrl } = this.props;

    return (
      <article className="search-item" ref={(el) => (this.itemWrapper = el)}>
        {this.renderImage()}
        
        <div className="search-item__content">

          {contentUrl && (
            <a target="_blank" href={contentUrl}>
              <span className="search-item__title"
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
              <span className="search-item__icon">{this.renderExternalLinkIcon()}</span>
          </a>
          
          )}
          {!contentUrl && (
              <span className="search-item__title_bold"
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
          )}
        
        {taxonomyUrl && (
            <div className="search-item__eyebrow publication">
              <a href={taxonomyUrl}>{publication}</a>
            </div>
          )}
          {(date) && this.renderEyebrow()}
          <div
            className="search-item__description"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>

        </div>
      </article>
    );
  }
}

const { arrayOf, string } = PropTypes;
InTheNewsResultItem.propTypes = {
  date: string.isRequired,
  publication: arrayOf(string),
  title: string.isRequired,
  body: string.isRequired,
  contentUrl: string,
  taxonomyUrl: string,
  image: string,
};
