/** @module search/components */

import React from "react";
import PropTypes from "prop-types";
import R from "ramda";
import classnames from "classnames";
import { facetGroupShape, facetValueShape } from "../../dataShapes";

import {
  valuesWithSelectedChildren,
  selectedFacetsFromGroup,
} from "../../redux/facets";

const MAX_FACETS_SHOWN = 5;

/**
 *
 * A section for a single grouping of event facets.
 * ```
 *  <EventFacetGroup
      group={group}
      handleFacetChange={func}
      handleClearAll={func} />
 * ```
 * @component
 * @param {object} props
 * @param {object} props.group The group of facets to show
 * @param {function} props.handleFacetChange Function called when a facet is selected/deselected
 * @params {function} prop.handleClearGroup Function called when clearing all selected facets
 */

class EventFacetGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  onFacetChange(event, valueId) {
    this.props.handleFacetChange(valueId, event.target.checked);
  }

  renderValueItem(value) {
    return (
      <li key={value.id}>
        <input
          id={value.id}
          checked={value.selected}
          onChange={(e) => this.onFacetChange(e, value.id)}
          type="checkbox"
        />
        <label htmlFor={value.id}>
          {value.name}
          {value.selected && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <polygon
                  id="check-a"
                  points="8.59 15.58 4.42 11.41 3 12.82 8.59 18.41 20.59 6.41 19.18 5"
                />
              </g>
            </svg>
          )}
        </label>
        {value.childValues.length > 0 &&
          value.selected &&
          this.renderValues(value.childValues)}
      </li>
    );
  }

  renderValues(values) {
    return (
      <div className="event-facets__values-wrapper">
        <ul className="event-facets__group-values">
          {values.map((val) => this.renderValueItem(val))}
        </ul>
      </div>
    );
  }

  render() {
    const group = this.props.group;

    return (
      <div className="event-facets__group">
        {group.label && (
          <div className="event-facets__group-heading">
            <h4>{group.label}</h4>
          </div>
        )}
        <div className="event-facets__group-wrapper">
          <div className="event-facets__group-body">
            {this.renderValues(group.values)}
          </div>
        </div>
      </div>
    );
  }
}

export default EventFacetGroup;

const { func, shape, bool, number, string, oneOfType, arrayOf } = PropTypes;

EventFacetGroup.propTypes = {
  dupeSelected: bool,
  handleFacetChange: func.isRequired,
  group: shape({
    id: oneOfType([number, string]).isRequired,
    label: string,
    values: arrayOf(facetValueShape).isRequired,
  }),
};

EventFacetGroup.defaultProps = {
  dupeSelected: true,
};
