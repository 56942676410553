const getScrollBehavior = () => {
  // Grab the prefers reduced motion query (for a11y)
  const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

  // Set the scrolling behavior based on the media query
  // This is also set in the CSS, but adding it here in case
  // some browsers adopt the JS behavior before the CSS behavior
  const scrollBehavior = !mediaQuery || mediaQuery.matches ? "auto" : "smooth";
  return scrollBehavior;
};

export default getScrollBehavior;
