import PropTypes from "prop-types";
import R from "ramda";
import React from "react";
import classnames from "classnames";

function activateOrToggleSorter(sorterId, sorters, handleSortClick) {
  const toggleDir = dir => {
    if (!dir) {
      return null;
    } else {
      return  dir === "asc" ? "desc" : "asc";
    }
  };

  // this is like a 'bookmark' to the direction property
  const dirLens = R.lensProp("direction");

  // each argument array supplies a condition predicate, and an action
  // 1) if clicked sorter and active, toggle the sort direction
  // 2) if clicked sorter and not active, make active
  // 3) if not clicked sorter, and active, make inactive
  // 4) all other cases, do nothing
  const adjustSorters = R.cond([
    [R.whereEq({id: sorterId, isActive: true}), R.over(dirLens, toggleDir)],
    [R.whereEq({id: sorterId, isActive: false}), R.assoc("isActive", true)],
    [R.whereEq({isActive: true}), R.assoc("isActive", false)],
    [R.T, R.identity]
  ]);

  const newSorters = R.map(adjustSorters, sorters);
  handleSortClick(newSorters);
}


const SortBar = ({sorters, handleSortClick}) => {
  return (
    <div className="search-results__sort-bar">
      <span>Sort By:</span>
      <ul>
        {sorters.map(sorter => {
          let sorterClass = classnames("search-results__sorter", { "is-active": sorter.isActive });
          return (
            <li
              key={sorter.id}
              className={sorterClass}>
              <button className="search-results__sort-label"
                onClick={ () => activateOrToggleSorter(sorter.id, sorters, handleSortClick) }>{sorter.label}</button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const { shape, func, number, string, bool, oneOfType, arrayOf } = PropTypes;

SortBar.propTypes = {
  sorters: arrayOf(shape({
    id: oneOfType([number, string]).isRequired,
    label: string.isRequired,
    value: string.isRequired,
    direction: oneOfType([bool, string]),
    isActive: bool.isRequired
  })).isRequired,
  handleSortClick: func.isRequired
};

export default SortBar;
