import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { isLastPage } from "../../paging";
import EventItem from "./EventItem.jsx";
import LoadMore from "../LoadMore.jsx";
import LoadingSpinner from "../LoadingSpinner.jsx";
import SearchStatus from "../SearchStatus.jsx";
import Pagination from "../Pagination.jsx";
import RawHtml from "../RawHtml.jsx";

import {
  RESULTS_DISPLAY_REFRESH,
  RESULTS_DISPLAY_APPEND
} from "../../redux/resultsDisplay";

const EventResultList = ({
  alertbanner,
  results,
  resultsDisplayStrategy,
  selectedFacets,
  totalResults,
  listTitle,
  loading,
  isFirstLoad,
  sorters,
  nextPage,
  gotoPage,
  dismissFacet,
  changeSort,
  query,
  pagination,
  dictionary,
  showMobileFacets,
  searchId
}) => {
  const isLast = isLastPage(totalResults, query.perPage, query.page);
  const shouldShowNoResults = results.length === 0 && !isFirstLoad;
  const shouldShowPager = totalResults > query.perPage;

  const searchLinkTarget = searchId + "-jumptarget";
  const resultLabel = totalResults === 1 ? "event" : "events";

  function wrapperClass() {
    const isRefreshing =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
    const isAppending =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
    return classnames("search-results", {
      "is-refreshing": isRefreshing,
      "is-appending": isAppending
    });
  }

  function resultsRange() {
    // Upper bound of visible results
    let ceiling = query.page * query.perPage;

    // Lower bound of visible results
    let floor = ceiling - query.perPage + 1;

    // make sure the ceiling does not exceed total results
    if (ceiling > totalResults) {
      ceiling = totalResults;
    }

    // if results are only one page, eschew the range entirely
    if (totalResults <= query.perPage) {
      return `${totalResults}`;
    }

    // if we're using load more, we want to show only
    // the ceiling of results displayed
    if (resultsDisplayStrategy === RESULTS_DISPLAY_APPEND) {
      return `${ceiling}`;
    }

    // otherwise, return the full range
    return `${floor} - ${ceiling}`;
  }

  function renderInfoSvg() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <path d="M12.0000004,2 L12,2 C6.47715,2.00000024 2,6.47715 2,12 C2.00000024,17.52285 6.47715,22 12,22 C17.52285,21.9999998 22,17.52285 22,12 L22,12.0000004 C22,6.47715044 17.52285,2.00000044 12,2.00000044 L12.0000004,2 Z M12.8333338,17 C12.8333338,17.4602375 12.4602379,17.8333333 12.0000004,17.8333333 C11.5397629,17.8333333 11.1666671,17.4602375 11.1666671,17 C11.1666671,17 11.1666671,17 11.1666671,17 L11.1666671,11.1666667 L11.1666671,11.1666668 C11.166667,10.7064293 11.5397629,10.3333335 12.0000004,10.3333335 C12.4602379,10.3333334 12.8333337,10.7064293 12.8333337,11.1666668 L12.8333338,17 Z M12.0000004,8.66666667 C11.3096445,8.66666664 10.7500004,8.1070225 10.7500004,7.41666667 C10.7500004,6.72631083 11.3096445,6.16666667 12.0000004,6.16666667 C12.6903562,6.1666667 13.2500004,6.72631083 13.2500004,7.41666667 C13.2500004,8.10702245 12.6903562,8.66666661 12.0000004,8.66666661 Z"/>
        </g>
      </svg>
    );
  }

  function renderPagingControl() {
    if (pagination) {
      return (
        <Pagination
          totalResults={totalResults}
          resultsPerPage={query.perPage}
          currentPage={query.page}
          handlePageChange={gotoPage}
        />
      );
    }

    if (!pagination && !isLast) {
      return (
        <LoadMore
          handleClick={nextPage}
          dictionary={dictionary}
          loading={loading}
        />
      );
    }
  }

  return (
    <section className={wrapperClass()}>
      <div className="search-results__title-bar">
        { listTitle && <h3>{listTitle}</h3> }
        <SearchStatus
          resultsRange={resultsRange()}
          totalResults={totalResults}
          keywords={query.q}
          selectedFacets={selectedFacets}
          handleFacetDismiss={dismissFacet}
          hideKeywordsLabel={true}
          resultLabel={resultLabel}
        />
      </div>

      { alertbanner && (
        <div className="search-results__alert-banner">
          <div className="search-results__alert-banner-flag">{ renderInfoSvg() }{ alertbanner.warningtext }</div>
          <RawHtml content={ alertbanner.content } />
          { alertbanner.additionalresultstitle  && <h3>{ alertbanner.additionalresultstitle }</h3> }
        </div>
      )}

      <div className="search-results__item-wrapper" id={ searchLinkTarget }>
        {loading && (
          <div className="search-results__loader">
            <LoadingSpinner />
          </div>
        )}
        <div className="search-results__items">
          {shouldShowNoResults ? (
            <p>No results found to match your query.</p>
          ) : null}
          {results.map((item, i) => <EventItem key={item.id} query={query} index={i} {...item} />)}
        </div>
      </div>
      {shouldShowPager ? renderPagingControl() : null}
    </section>
  );
};

const {
  arrayOf,
  string,
  object,
  func,
  bool,
  number,
  shape,
  oneOfType
} = PropTypes;

EventResultList.propTypes = {
  alertbanner: object,
  results: arrayOf(
    shape({
      id: oneOfType([number, string])
    })
  ),
  resultsDisplayStrategy: number,
  selectedFacets: arrayOf(object).isRequired,
  totalResults: number.isRequired,
  sorters: arrayOf(
    shape({
      id: oneOfType([number, string]).isRequired,
      label: string.isRequired,
      value: string.isRequired,
      direction: oneOfType([bool, string]),
      isActive: bool.isRequired
    })
  ),
  listTitle: string,
  loading: bool,
  isFirstLoad: bool,
  nextPage: func,
  gotoPage: func,
  changeSort: func,
  showMobileFacets: func,
  dismissFacet: func,
  query: shape({
    q: string,
    sortBy: string,
    page: number,
    perPage: number,
    pageId: string
  }),
  pagination: bool,
  dictionary: object,
  searchId: string
};

EventResultList.defaultProps = {
  selectedFacets: []
};

export default EventResultList;
