import { connect } from "react-redux";
import VideoFacetList from "../../components/Videos/VideoFacetList.jsx";
import { loadFacetChange } from "../../redux/search/search.actions";

const mapStateToProps = (state, ownProps) => {
  return {
    title: state.dictionary.facetsTitle,
    facets: state.facets,
    numOpenGroups: state.numOpenGroups,
    placeholder: state.dictionary.selectPlaceholder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleFacetChange: (facetId, facetValue) => {
      dispatch(loadFacetChange(facetId, facetValue));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoFacetList);
