/** @module search/components */

import React from "react";
import PropTypes from "prop-types";
import { facetValueShape } from "../../dataShapes";

/**
 *
 * A section for a single grouping of event facets.
 * ```
 *  <AdmissionRepRadioFacetGroup
      group={group}
      handleFacetChange={func} />
 * ```
 * @component
 * @param {object} props
 * @param {object} props.group The group of facets to show
 * @param {function} props.handleFacetChange Function called when a facet is selected/deselected
 */

class AdmissionRepRadioFacetGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  onFacetChange(event, valueId) {
    const { group, handleFacetChange } = this.props;
    handleFacetChange(valueId, group, event);
  }

  renderValueItem(value, groupId) {
    const radioGroupName = "radio-" + groupId;
    return (
      <li key={value.id} className="radio-facets__list-item">
        <input
          type="radio"
          value={value.name}
          id={value.id}
          onChange={(e) => {
            this.onFacetChange(e, value.id);
          }}
          checked={value.selected}
          name={radioGroupName}
        />
        <label htmlFor={value.id}>{value.name}</label>
      </li>
    );
  }

  renderValues(values, groupId) {
    return (
      <ul className="radio-facets__list">
        {values.map((val) => this.renderValueItem(val, groupId))}
      </ul>
    );
  }

  render() {
    const group = this.props.group;

    return (
      <div className="radio-facets">
        {group.label && (
          <div className="radio-facets__group-heading">
            <h3>{group.label}</h3>
          </div>
        )}
        {this.renderValues(group.values, group.id)}
      </div>
    );
  }
}

export default AdmissionRepRadioFacetGroup;

const { func, shape, number, string, oneOfType, arrayOf } = PropTypes;

AdmissionRepRadioFacetGroup.propTypes = {
  handleFacetChange: func.isRequired,
  group: shape({
    id: oneOfType([number, string]).isRequired,
    label: string,
    values: arrayOf(facetValueShape).isRequired,
  }),
};
