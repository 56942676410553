import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";

import ListContainer from "./containers/ListContainer.jsx";

export function initListApp(mountNodes) {
  if (!mountNodes.length) {
    return;
  }


  mountNodes.forEach( (node) => {
    /* Get ini info off the mount node */
    let id = $(node).attr("id");

    /* Get question endpoints */
    const endpoint = $(node).data("endpoint");

    ReactDOM.render(
      <ListContainer key={id}
      wrapperid={id}
      endpoint={endpoint}
      />,
      node
    );
  });
}
