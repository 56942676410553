import PropTypes from "prop-types";
import React from "react";

const SearchStatus = ({
  resultsRange,
  totalResults,
  keywords,
  selectedFacets,
  handleFacetDismiss,
  resultLabel,
  hideKeywordsLabel
}) => {

  const shouldRenderKeywords = !!keywords;

  return (
    <div className="search-results__status">
      <span>Showing</span>&nbsp;
      <strong>{resultsRange}</strong>&nbsp;
      <strong>of</strong>&nbsp;
      <strong>{totalResults}</strong>&nbsp;
      <span>{resultLabel}</span>&nbsp;
      { shouldRenderKeywords && !hideKeywordsLabel && (
        <span>for &quot;{keywords}&quot;&nbsp;</span>
      )}
    </div>
  );

};

const { bool, string, number, object, arrayOf, func } = PropTypes;

SearchStatus.propTypes = {
  resultsRange: string.isRequired,
  totalResults: number.isRequired,
  keywords: string,
  selectedFacets: arrayOf(object).isRequired,
  handleFacetDismiss: func.isRequired,
  resultLabel: string,
  hideKeywordsLabel: bool
};

SearchStatus.defaultProps = {
  selectedFacets: []
};

export default SearchStatus;
