import React from "react";
import { string, array, shape, oneOf } from "prop-types";

const propTypes = {
  vidGridPlayMsg: string,
  title: string.isRequired,
  description: string,
  images: array.isRequired,
  baseImage: shape({
    src: string.isRequired,
    alt: string,
  }),
  video: shape({
    videoId: string,
    videoType: oneOf(["youtube", "vimeo"]),
    videoUrl: string,
  }).isRequired,
};

export default class VideoGridItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: {} };

    this.videoGrid = null;
  }

  renderPlayIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="2 2 21 21"
        focusable="false"
      >
        <path
          fill="none"
          fillRule="evenodd"
          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-.857 0a8.143 8.143 0 10-16.286 0 8.143 8.143 0 0016.286 0zm-3.857.214l-6.429 4.5v-9l6.429 4.5z"
        ></path>
      </svg>
    );
  }

  renderCloseIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        focusable="false"
      >
        <path
          fill="none"
          fillRule="evenodd"
          d="M20 5.611L18.389 4 12 10.389 5.611 4 4 5.611 10.389 12 4 18.389 5.611 20 12 13.611 18.389 20 20 18.389 13.611 12z"
        ></path>
      </svg>
    );
  }

  render() {
    const { vidGridPlayMsg, title, description, images, baseImage, video } =
      this.props;

    return (
      <div className="video-grid-block" key={video.videoId}>
        <div className="js-video-grid__content">
          <div className="video-grid-block__video-wrapper">
            <div className="video-grid-block__video-overlay">
              <picture>
                {images.map((videoImage, j) => {
                  const srcSet = videoImage.hires
                    ? `${videoImage.lores} 1x, ${videoImage.hires} 2x`
                    : `${videoImage.lores} 1x`;
                  return (
                    <source
                      key={j}
                      srcSet={srcSet}
                      media={`(min-width: ${videoImage.useat})`}
                    />
                  );
                })}
                <img alt={`${baseImage.alt}`} src={`${baseImage.src}`} />
              </picture>
              <span
                className="video-grid-block__play-overlay js-video-grid__play-overlay"
                aria-hidden="true"
              >
                {vidGridPlayMsg}
              </span>
              <span
                className="video-grid-block__video-icon js-video-grid__play"
                aria-hidden="true"
              >
                {this.renderPlayIcon()}
              </span>
            </div>
          </div>
          {title && (
            <h3 className="video-grid-block__title js-video-grid-block__title">
              <button
                className="js-video-grid__trigger"
                data-id={`video-${video.videoId}`}
                data-videotype={`${video.videoType}`}
                data-videourl={`${video.videoUrl}`}
                data-video={`${video.videoId}`}
              >
                {title}
              </button>
            </h3>
          )}
          {description && (
            <div
              className="video-grid-block__caption"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}
        </div>
        <div
          id={`video-grid-panel__${video.videoId}`}
          className="video-grid-block__expanding-panel js-video-grid__target"
        >
          <div className="video-grid-block__expanding-panel-inner">
            <div className="video-grid-block__video-outer-wrapper">
              <div className="video-grid-block__video-inner-wrapper">
                <div id={`video-${video.videoId}`}></div>
                <button
                  className="video-grid-block__video-close js-video-grid__close"
                  aria-label="Close"
                >
                  {this.renderCloseIcon()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VideoGridItem.propTypes = propTypes;
