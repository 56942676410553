import $ from "jquery";
import "./custom-vendor/slick-carousel/slick/slick";

export class ImageSlider {
  constructor($el, conf) {
    this.$el = $el;
    let slickConf = Object.assign(this.slickDefaults(), conf);

    // bind event prior to slick init
    this.bindEvents();
    this.$el.slick(slickConf);
  }

  bindEvents() {
    // bind an event on the arrows to de-focus them when
    // you mouse out, so the focus state doesn't get stuck
    this.$el.on("mouseleave", ".slick-next, .slick-prev", (event) => {
      event.currentTarget.blur();
    });

    this.$el.on(
      "beforeChange",
      function (event, slick, currentSlide, nextSlide) {
        $(window).trigger("slideChange");
      },
    );
  }

  /* See http://kenwheeler.github.io/slick/ for Slick Slider options */
  slickDefaults() {
    return {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      appendArrows: this.$el
        .siblings(".js-slider-nav-wrapper")
        .find(".js-slider-nav"),
      appendDots: this.$el
        .siblings(".js-slider-nav-wrapper")
        .find(".js-slider-nav"),
      prevArrow: `
        <button type="button" class="slick-prev" tabindex="0" aria-label="go back one slide">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd">
              <path d="M4.34099026,11.3928571 L22,11.3928571 C22.4142136,11.3928571 22.75,11.7286436 22.75,12.1428571 C22.75,12.5570707 22.4142136,12.8928571 22,12.8928571 L4.34099026,12.8928571 L10.2035173,18.7553842 L9.14285714,19.8160444 L1.46966991,12.1428571 L9.14285714,4.46966991 L10.2035173,5.53033009 L4.34099026,11.3928571 Z"/>
            </g>
          </svg>
        </button>`,
      nextArrow: `
        <button type="button" class="slick-next" tabindex="0" aria-label="advance one slide">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd">
              <path d="M4.34099026,11.3928571 L22,11.3928571 C22.4142136,11.3928571 22.75,11.7286436 22.75,12.1428571 C22.75,12.5570707 22.4142136,12.8928571 22,12.8928571 L4.34099026,12.8928571 L10.2035173,18.7553842 L9.14285714,19.8160444 L1.46966991,12.1428571 L9.14285714,4.46966991 L10.2035173,5.53033009 L4.34099026,11.3928571 Z"/>
            </g>
          </svg>
       </button>`,
      responsive: [
        {
          breakpoint: 624,
          settings: {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            appendArrows: this.$el
              .siblings(".js-slider-nav-wrapper")
              .find(".js-slider-nav"),
            prevArrow: `
              <button type="button" class="slick-prev" tabindex="0" aria-label="go back one slide">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <g fill="none" fill-rule="evenodd">
                    <path d="M4.34099026,11.3928571 L22,11.3928571 C22.4142136,11.3928571 22.75,11.7286436 22.75,12.1428571 C22.75,12.5570707 22.4142136,12.8928571 22,12.8928571 L4.34099026,12.8928571 L10.2035173,18.7553842 L9.14285714,19.8160444 L1.46966991,12.1428571 L9.14285714,4.46966991 L10.2035173,5.53033009 L4.34099026,11.3928571 Z"/>
                  </g>
                </svg>
              </button>`,
            nextArrow: `
              <button type="button" class="slick-next" tabindex="0" aria-label="advance one slide">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                  <g fill="none" fill-rule="evenodd">
                    <path d="M4.34099026,11.3928571 L22,11.3928571 C22.4142136,11.3928571 22.75,11.7286436 22.75,12.1428571 C22.75,12.5570707 22.4142136,12.8928571 22,12.8928571 L4.34099026,12.8928571 L10.2035173,18.7553842 L9.14285714,19.8160444 L1.46966991,12.1428571 L9.14285714,4.46966991 L10.2035173,5.53033009 L4.34099026,11.3928571 Z"/>
                  </g>
                </svg>
             </button>`,
          },
        },
      ],
    };
  }
}
