import { connect } from "react-redux";
import FilterSelectBar from "../components/FilterSelect/FilterSelectBar.jsx";
import {
  loadFacetChange,
  loadClearFacetGroup,
  loadClearAllFacets
} from "../redux/search/search.actions";

const mapStateToProps = (state, ownProps) => {
  return {
    title: state.dictionary.filterTitle,
    filterGroups: state.facets,
    dictionary: state.dictionary
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterChange: (facetId, facetValue) => {
      dispatch(loadFacetChange(facetId, facetValue));
    },

    handleClearGroup: group => () => {
      dispatch(loadClearFacetGroup(group));
    },

    handleClearAll: () => {
      dispatch(loadClearAllFacets());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterSelectBar);
