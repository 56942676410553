import $ from "jquery";
import { breakpoint } from "../breakpoint";

let throttler;
const keyCodes = {
  tab: 9,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  enter: 13,
  space: 32,
  escape: 27,
};

/*
 * Trigger class for Hamburger-like menues
 * params:
 *  $el: jQuery element, burger menu trigger
 *  skipTriggerInTab: when true, confine tabbing behavior to target and
 *    skip trigger (trigger is not a toggle)
 *  preventBodyScroll: if true, locks body on burger open
 */
export class ShareButtons {
  constructor($el) {
    this.$el = $el;
    this.$target = $("#" + this.$el.data("target")); // Body of menu
    this.inShareMenu = false;

    this.$shareCircleLinks = this.$target
      .find(".share_circle_links")
      .children();
    this.shareCircleLinkActive = 0;

    this.manageBreakpointBehaviors();
    this.bindEvents();
  }

  bindEvents() {
    let thisMenu = this;

    /* Click controls for trigger open */
    this.$el.on("click", function () {
      thisMenu.$target.toggle().attr("aria-hidden", thisMenu.getHiddenState());
      thisMenu.$el.toggleClass("is-open");
      thisMenu.inShareMenu = !thisMenu.inShareMenu;
      if (thisMenu.preventBodyScroll) {
        $("body").toggleClass("is-scroll-frozen");
      }

      if (thisMenu.inShareMenu) {
        if (thisMenu.$focus) {
        } else {
        }
      }
    });

    thisMenu.$shareCircleLinks.each(function () {
      setUpDownKeys($(this));
    });

    function setUpDownKeys(circ) {
      circ.on("keydown", function (e) {
        switch (e.keyCode) {
          case keyCodes.up:
            thisMenu.shareCircleLinkActive = thisMenu.shareCircleLinkActive - 1;
            if (thisMenu.shareCircleLinkActive <= 0) {
              thisMenu.shareCircleLinkActive =
                thisMenu.$shareCircleLinks.length;
            }
            if (thisMenu.inShareMenu) {
              e.preventDefault();
              thisMenu.$shareCircleLinks[
                thisMenu.shareCircleLinkActive - 1
              ].focus();
            }
            break;

          case keyCodes.down:
            thisMenu.shareCircleLinkActive = thisMenu.shareCircleLinkActive + 1;
            if (
              thisMenu.inShareMenu &&
              thisMenu.shareCircleLinkActive > thisMenu.$shareCircleLinks.length
            ) {
              thisMenu.shareCircleLinkActive = 1;
            }
            if (thisMenu.inShareMenu) {
              e.preventDefault();
              thisMenu.$shareCircleLinks[
                thisMenu.shareCircleLinkActive - 1
              ].focus();
            }
            break;

          case keyCodes.tab:
            if (thisMenu.inShareMenu) {
              thisMenu.$target.hide().attr("aria-hidden", "true");
              thisMenu.$el.removeClass("is-open");
              thisMenu.shareCircleLinkActive = 0;
              thisMenu.inShareMenu = !thisMenu.inShareMenu;
              thisMenu.$el.focus();
            }
            break;

          case keyCodes.escape:
            e.preventDefault();
            thisMenu.$target.hide().attr("aria-hidden", "true");
            thisMenu.$el.removeClass("is-open");
            thisMenu.shareCircleLinkActive = 0;
            thisMenu.inShareMenu = !thisMenu.inShareMenu;
            break;

          case keyCodes.enter:
            e.preventDefault();
            if (thisMenu.inShareMenu) {
              if (thisMenu.shareCircleLinkActive == 0) {
                thisMenu.$target
                  .toggle()
                  .attr("aria-hidden", thisMenu.getHiddenState());
                thisMenu.$el.toggleClass("is-open");
                thisMenu.inShareMenu = !thisMenu.inShareMenu;
                thisMenu.shareCircleLinkActive = 0;
                if (thisMenu.preventBodyScroll) {
                  $("body").toggleClass("is-scroll-frozen");
                }
              } else {
                thisMenu.$shareCircleLinks[
                  thisMenu.shareCircleLinkActive - 1
                ].click();
                thisMenu.$el.focus();
                thisMenu.$el.toggleClass("is-open");
                thisMenu.$target
                  .toggle()
                  .attr("aria-hidden", thisMenu.getHiddenState());

                thisMenu.shareCircleLinkActive = 0;
                thisMenu.inShareMenu = !thisMenu.inShareMenu;
              }
            } else {
              thisMenu.$target
                .toggle()
                .attr("aria-hidden", thisMenu.getHiddenState());
              thisMenu.$el.toggleClass("is-open");
              thisMenu.inShareMenu = !thisMenu.inShareMenu;
              thisMenu.shareCircleLinkActive = 0;
              if (thisMenu.preventBodyScroll) {
                $("body").toggleClass("is-scroll-frozen");
              }
            }
            break;
          case keyCodes.space:
            e.preventDefault();

            thisMenu.$target
              .toggle()
              .attr("aria-hidden", thisMenu.getHiddenState());
            thisMenu.$el.toggleClass("is-open");
            thisMenu.inShareMenu = !thisMenu.inShareMenu;
            if (thisMenu.preventBodyScroll) {
              $("body").toggleClass("is-scroll-frozen");
            }
            if (thisMenu.inShareMenu) {
              if (thisMenu.$focus) {
                thisMenu.$focus.focus();
              } else {
                //thisMenu.$targetsFirstTabbable.focus();
              }
            }
            break;
          default:
        }
      });
    }

    setUpDownKeys(thisMenu.$el);

    $(window).on("resize", (event) => {
      var mobileMenuOpen = $(".js-hamburger").hasClass("is-open");

      if (throttler) {
        window.clearTimeout(throttler);
      }
      throttler = setTimeout(() => {
        this.manageBreakpointBehaviors(mobileMenuOpen);
      }, 100);
    });
  }

  /* Helper function to grab current open state */
  getHiddenState() {
    return this.$target.attr("aria-hidden") === "true" ? "false" : "true";
  }

  /*
    Handle changes on breakpoint.
    In general, we want to close/hide the menu as appropriate when switching between
      major breakpoints, as well as set or unset the appropriate aria attributes
  */
  manageBreakpointBehaviors(mobileMenuOpen) {
    if (
      (breakpoint() === "xsmall" ||
        breakpoint() === "xxsmall" ||
        breakpoint() === "small") &&
      mobileMenuOpen
    ) {
      // Show mobile menu
      this.$target.attr("aria-hidden", "false").css("display", "block");
      this.inMenuMode = true;
      $("body").addClass("is-scroll-frozen");
    } else if (
      breakpoint() === "xsmall" ||
      breakpoint() === "xxsmall" ||
      breakpoint() === "small"
    ) {
      // Hide mobile menu

      this.$target.hide().attr("aria-hidden", "true").css("display", "");
      this.inMenuMode = false;
      this.$el.removeClass("is-open");
      $("body").removeClass("is-scroll-frozen");
    } else {
      // On desktop view, force menu to appear after visiting mobile view
      //this.$primaryNavMenu.show().attr("aria-hidden", "false").css("display", "block");
      this.inMenuMode = false;
      this.$el.removeClass("is-open");
      $("body").removeClass("is-scroll-frozen");
    }
  }
}
