import $ from "jquery";
import "intersection-observer";
import scrollama from "scrollama";
import { scaleLinear } from "d3-scale";

let throttler;

/*
 * Alert
 * Close an alert banner and notify the BE that it's been read via the endpoint in data-endpoint
 */
export class ExperienceDrexel {
    constructor(el) {
        this.el = el;
        this.scroller = scrollama();
        this.$slidein = $(this.el).find(".hero-experience__title");
        this.$experLogo = $(this.el).find(".expereience-logo");

        this.$heroSubtitle = $(this.el).find(".heroSubtitle");
        /* Force wordmark out of view */
        $(this.el).find(".animated-hero__overlay").css({
          opacity: 0,
          height: "300%",
          width: "300%"
        });
    
        this.scroller.setup({
          step: this.el,
          progress: true,
          offset: $(this.el).offset().top / $(window).height(),
          debug: false,
          threshold: 1
        })
          .onStepProgress(this.handleStepProgress)
          .onStepEnter(this.handleStepEnter);
    
        this.bindEvents();
        
      }
    
      bindEvents() {
        let thisScroller = this;
        $(window).on("load", function(){
        
            thisScroller.$slidein.css('visibility', 'visible');
            thisScroller.$slidein.addClass("animate__fadeInLeftBig");
              setTimeout(function(){
                thisScroller.$experLogo.css('visibility', 'visible');
                thisScroller.$experLogo.addClass("animate__fadeInLeftBig");
              } , 1000);
              setTimeout(function(){
                thisScroller.$heroSubtitle.css('visibility', 'visible');
                thisScroller.$heroSubtitle.addClass("animate__fadeInLeftBig");
              }, 1750);
          });
        /* Reset trigger point on resize */
        $(window).on("resize", event => {
          if (throttler) {
            window.clearTimeout(throttler);
          }
          throttler = setTimeout(() => {
            thisScroller.scroller.offsetTrigger($(thisScroller.el).offset().top / $(window).height());
            thisScroller.scroller.resize();
          }, 400);
        });

        
      }
    
      handleStepProgress(response) {
        let $image = $(response.element).find("img");
        let ratio = $image.width() / $image.height();
        /* Differently proportioned images benefit from different timing here */
        let domainstart = ratio > 1.5 ? 0.05 : 0.1;
        let domainend = ratio > 1.5 ? 0.5 : 0.6;
    
        /* Scale the progress value based on animation start and end points */
        let scale = scaleLinear()
          .domain([domainstart, domainend])
          .range([0, 1])
          .clamp(true);
    
        let progressShifted = scale(response.progress);
    
        $(response.element).find(".animated-hero__overlay").css({
          opacity: progressShifted * 1,
          height: (300 - (progressShifted * 200)) + "%",
          width: (300 - (progressShifted * 200)) + "%",
          bottom: (100 - (progressShifted * 100)) + "%"
        });
      }
    
      handleStepEnter(response) {
        if (response.direction === "up") {
          /* Reset everything to make certain the animation doesn't get "stuck" */
          $(response.element).find(".animated-hero__overlay").css({
            opacity: 0,
            height: "300%",
            width: "300%",
            bottom: "100%"
          });
        }
      }
      
    }
    
//   constructor($el) {
//     this.$el = $el;
//     this.$slidein = this.$el.find(".hero-experience__title");
//     // this.$close = this.$el.find(".js-alert__close");
//     // this.endpoint = this.$el.data("endpoint");
//     // this.id = this.$el.data("id");
//     alert("loaded");
//     //this.bindEvents();
//     $(document).ready(function(){
        
//         this.$slidein.css('visibility', 'visible');
//         this.$slidein.addClass("animate__slideInLeft");

//       });
//   }

//   bindEvents() {
//     let thisAlert = this;

//     window.on("load", function() {

//     });
    
//     thisAlert.$close.on("click", function() {
//       thisAlert.$el.slideUp(function() {
//         thisAlert.$el.remove();
//       });
//       fetch(thisAlert.endpoint, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json; charset=utf-8"
//         },
//         body: JSON.stringify({
//           "id": thisAlert.id
//         })
//       });
//     });
//   }
// }
