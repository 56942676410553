import React from "react";
import R from "ramda";

import {
  withSearchId,
  withNode,
  withServiceApi,
  withQueryStringRouting,
  withoutQueryStringRouting,
  withInitialState,
  withInitialDispatch,
} from "./factory";

import {
  initialState as defaultInitialState,
  applyExternalConfig,
  applyResultDisplayStrategy,
} from "./redux/initialState";
import {
  fetchSearchResults as defaultFetchResults,
  mapResponseToState as defaultMapResponseToState,
} from "./redux/service.js";
import { loadResults } from "./redux/search/search.actions";

import {
  mapStateToUrl as defaultMapStateToUrl,
  handleUrlChange as defaultHandleUrlChange,
} from "./redux/routing";

import SearchResultsContainer from "./containers/SearchResultsContainer.jsx";
import SimpleResultsContainer from "./containers/SimpleResultsContainer.jsx";
import EventResultsContainer from "./containers/Events/EventResultsContainer.jsx";
import DirectoryResultsContainer from "./containers/Directory/DirectoryResultsContainer.jsx";
import QueryContainer from "./containers/QueryContainer.jsx";
import FacetsContainer from "./containers/FacetsContainer.jsx";
import EventFacetsContainer from "./containers/Events/EventFacetsContainer.jsx";
import ProgramsResultsContainer from "./containers/Programs/ProgramsResultsContainer.jsx";
import VisitFacetsContainer from "./containers/Visits/VisitFacetsContainer.jsx";
import VisitSidebar from "./containers/Visits/VisitSidebar.jsx";
import FilterBarContainer from "./containers/FilterBarContainer.jsx";
import DateFacetContainer from "./containers/DateFacetContainer.jsx";
import DateFacetContainerMobile from "./containers/DateFacetContainerMobile.jsx";
import VideoFacetsContainer from "./containers/Videos/VideoFacetsContainer.jsx";
import VideoResultsContainer from "./containers/Videos/VideoResultsContainer.jsx";
import AdmissionRepResultsContainer from "./containers/AdmissionReps/AdmissionRepResultsContainer.jsx";
import AdmissionRepFacetsContainer from "./containers/AdmissionReps/AdmissionRepFacetsContainer.jsx";
import InTheNewsSearchResultsContainer from "./containers/InTheNewsSearchResultsContainer.jsx";
import InTheNewsFacetsContainer from "./containers/InTheNewsFacetsContainer.jsx";

// Default search app factory function, without any React components
// specified. This is a good "base factory" to use if you want the
// "out-of-box" search config, but with custom components.
export const nodelessDefaultFactory = (searchId) =>
  R.pipe(
    withSearchId(searchId),
    withInitialState(
      defaultInitialState,
      R.pipe(
        applyExternalConfig(window.threadSearchConfigs, searchId),
        applyResultDisplayStrategy,
      ),
    ),
    withQueryStringRouting(defaultMapStateToUrl, defaultHandleUrlChange),
    withServiceApi(defaultFetchResults, defaultMapResponseToState),
    withInitialDispatch(loadResults),
  );

// Default "out-of-box" search page factory
export const defaultFacetedSearchFactory = (searchId) =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withNode(".js-search-query", <QueryContainer />),
    withNode(".js-search-results", <SearchResultsContainer />),
    withNode(".js-search-facets", <FacetsContainer />),
  );

// Factory for directory search
export const defaultDirectorySearchFactory = (searchId) =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withNode(".js-search-query", <QueryContainer />),
    withNode(".js-directory-results", <DirectoryResultsContainer />),
    withNode(".js-search-facets", <EventFacetsContainer />),
  );

// Factory for staff search
export const defaultStaffSearchFactory = (searchId) =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withNode(".js-search-query", <QueryContainer />),
    withNode(".js-staff-search-results", <DirectoryResultsContainer />),
    withNode(".js-staff-search-facets", <EventFacetsContainer />),
  );

// Factory for Dynamic Content Listing apps
export const defaultDynamicContentFactory = (searchId) =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withoutQueryStringRouting(),
    withNode(".js-dynamic-results", <SimpleResultsContainer />),
    withNode(
      ".js-dynamic-filter-bar",
      <FilterBarContainer showSelected={true} />,
    ),
  );

// Factory for Program Listing apps
export const defaultProgramContentFactory = (searchId) =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withNode(".js-program-query", <QueryContainer />),
    withNode(".js-program-results", <ProgramsResultsContainer />),
    withNode(".js-program-facets", <EventFacetsContainer />),
  );

// Factory for Event Listing apps
export const defaultEventContentFactory = (searchId) =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withNode(".js-search-query", <QueryContainer />),
    withNode(".js-date-facets", <DateFacetContainer />),
    withNode(".js-date-facets-mobile", <DateFacetContainerMobile />),
    withNode(".js-event-results", <EventResultsContainer />),
    withNode(".js-search-facets", <EventFacetsContainer />),
  );

// Factory for Video Grid apps
export const defaultVideoContentFactory = (searchId) =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withNode(".js-video-results", <VideoResultsContainer />),
    withNode(".js-search-facets", <VideoFacetsContainer />),
  );

// Factory for Visit Listing apps
export const defaultVisitContentFactory = (searchId) =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withNode(".js-date-facets", <DateFacetContainer />),
    withNode(".js-date-facets-mobile", <DateFacetContainerMobile />),
    withNode(".js-visit-results", <EventResultsContainer />),
    withNode(".js-search-facets", <VisitFacetsContainer />),
    withNode(".js-visit-sidebar", <VisitSidebar />),
  );

// Factory for admission reps search
export const defaultAdmissionRepSearchFactory = (searchId) =>
  R.pipe(
    nodelessDefaultFactory(searchId),
    withNode(".js-search-query", <QueryContainer />),
    withNode(".js-admissionrep-results", <AdmissionRepResultsContainer />),
    withNode(".js-admissionrep-facets", <AdmissionRepFacetsContainer />),
  );

 // In The News search page factory
export const defaultInTheNewsSearchFactory = (searchId) =>
R.pipe(
  nodelessDefaultFactory(searchId),
  withNode(".js-search-query", <QueryContainer />),
  withNode(".js-inthenewssearch-results", <InTheNewsSearchResultsContainer />),
  withNode(".js-search-facets", <InTheNewsFacetsContainer />),
);