import PropTypes from "prop-types";
import React from "react";
import EventFacetGroup from "./Events/EventFacetGroup.jsx";
import EventTypeaheadGroup from "./Events/EventTypeaheadGroup.jsx";
import DateFacets from "./DateFacets.jsx";
import { breakpoint } from "../../breakpoint";
import classNames from "classnames";

const ClearAll = (props) => {
  const { handleClear } = props;
  return (
    <button
      onClick={handleClear}
      className="event-facets__group-clear"
      type="button"
    >
      Clear All
    </button>
  );
};

export default class FacetList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentBreakpoint: breakpoint(),
      drawerOpen: !(breakpoint().indexOf("small") > -1),
      inToggleMode: breakpoint().indexOf("small") > -1,
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.updateBreakpoint);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateBreakpoint);
  };

  updateBreakpoint = () => {
    const { currentBreakpoint } = this.state;
    const newBreakpoint = breakpoint();

    if (currentBreakpoint !== newBreakpoint) {
      // Only update if we're actually switching breakpoints
      // This avoids issues like filter dropdowns triggering viewport changes on selection on Android phones
      this.setState({
        ...this.state,
        currentBreakpoint: breakpoint(),
        drawerOpen: !(breakpoint().indexOf("small") > -1),
        inToggleMode: breakpoint().indexOf("small") > -1,
      });
    }
  };

  clearAll() {
    this.props.handleClearAll();
  }

  renderEventFacetGroups() {
    return this.props.facets.map((group, i) => {
      if (group.values.length === 0 && group.childGroups.length === 0) {
        return null;
      }

      switch (group.type) {
        case "typeahead":
          return (
            <EventTypeaheadGroup
              key={group.id}
              handleFacetChange={this.props.handleFacetChange}
              group={group}
            />
          );
        default:
          return (
            <EventFacetGroup
              key={group.id}
              handleFacetChange={this.props.handleFacetChange}
              group={group}
            />
          );
      }
    });
  }

  toggleFacets = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen,
    });
  };

  render() {
    const EventFacetGroups = this.renderEventFacetGroups();
    const {
      dateConstraints,
      dateQuickPicks,
      endDate,
      handleDateChange,
      startDate,
    } = this.props;
    const { drawerOpen, inToggleMode } = this.state;

    const facetClass = classNames("event-facets", {
      "is-open": drawerOpen,
      "in-toggle-mode": inToggleMode,
    });

    return (
      <div className={facetClass}>
        {!inToggleMode && <h3>Filter Results</h3>}
        {inToggleMode && (
          <h3>
            <button onClick={this.toggleFacets}>Filter Results</button>
          </h3>
        )}
        <div className="event-facets__all-facets">
          {drawerOpen && <ClearAll handleClear={() => this.clearAll()} />}
          {drawerOpen && EventFacetGroups}
          {drawerOpen && dateQuickPicks && (
            <DateFacets
              dateConstraints={dateConstraints}
              dateQuickPicks={dateQuickPicks}
              startDate={startDate}
              endDate={endDate}
              showCheckbox={true}
              handleDateChange={handleDateChange}
            />
          )}
          {drawerOpen && <ClearAll handleClear={() => this.clearAll()} />}
        </div>
      </div>
    );
  }
}

const { string, arrayOf, bool, shape, number, oneOfType, func, object } =
  PropTypes;
FacetList.propTypes = {
  title: string,
  mobileFiltersShown: bool,
  hideMobileFacets: func,
  facets: arrayOf(shape({ id: oneOfType([number, string]) })),
  numOpenGroups: number,
  handleFacetChange: func.isRequired,
  handleClearAll: func.isRequired,
  dateQuickPicks: arrayOf(object),
  endDate: string,
  startDate: string,
  handleDateChange: func,
  dateConstraints: object,
};

ClearAll.propTypes = {
  handleClear: func,
};

FacetList.defaultProps = {
  facets: [],
  numOpenGroups: null,
};
