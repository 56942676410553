import React from "react";
import moment from "moment";

import { bool, func, number, string } from "prop-types";

const propTypes = {
  endDate: string,
  handleDateChange: func,
  label: string,
  idmodifier: string,
  index: number,
  spanType: string,
  startDate: string,
  showCheckbox: bool
};

const checkIfSelected = (spanType, endDate, startDate) => {
  if (!spanType && !endDate && !startDate) {
    return true;
  }
  else if (spanType) {
    let spanStart = moment().format("MMDDYYYY");
    let spanEnd = moment().endOf(spanType).format("MMDDYYYY");

    if (spanStart === moment(startDate).format("MMDDYYYY") && spanEnd === moment(endDate).format("MMDDYYYY")) {
      return true;
    }
  }
  return false;
};

const onDateChange = (spanType, handleDateChange) => {
  let spanStart = spanType ? moment().format() : null;
  let spanEnd = spanType ? moment().endOf(spanType).format() : null;

  handleDateChange(spanStart, spanEnd);
};

const DateQuickPick = (props) => {
  const { endDate, handleDateChange, idmodifier, index, label, showCheckbox, spanType, startDate } = props;
  const isSelected = checkIfSelected(spanType, endDate, startDate);
  const id = "quickpick" + index + idmodifier;

  return (
    <span className="date-quick-pick">
      <input type="checkbox"
        id={id}
        checked={isSelected}
        onChange={ () => onDateChange(spanType, handleDateChange) }
      />
      <label htmlFor={id}>{ showCheckbox &&
        <span className="date-quick-pick__check">
          { isSelected &&
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <polygon points="8.59 15.58 4.42 11.41 3 12.82 8.59 18.41 20.59 6.41 19.18 5"/>
              </g>
            </svg>
          }
        </span>
      }{ label }</label>
    </span>
  );
};

DateQuickPick.propTypes = propTypes;

export default DateQuickPick;
