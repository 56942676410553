import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { isLastPage } from "../paging";
import ResultItem from "./ResultItem.jsx";
import LoadMore from "./LoadMore.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import SortBar from "./SortBar.jsx";
import SearchStatus from "./SearchStatus.jsx";
import Pagination from "./Pagination.jsx";
import {
  RESULTS_DISPLAY_REFRESH,
  RESULTS_DISPLAY_APPEND,
} from "../redux/resultsDisplay";

const SearchResultList = ({
  results,
  resultsDisplayStrategy,
  selectedFacets,
  totalResults,
  loading,
  listTitle,
  isFirstLoad,
  sorters,
  nextPage,
  gotoPage,
  dismissFacet,
  changeSort,
  query,
  pagination,
  dictionary,
  searchId,
}) => {
  const isLast = isLastPage(totalResults, query.perPage, query.page);
  const shouldShowNoResults = results.length === 0 && !isFirstLoad;
  const shouldShowPager = totalResults > query.perPage;

  const searchLinkTarget = searchId + "-jumptarget";
  const resultLabel =
    totalResults === 1 ? dictionary.singleresult : dictionary.pluralresult;

  function wrapperClass() {
    const isRefreshing =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
    const isAppending =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
    return classnames("search-results", {
      "is-refreshing": isRefreshing,
      "is-appending": isAppending,
    });
  }

  function resultsRange() {
    // Upper bound of visible results
    let ceiling = query.page * query.perPage;

    // Lower bound of visible results
    let floor = ceiling - query.perPage + 1;

    // make sure the ceiling does not exceed total results
    if (ceiling > totalResults) {
      ceiling = totalResults;
    }

    // if results are only one page, eschew the range entirely
    if (totalResults <= query.perPage) {
      return `${totalResults}`;
    }

    // if we're using load more, we want to show only
    // the ceiling of results displayed
    if (resultsDisplayStrategy === RESULTS_DISPLAY_APPEND) {
      return `${ceiling}`;
    }

    // otherwise, return the full range
    return `${floor} - ${ceiling}`;
  }

  function renderPagingControl() {
    if (pagination) {
      return (
        <Pagination
          totalResults={totalResults}
          resultsPerPage={query.perPage}
          currentPage={query.page}
          handlePageChange={gotoPage}
        />
      );
    }

    if (!pagination && !isLast) {
      return (
        <LoadMore
          handleClick={nextPage}
          dictionary={dictionary}
          loading={loading}
        />
      );
    }
  }

  return (
    <section className={wrapperClass()}>
      <div className="search-results__title-bar">
        {listTitle && <h3>{listTitle}</h3>}
        {!listTitle && sorters.length > 0 && (
          <SortBar sorters={sorters} handleSortClick={changeSort} />
        )}
        <SearchStatus
          resultsRange={resultsRange()}
          totalResults={totalResults}
          keywords={query.q}
          selectedFacets={selectedFacets}
          handleFacetDismiss={dismissFacet}
          resultLabel={resultLabel}
        />
      </div>
      {listTitle && sorters.length > 0 && (
        <SortBar sorters={sorters} handleSortClick={changeSort} />
      )}
      <div className="search-results__item-wrapper" id={searchLinkTarget}>
        {loading && (
          <div className="search-results__loader">
            <LoadingSpinner />
          </div>
        )}
        <div className="search-results__items">
          {shouldShowNoResults ? (
            <p>No results found to match your query.</p>
          ) : null}
          {results.map((item) => (
            <ResultItem
              key={item.id}
              featuredResultLabel={dictionary.featuredResultLabel}
              {...item}
            />
          ))}
        </div>
      </div>
      {shouldShowPager ? renderPagingControl() : null}
    </section>
  );
};

const { arrayOf, string, object, func, bool, number, shape, oneOfType } =
  PropTypes;

SearchResultList.propTypes = {
  results: arrayOf(
    shape({
      id: oneOfType([number, string]),
    }),
  ),
  resultsDisplayStrategy: number,
  selectedFacets: arrayOf(object).isRequired,
  totalResults: number.isRequired,
  sorters: arrayOf(
    shape({
      id: oneOfType([number, string]).isRequired,
      label: string.isRequired,
      value: string.isRequired,
      direction: oneOfType([bool, string]),
      isActive: bool.isRequired,
    }),
  ),
  loading: bool,
  isFirstLoad: bool,
  nextPage: func,
  gotoPage: func,
  changeSort: func,
  listTitle: string,
  dismissFacet: func,
  query: shape({
    q: string,
    sortBy: string,
    page: number,
    perPage: number,
    pageId: string,
  }),
  pagination: bool,
  dictionary: object,
  searchId: string,
};

SearchResultList.defaultProps = {
  selectedFacets: [],
};

export default SearchResultList;
