import $ from "jquery";
import R from "ramda";
import {
  RESULTS_DISPLAY_APPEND,
  RESULTS_DISPLAY_REFRESH,
} from "./resultsDisplay";

const newResults = R.curry((newResults, oldResults, strategy) => {
  return strategy === RESULTS_DISPLAY_APPEND
    ? R.concat(oldResults, newResults)
    : newResults;
});

export function fetchSearchResults(url, query) {
  return $.ajax({
    url: url,
    data: query,
  });
}

const setActiveSorter = (sorters, sortBy) =>
  R.pipe(
    R.map(R.assoc("isActive", false)),
    R.map(R.when(R.propEq("value", sortBy), R.assoc("isActive", true))),
  )(sorters);

export function mapResponseToState(response, state) {
  const request = response.request;
  const castToInt = R.curry(parseInt)(R.__, 10);
  return R.mergeDeepLeft(
    {
      totalResults: parseInt(response.totalResults, 10),
      results: newResults(
        response.results,
        state.results,
        state.resultsDisplayStrategy,
      ),
      facets: response.facets,
      sorters: setActiveSorter(state.sorters, response.request.sortBy),
      listTitle: response.listTitle,
      sidebar: response.sidebar,
      alertbanner: response.alertbanner,
      query: {
        pageId: request.pageId,
        page: castToInt(request.page),
        perPage: castToInt(request.perPage),
        sortOrder: request.sortOrder,
        loadAllPages: false,
      },
    },
    state,
  );
}
