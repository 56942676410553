import React from "react";
import classNames from "classnames";

import { array, object, string } from "prop-types";

/*
  NewsListingContainer
 */
export default class NewsListingContainer extends React.Component {

  static propTypes = {
    listItems: array,
    newsTitle: string,
    mobileLink: object
  };

  UNSAFE_componentWillMount() {}

  render() {
    const { listItems, mobileLink, newsTitle } = this.props;
    const classList = classNames("news-listing", {});

    return (
      <div className={classList}>
        <h2 className="news-listing__title">{ newsTitle }</h2>
        <div className="news-listing__items">
          { listItems.map( (item, i) => {
            return (<div key={i} className="news-listing__item">
              { item.img && <a href={ item.url }><img className="news-listing__image" src={ item.img } alt={ item.title } /></a> }
              <a className="news-listing__item-title" href={ item.url }>
                { item.title }
              </a>
              <div className="news-listing__item-dateline">
                { item.source && <span className="news-listing__item-source">{ item.source }</span>}{ item.date && <span className="news-listing__item-date">{ item.date }</span>}
              </div>
              <div className="news-listing__item-teaser">
                { item.summary }
              </div>
            </div>);
          }) }
        </div>
        { mobileLink && <div className="news-listing__mobile-button">
          <a className="btn btn--primary" href={ mobileLink.url }>{ mobileLink.text }</a>
        </div> }
      </div>
    );
  }
}
