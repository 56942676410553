import { connect } from "react-redux";
import DateFacets from "../components/DateFacets.jsx";

import {
  loadDateChange
} from "../redux/search/search.actions";

const mapStateToProps = (state, ownProps) => {
  return {
    title: state.dictionary.facetsTitle,
    skipLinkText: state.dictionary.skipLinkText,
    facets: state.facets,
    numOpenGroups: state.numOpenGroups,
    mobileFacetsShown: state.mobileFacetsShown,
    searchId: state.searchId,
    startDate: state.query.startDate,
    endDate: state.query.endDate,
    dateQuickPicks: state.dateQuickPicks,
    dateConstraints: state.dateConstraints,
    idmodifier: "mobile"
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDateChange: (startDate, endDate) => {
      dispatch(loadDateChange(startDate, endDate));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFacets);
