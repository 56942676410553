import R from "ramda";
import React from "react";
import PropTypes from "prop-types";
import { facetValueShape, facetGroupShape } from "../../dataShapes";

const MultiSelectList = ({ items, groups, onChange, onClear, label, showChildren }) => {
  const FilterItem = ({ item, onChange }) => (
    <li>
      <input
        className="u-visuallyhidden"
        type="checkbox"
        onChange={e => onChange(item.id, e.target.checked)}
        id={item.id}
        checked={item.selected}
      />
      <label htmlFor={item.id}>
        {item.name}&nbsp;{`(${item.count})`}
      </label>
      {item.childValues &&
        !!item.childValues.length &&
        showChildren && (
        <ul className="multiselect-list">
          {item.childValues.map((value, i) => (
            <FilterItem
              item={value}
              onChange={onChange}
              onClear={onClear}
              key={i}
            />
          ))}
        </ul>
      )}
    </li>
  );

  return (
    <React.Fragment>
      <ul className="multiselect-list">
        {label && <li className="multiselect-list__label">{label}</li>}
        {items.map((item, i) => (
          <FilterItem item={item} onChange={onChange} key={i} />
        ))}
      </ul>
      {groups && groups.map( (g, i) => (<MultiSelectList
        key={i}
        items={g.values}
        groups={g.childGroups}
        label={g.label}
        hideSelcted={true}
        onChange={onChange}
        onClear={onClear}
      />))}
    </React.Fragment>
  );
};

export default MultiSelectList;

const { arrayOf, func, bool, string } = PropTypes;
MultiSelectList.propTypes = {
  items: arrayOf(facetValueShape).isRequired,
  groups: arrayOf(facetGroupShape),
  label: string,
  showChildren: bool,
  onChange: func.isRequired,
  onClear: func.isRequired
};

MultiSelectList.defaultProps = {
  showChildren: true
};
