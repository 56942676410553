/** @module search/components */

import React from "react";
import PropTypes from "prop-types";
import R from "ramda";
import { facetValueShape } from "../../dataShapes";
import Select from "react-select";

/**
 *
 * A section for a single grouping of event facets.
 * ```
 *  <AdmissionRepTypeaheadGroup
      group={group}
      handleFacetChange={func}
      handleClearAll={func} />
 * ```
 * @component
 * @param {object} props
 * @param {object} props.group The group of facets to show
 * @param {function} props.handleFacetChange Function called when a facet is selected/deselected
 * @params {function} prop.handleClearGroup Function called when clearing all selected facets
 */

class AdmissionRepTypeaheadGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  onFacetChange = (value) => {
    // For a typeahead, the value is the selected value object of the facet.

    const { group, handleFacetChange, handleClearGroup } = this.props;

    if (!value) {
      handleClearGroup(group);
      return;
    }

    handleFacetChange(value.id, group);
  };

  render() {
    const group = this.props.group;
    const reactSelectValues = group.values.map((value) => {
      value.value = value.id;
      value.label = value.name;
      return value;
    });
    const available = reactSelectValues.filter((value) => {
      return !value.selected;
    });
    const selected = reactSelectValues.filter((value) => {
      return value.selected;
    });

    const multi = group.singleChoice === true ? false : true;

    const clearable =
      typeof group.clearable !== "undefined" ? group.clearable : !multi;

    return (
      <div className="event-typeahead">
        <h4>
          <label id={group.id}>{group.label}</label>
        </h4>
        <Select
          isMulti={multi}
          value={selected}
          onChange={this.onFacetChange}
          options={available}
          isClearable={clearable}
          classNamePrefix="event-typeahead"
          maxMenuHeight={200}
          aria-labelledby={group.id}
        />
      </div>
    );
  }
}

export default AdmissionRepTypeaheadGroup;

const { func, shape, bool, number, string, oneOfType, arrayOf } =
  PropTypes;

AdmissionRepTypeaheadGroup.propTypes = {
  dupeSelected: bool,
  handleFacetChange: func.isRequired,
  handleClearGroup: func.isRequired,
  group: shape({
    id: oneOfType([number, string]).isRequired,
    label: string,
    values: arrayOf(facetValueShape).isRequired,
  }),
};

AdmissionRepTypeaheadGroup.defaultProps = {
  dupeSelected: true,
};
