import $ from "jquery";

export class HeroStickyScroll {
      constructor(selector, scrollOffset) {
        this.$el = $(selector);
        this.$headerHeight = $(document).find("header").height();
        this.$stickyHeader = $(this.$el).find(".single-page-hero-sticky");
        this.$heroFullHeight = $(this.$el).height();
        this.$image = $(this.$el).find("#parallax_image").attr("src");
        this.$backgroundParallax = $(this.$el).find(".single-page-hero__background");
        this.$backgroundParallax.css("background-image", "url(" + this.$image + ")");
        this.$singlePageOverlay = $(this.$el).find(".single-page-hero__overlay");
        this.isMobile = false;
        this.checkIfMobile();
        
        this.$stickyOnMobile = $(this.$el).find(".single-page-hero-sticky-mobile");
        this.isStickyOnMobile = false;
        if(this.$stickyOnMobile.length > 0){
          this.isStickyOnMobile = true;
        }

        this.$backgroundOverlay = $(this.$el).find(".overlay-background");
        this.checkIfAbsolute();
        this.bindEvent();
        this.smallHeight();
      }

      checkIfMobile(){
        const thisSticky = this;
        if(window.innerWidth < 860){
          thisSticky.isMobile = true;
        } else {
          thisSticky.isMobile = false;
        }
      }

      smallHeight(){
        if(window.innerHeight > 850 && window.innerWidth < 860) {
          this.$el.css("height", "50vh");
        } else {
          this.$el.css("height", "100vh");
        }
      }

      bindEvent() {
        const thisSticky = this;

        $(window).on('scroll', () => {
          handleScroll();
        });

        $(window).on('resize', () => {
          thisSticky.smallHeight();
          thisSticky.checkIfMobile();

          thisSticky.$headerHeight = $(document).find("header").height();
          thisSticky.$heroFullHeight = $(this.$el).height();
          
          if ($(window).scrollTop() > thisSticky.$heroFullHeight - thisSticky.$headerHeight) {
              thisSticky.$stickyHeader.addClass('js-sticky-scroll');
          } else {
            thisSticky.$stickyHeader.removeClass('js-sticky-scroll');
          }
          handleScroll();
        });

        
      

        const handleScroll = () => {

          const windowWidth = $(window).width();
          thisSticky.checkIfMobile();
          thisSticky.$headerHeight = $(document).find("header").height();
            const scrollTop = $(window).scrollTop();
            const windowHeight = $(window).height();
            if (window.innerWidth > 860 && scrollTop > +thisSticky.$backgroundOverlay.css("bottom").replace('px', '')) {
              thisSticky.$backgroundOverlay.css('position', 'relative');
            }
            else if(window.innerWidth < 860){
              thisSticky.$backgroundOverlay.css('position', 'absolute');
            }
            else {
              thisSticky.$backgroundOverlay.css('position', 'fixed');
            }            
            let elementOffset = $(thisSticky.$backgroundOverlay).offset().top;
            const distance = (elementOffset - scrollTop);

            if(elementOffset == 0){
              elementOffset = thisSticky.$headerHeight * 2;
            }

            if (scrollTop > windowHeight - thisSticky.$headerHeight - thisSticky.$backgroundOverlay.height() && !thisSticky.isMobile) {
              //console.log("if");
              thisSticky.$backgroundOverlay.hide();
              thisSticky.$stickyHeader.addClass('js-sticky-scroll');
              thisSticky.$stickyHeader.css("top", thisSticky.$headerHeight + "px");
              thisSticky.$stickyHeader.css("height", "auto");
            } else if (scrollTop > elementOffset && thisSticky.isMobile && thisSticky.isStickyOnMobile) {
              //console.log("else if mobile");
              thisSticky.$backgroundOverlay.hide();
              thisSticky.$stickyHeader.addClass('js-sticky-scroll');
              thisSticky.$stickyHeader.css("top", thisSticky.$headerHeight + "px");
              thisSticky.$stickyHeader.css("height", "auto");
            } else if(scrollTop <  (thisSticky.$headerHeight*2) ){
              //console.log("else header x 2");
              thisSticky.$backgroundOverlay.show();
              thisSticky.$stickyHeader.removeClass('js-sticky-scroll');
              thisSticky.$stickyHeader.css("height", "0px");
            }
            else {
              //console.log("else");
              thisSticky.$backgroundOverlay.show();
              thisSticky.$stickyHeader.removeClass('js-sticky-scroll');
              thisSticky.$stickyHeader.css("height", "0px");
            }

        };
    
    
        // Initial check in case the page is already scrolled past 100px when loaded
        handleScroll();
    
      
      
      }
      checkIfAbsolute(){
        if(this.$singlePageOverlay.css("position") === "absolute"){
          //this.ismobile = true;
        }
      }


    }

 