// Adapted from the excellent Adrian Roselli's work (https://adrianroselli.com/2021/04/sortable-table-columns.html)
import * as DOMPurify from "dompurify";
import { InteractiveTable } from "../common/InteractiveTable.js";
import { updateAriaLive } from "../../a11y/ariaLiveUtils";
import { breakpoint } from "../../breakpoint";

export class SortableTable extends InteractiveTable {
  constructor($el) {
    super($el); // to access the parent InteractiveTable constructor

    // Consolidating instructions, labels, and messages, in case we need to import them from a Sitecore dictionary in future.
    this.labelClearAll = "clear all";
    this.labelMobileSortPlaceholder = "Sort table by:"; // No HTML
    this.labelSortScreenreader = "Sort"; // No HTML
    this.labelAscending = "Ascending";
    this.labelDescending = "Descending";
    this.labelSortAscScreenreader = "Sort Ascending"; // No HTML
    this.labelSortDesScreenreader = "Sort Descending"; // No HTML
    this.legendMobileSortDirection = "Sort Direction:";

    // It's important that screenreader messages be short (or they may be cut off by screenreaders), and meaningful.
    this.messageSortAscendingScreenreader = "Sorted up.";
    this.messageSortDescendingScreenreader = "Sorted down.";
    this.messageTableSortsClearedScreenreader = "Table sorts cleared.";

    this.locale = (navigator && navigator.language) || "en"; // for sorting
    this.$tableHeaders = null;
    this.sortableColumnReference = [];
    this.$sortButtons = this.createSortButtons();
    this.$svgTemplate = this.createSVGIconTemplate();
    this.tableHeaderIdPrefix = this.$table.id + "-column-";
    this.mobileSortRadioName = this.$table.id + "-mobileSortDir";
    this.mobileSortId = this.$table.id + "__" + "mobilesort";

    this.renderForm();
    this.bindEvents();
  }

  bindEvents() {
    const outerThis = this;
    this.$sortButtons.forEach((button) => {
      button.addEventListener("click", function (e) {
        outerThis.handleSortButtonClick(this.dataset.columnIndex);
      });
    });
  }

  // Render the expandable form shown at mobile
  renderForm() {
    var outerThis = this;

    var fieldset = document.createElement("fieldset");
    fieldset.setAttribute(
      "class",
      "responsive-table__fieldset responsive-table__fieldset--sortable",
    ),
      this.$form.appendChild(fieldset);

    var legend = document.createElement("legend");
    legend.setAttribute("class", "visually-hidden"),
      (legend.innerHTML = DOMPurify.sanitize(this.labelMobileSortPlaceholder)),
      fieldset.appendChild(legend);

    var fieldsetItem = document.createElement("div");
    fieldsetItem.setAttribute("class", "responsive-table__fieldset-item"),
      fieldset.appendChild(fieldsetItem);

    var mobileSortLabel = document.createElement("label");
    mobileSortLabel.setAttribute("for", this.mobileSortId),
      (mobileSortLabel.innerHTML = DOMPurify.sanitize(
        this.labelMobileSortPlaceholder,
      ));
    mobileSortLabel.setAttribute("class", "responsive-table__label");
    fieldsetItem.appendChild(mobileSortLabel);

    var mobileSortDropdown = document.createElement("select");
    mobileSortDropdown.setAttribute("id", this.mobileSortId),
      mobileSortDropdown.setAttribute(
        "class",
        "responsive-table__input responsive-table__input--select",
      ),
      mobileSortDropdown.setAttribute("autocomplete", "on"),
      fieldsetItem.appendChild(mobileSortDropdown);

    var selectOptionDefault = document.createElement("option");
    selectOptionDefault.setAttribute("value", ""),
      (selectOptionDefault.innerHTML = DOMPurify.sanitize(
        this.labelMobileSortPlaceholder,
      )),
      mobileSortDropdown.appendChild(selectOptionDefault);

    this.sortableColumnReference.forEach((column) => {
      var selectOption = document.createElement("option");
      selectOption.setAttribute("value", column.columnIndex),
        (selectOption.innerHTML = DOMPurify.sanitize(column.label)),
        mobileSortDropdown.appendChild(selectOption);
    });

    mobileSortDropdown.addEventListener("change", function (event) {
      var currentBreakpoint = breakpoint();
      // Only fire events when the accordion is visible
      // Prevents infinite loops, when the desktop controls update these!
      if (outerThis.isAccordion(currentBreakpoint) === true) {
        const sortDir = document.querySelector(
          `input[name="${outerThis.mobileSortRadioName}"]:checked`,
        ).value;
        // Update the sort column?
        outerThis.update(event.target.value, sortDir);
      }
    });

    let SortRadioButtons = [];

    var mobileSortRadioFieldset = document.createElement("fieldset");
    mobileSortRadioFieldset.setAttribute(
      "class",
      "responsive-table__fieldset responsive-table__fieldset-item",
    ),
      fieldset.appendChild(mobileSortRadioFieldset);

    var mobileSortRadioLegend = document.createElement("legend");
    mobileSortRadioLegend.setAttribute("class", "visually-hidden"),
      (mobileSortRadioLegend.innerHTML = DOMPurify.sanitize(
        this.legendMobileSortDirection,
      )),
      mobileSortRadioFieldset.appendChild(mobileSortRadioLegend);

    var mobileSortRadioGroup = document.createElement("div");
    mobileSortRadioFieldset.appendChild(mobileSortRadioGroup);

    var mobileSortRadioAsc = document.createElement("input");
    mobileSortRadioAsc.setAttribute("type", "radio"),
      mobileSortRadioAsc.setAttribute("name", this.mobileSortRadioName),
      (mobileSortRadioAsc.checked = true),
      (mobileSortRadioAsc.value = this.labelAscending.toLowerCase()),
      mobileSortRadioAsc.setAttribute("id", this.$table.id + "-mobileSortAsc");
    mobileSortRadioGroup.appendChild(mobileSortRadioAsc);

    SortRadioButtons.push(mobileSortRadioAsc);

    var mobileSortRadioLabelAsc = document.createElement("label");
    mobileSortRadioLabelAsc.setAttribute(
      "for",
      this.$table.id + "-mobileSortAsc",
    ),
      mobileSortRadioLabelAsc.setAttribute("class", "responsive-table__label"),
      (mobileSortRadioLabelAsc.innerHTML = DOMPurify.sanitize(
        this.labelAscending,
      ));
    mobileSortRadioGroup.appendChild(mobileSortRadioLabelAsc);

    var mobileSortRadioDes = document.createElement("input");
    mobileSortRadioDes.setAttribute("type", "radio"),
      mobileSortRadioDes.setAttribute(
        "name",
        this.$table.id + "-mobileSortDir",
      ),
      (mobileSortRadioDes.value = this.labelDescending.toLowerCase()),
      mobileSortRadioDes.setAttribute("id", this.$table.id + "-mobileSortDes");
    mobileSortRadioGroup.appendChild(mobileSortRadioDes);

    SortRadioButtons.push(mobileSortRadioDes);

    var mobileSortRadioLabelDes = document.createElement("label");
    mobileSortRadioLabelDes.setAttribute(
      "for",
      this.$table.id + "-mobileSortDes",
    ),
      mobileSortRadioLabelDes.setAttribute("class", "responsive-table__label"),
      (mobileSortRadioLabelDes.innerHTML = DOMPurify.sanitize(
        this.labelDescending,
      ));
    mobileSortRadioGroup.appendChild(mobileSortRadioLabelDes);

    // Ascending/descending mobile events
    SortRadioButtons.forEach((radioButton) => {
      radioButton.addEventListener("change", function () {
        const selectedSort = mobileSortDropdown.value;
        const sortDir = document.querySelector(
          `input[name="${outerThis.mobileSortRadioName}"]:checked`,
        ).value;
        if (selectedSort !== "") {
          outerThis.update(selectedSort, sortDir);
        }
      });
    });
  }

  // Click event handler for the sort button
  handleSortButtonClick(colIndex) {
    this.update(colIndex);
  }

  // Update the mobile form's sort filters
  updateMobileSortFilters(sortDirection, colIndex) {
    const mobileSortDropdown = document.getElementById(this.mobileSortId);

    mobileSortDropdown.value = colIndex;

    const mobileSortDirection =
      sortDirection === "ascending"
        ? document.getElementById(`${this.$table.id}-mobileSortAsc`)
        : document.getElementById(`${this.$table.id}-mobileSortDes`);

    mobileSortDirection.checked = true;
  }

  // Update everything (table, controls, etc. -- calls sub-methods)
  update(colIndex, optionalDirection) {
    const colNum = Number(colIndex) + 1;
    const $theColumn = document.getElementById(
      this.tableHeaderIdPrefix + colNum,
    );
    const $sortedTDs = this.$table.querySelectorAll(
      "td:nth-child(" + colNum + "), *[role=cell]:nth-child(" + colNum + ")",
    );

    const sortDirection = optionalDirection
      ? optionalDirection
      : $theColumn.getAttribute("aria-sort") == "ascending"
      ? "descending"
      : "ascending";
    this.clearSorts();

    $theColumn.setAttribute("aria-sort", sortDirection);

    this.updateSort(sortDirection, colIndex);

    // Keep the mobile sort filters synced, if this was called from desktop
    var currentBreakpoint = breakpoint();
    if (this.isAccordion(currentBreakpoint) !== true) {
      this.updateMobileSortFilters(sortDirection, colIndex);
    }

    const screenReaderMessage =
      sortDirection == "descending"
        ? DOMPurify.sanitize(this.messageSortDescendingScreenreader)
        : DOMPurify.sanitize(this.messageSortAscendingScreenreader);
    updateAriaLive(screenReaderMessage);

    // Add CSS class for styling if desired
    for (let i = 0; i < $sortedTDs.length; i++) {
      $sortedTDs[i].classList.add("sorted");
    }
  }

  // Update the table's sorting
  updateSort = function (sortDirection, columnIndex) {
    let $tableBody = this.$table.querySelector("tbody");
    let tableRows = $tableBody.rows;

    Array.from(tableRows)
      .sort((a, b) => {
        if (sortDirection === "descending") {
          return new Intl.Collator(this.locale, {
            numeric: true,
            sensitivity: "base",
          }).compare(
            b.cells[columnIndex].textContent,
            a.cells[columnIndex].textContent,
          );
        } else {
          return new Intl.Collator(this.locale, {
            numeric: true,
            sensitivity: "base",
          }).compare(
            a.cells[columnIndex].textContent,
            b.cells[columnIndex].textContent,
          );
        }
      })
      .forEach((tr) => this.$table.querySelector("tbody").appendChild(tr));
  };

  // Clear the sort markup from the controls and table (though it doesn't restore to original order)
  clearSorts() {
    const $thSort = this.$table.querySelectorAll("*[aria-sort]");
    const $tdSort = this.$table.querySelectorAll(".sorted");
    for (let i = 0; i < $thSort.length; i++) {
      $thSort[i].removeAttribute("aria-sort");
    }
    for (let j = 0; j < $tdSort.length; j++) {
      $tdSort[j].classList.remove("sorted");
    }
  }

  // Create and render the sort buttons in the table headers
  // Returns the sort button DOM collection for later
  createSortButtons() {
    const outerThis = this;
    // Get all the table headers, assign to object variable
    this.$tableHeaders = this.$table.querySelectorAll("th");

    // Loop through them
    this.$tableHeaders.forEach(function ($header, currentIndex) {
      const columnIsSortable = $header.dataset.sortable !== undefined;

      // Create and insert each sort button if sortable
      if (columnIsSortable) {
        // Get header content and put it into a span
        const headerContent = $header.innerHTML.trim();
        const $headerSpan = document.createElement("span");
        $headerSpan.innerHTML = DOMPurify.sanitize(headerContent);

        // Create a new sort button
        const $sortButton = document.createElement("button");
        $sortButton.setAttribute("type", "button");
        $sortButton.classList.add(
          "responsive-table__button--sort",
          "js-table-sort-button",
        );
        $sortButton.dataset.columnIndex = currentIndex;

        // Transfer span with original header text to button
        $sortButton.appendChild($headerSpan);

        // Create sort icons
        const $sortIconAsc = outerThis.createSortIcon("asc");
        const $sortIconDes = outerThis.createSortIcon("des");

        // Append icons to button
        $sortButton.appendChild($sortIconAsc);
        $sortButton.appendChild($sortIconDes);

        // Replace header content with button
        $header.innerHTML = "";
        $header.appendChild($sortButton);

        // Save the important info for building the mobile dropdown
        outerThis.sortableColumnReference.push({
          label: headerContent,
          columnIndex: currentIndex,
        });
      }
    });

    // Get and return the collection of newly created buttons
    const $sortButtons = this.$table.querySelectorAll(".js-table-sort-button");
    return $sortButtons;
  }

  // Create an individual sort icon SVG with #use
  // Returns the icon SVG as a DOM object
  createSortIcon(sortDirection) {
    // SVG namespace
    const svgNamespace = "http://www.w3.org/2000/svg";

    const $sortIcon = document.createElementNS(svgNamespace, "svg");
    $sortIcon.setAttribute("viewBox", "0 0 425 233.7");
    $sortIcon.setAttribute("focusable", "false");
    $sortIcon.ariaHidden = "true";
    $sortIcon.classList.add("sort", sortDirection);

    const $sortIconUse = document.createElementNS(svgNamespace, "use");
    $sortIconUse.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    $sortIconUse.setAttributeNS(
      "http://www.w3.org/1999/xlink",
      "xlink:href",
      "#icon-sort-" + sortDirection,
    );

    $sortIcon.appendChild($sortIconUse);

    return $sortIcon;
  }

  // Create an SVG icon template that other SVGs can #use
  // Inserts it into the body of the page
  createSVGIconTemplate() {
    // SVG namespace
    const svgNamespace = "http://www.w3.org/2000/svg";

    // Create SVG
    const $templateSVG = document.createElementNS(svgNamespace, "svg");
    $templateSVG.setAttribute("version", "1.1");
    $templateSVG.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    $templateSVG.setAttribute("id", "responsive-table__sprites");
    $templateSVG.setAttribute("role", "image");
    $templateSVG.classList.add("responsive-table__icon-template");
    $templateSVG.ariaHidden = "true";

    // Create SVG styles tag
    const $templateStyles = document.createElementNS(svgNamespace, "style");
    const styleClassL = document.createTextNode(
      ".l { fill: none; stroke-width: 30; stroke-miterlimit: 10; }",
    );
    const styleClassT = document.createTextNode(".t { stroke: none; }");
    $templateStyles.appendChild(styleClassL);
    $templateStyles.appendChild(styleClassT);

    // Create SVG defs tag
    const $templateDefs = document.createElementNS(svgNamespace, "defs");

    // Create Sort icon group
    const $templateIconSort = document.createElementNS(svgNamespace, "g");
    $templateIconSort.setAttribute("id", "icon-sort");
    $templateIconSort.setAttribute("aria-labeledby", "title-sort");
    $templateIconSort.setAttribute("aria-describedby", "desc-sort");
    $templateIconSort.setAttribute("role", "image");

    const $iconSortTitle = document.createElementNS(svgNamespace, "title");
    const iconSortTitleText = document.createTextNode(
      DOMPurify.sanitize(this.labelSortScreenreader),
    );
    $iconSortTitle.appendChild(iconSortTitleText);
    $iconSortTitle.setAttribute("id", "title-sort");

    const $iconSortDesc = document.createElementNS(svgNamespace, "desc");
    $iconSortDesc.setAttribute("id", "desc-sort");

    const $iconSortPath1 = document.createElementNS(svgNamespace, "path");
    $iconSortPath1.classList.add("t");
    $iconSortPath1.setAttribute("d", "M20.4 233.7L212.5 41.6l192.1 192.1z");

    const $iconSortPath2 = document.createElementNS(svgNamespace, "path");
    $iconSortPath2.classList.add("l");
    $iconSortPath2.setAttribute("d", "M414.4 223.1L212.5 21.2 10.6 223.1");

    const $iconSortPath3 = document.createElementNS(svgNamespace, "path");
    $iconSortPath3.classList.add("t");
    $iconSortPath3.setAttribute("d", "M404.6 306L212.5 498.1 20.4 306z");

    const $iconSortPath4 = document.createElementNS(svgNamespace, "path");
    $iconSortPath4.classList.add("l");
    $iconSortPath4.setAttribute("d", "M10.6 316.6l201.9 201.9 201.9-201.9");

    $templateIconSort.appendChild($iconSortTitle);
    $templateIconSort.appendChild($iconSortDesc);
    $templateIconSort.appendChild($iconSortPath1);
    $templateIconSort.appendChild($iconSortPath2);
    $templateIconSort.appendChild($iconSortPath3);
    $templateIconSort.appendChild($iconSortPath4);

    // Create Sort Ascending icon group
    const $templateIconSortAsc = document.createElementNS(svgNamespace, "g");

    $templateIconSortAsc.setAttribute("id", "icon-sort-asc");
    $templateIconSortAsc.setAttribute("aria-labeledby", "title-sort-asc");
    $templateIconSortAsc.setAttribute("aria-describedby", "desc-sort-asc");
    $templateIconSortAsc.setAttribute("role", "image");

    const $iconSortAscTitle = document.createElementNS(svgNamespace, "title");
    const iconSortAscTitleText = document.createTextNode(
      DOMPurify.sanitize(this.labelSortAscScreenreader),
    );
    $iconSortAscTitle.appendChild(iconSortAscTitleText);
    $iconSortAscTitle.setAttribute("id", "title-sort-asc");

    const $iconSortAscDesc = document.createElementNS(svgNamespace, "desc");
    $iconSortAscDesc.setAttribute("id", "desc-sort-asc");

    const $iconSortAscPath1 = document.createElementNS(svgNamespace, "path");
    $iconSortAscPath1.classList.add("t");
    $iconSortAscPath1.setAttribute("d", "M20.4 233.7L212.5 41.6l192.1 192.1z");

    const $iconSortAscPath2 = document.createElementNS(svgNamespace, "path");
    $iconSortAscPath2.classList.add("l");
    $iconSortAscPath2.setAttribute("d", "M414.4 223.1L212.5 21.2 10.6 223.1");

    $templateIconSortAsc.appendChild($iconSortAscTitle);
    $templateIconSortAsc.appendChild($iconSortAscDesc);
    $templateIconSortAsc.appendChild($iconSortAscPath1);
    $templateIconSortAsc.appendChild($iconSortAscPath2);

    // Create Sort Descending icon group
    const $templateIconSortDes = document.createElementNS(svgNamespace, "g");

    $templateIconSortDes.setAttribute("id", "icon-sort-des");
    $templateIconSortDes.setAttribute("aria-labeledby", "title-sort-des");
    $templateIconSortDes.setAttribute("aria-describedby", "desc-sort-des");
    $templateIconSortDes.setAttribute("role", "image");

    const $iconSortDesTitle = document.createElementNS(svgNamespace, "title");
    const iconSortDesTitleText = document.createTextNode(
      DOMPurify.sanitize(this.labelSortDesScreenreader),
    );
    $iconSortDesTitle.appendChild(iconSortDesTitleText);
    $iconSortDesTitle.setAttribute("id", "title-sort-des");

    const $iconSortDesDesc = document.createElementNS(svgNamespace, "desc");
    $iconSortDesDesc.setAttribute("id", "desc-sort-des");

    const $iconSortDesPath1 = document.createElementNS(svgNamespace, "path");
    $iconSortDesPath1.classList.add("t");
    $iconSortDesPath1.setAttribute("d", "M404.6 0L212.5 192.1 20.4 0z");

    const $iconSortDesPath2 = document.createElementNS(svgNamespace, "path");
    $iconSortDesPath2.classList.add("l");
    $iconSortDesPath2.setAttribute("d", "M10.6 10.6l201.9 201.9L414.4 10.6");

    $templateIconSortDes.appendChild($iconSortDesTitle);
    $templateIconSortDes.appendChild($iconSortDesDesc);
    $templateIconSortDes.appendChild($iconSortDesPath1);
    $templateIconSortDes.appendChild($iconSortDesPath2);

    // Append the icons to the SVG defs
    $templateDefs.appendChild($templateIconSort);
    $templateDefs.appendChild($templateIconSortAsc);
    $templateDefs.appendChild($templateIconSortDes);

    // Append the styles and defs to the SVG
    $templateSVG.appendChild($templateStyles);
    $templateSVG.appendChild($templateDefs);

    // Insert SVG into the DOM
    document.body.appendChild($templateSVG);

    return $templateSVG;
  }
}
