import $ from "jquery";

export class PolicyDirectory {
    constructor($el) {
        this.$el = $el;

        this.$policyAnchor = $(this.$el).find('.js-policy-anchor');

        this.$policyGroup = $(this.$el).find('.js-policy-groups')[0];
        this.$accordions = $(this.$policyGroup).find('.policy-accordion');
        this.$sortPoliciesBy = $(this.$policyGroup).attr('data-sort-policies-by');
        this.sortByPolicyName = false;

        this.$policyFilter = $(this.$el).find('.js-policy-filter');
        this.groups = $(this.$el).find('.policy-accordion-group');
        this.items = $(this.$el).find('.policy-accordion');
        this.$searchButton = this.$policyFilter.find('#policyForm');
        this.$noResultsMessage = this.$policyFilter.find('#noResultsMessage'); // Reference for the message


        this.bindEvents();
    }

    bindEvents() {
        document.addEventListener("DOMContentLoaded", () => {
            this.createGroups();
            this.createSectionAnchors();

            this.groups = $(this.$el).find('.policy-accordion-group');
            this.items = $(this.$el).find('.policy-accordion');
            this.$searchButton = this.$policyFilter.find('#policyForm');
            this.$noResultsMessage = this.$policyFilter.find('#noResultsMessage'); // Reference for the message
    

            this.$policyFilter.on('input', '#searchInput', (event) => {
                this.filterItems(event.target.value);
            });


            this.$searchButton.on('submit', (event) => {
                event.preventDefault(); // Prevent the form from submitting
                const input = this.$el.find('#searchInput').val();
                this.filterItems(input);
            });
        });
    }

    createSectionAnchors() {
        const policyDirectory = this;
        const anchors = this.$policyAnchor;

        const headers = $(policyDirectory.$el).find($('h3.policy-accordion-group__title'));

        const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split(''); // All letters of the alphabet
        const items = []; // To store headers
        let hasLongText = false; // To track if any header has text length > 1

        // Collect headers and track used letters
        headers.each((index, header) => {
            const text = header.textContent.trim();
            const firstLetter = text.charAt(0).toLowerCase();

            // Create a valid id for the section
            const id = text.toLowerCase().replace(/\s+/g, '-');
            header.id = id + "-policyGroup"; // Set the id for the header

            // Check if the first character is a letter, and store the header
            if (/[a-zA-Z]/.test(firstLetter)) {
                items.push({ type: 'header', text: text, id: id, letter: firstLetter });

                // Check if the text length is greater than 1
                if (text.length > 1) {
                    hasLongText = true; // Mark that we have at least one long text header
                }
            }
        });

        // Create a div for the anchor links
        const anchorUl = document.createElement('ul');
        anchorUl.classList.add('anchor-links');

        // Add conditional class based on text length
        if (hasLongText) {
            anchorUl.classList.add('sponsoring-unit');
        } else {
            anchorUl.classList.add('a-z');

            // Only add missing alphabet letters if the .a-z class is applied
            alphabet.forEach(letter => {
                const isUsed = items.some(item => item.letter === letter);
                if (!isUsed) {
                    items.push({ type: 'letter', text: letter.toUpperCase(), letter: letter });
                }
            });
        }

        // Sort items alphabetically by letter
        items.sort((a, b) => a.letter.localeCompare(b.letter));

        // Generate the sorted list
        items.forEach(item => {
            const anchorLi = document.createElement('li');
            anchorLi.classList.add('anchor-item');

            if (item.type === 'header') {
                // Create an anchor link for headers
                const anchorLink = document.createElement('a');
                anchorLink.href = `#${item.id + "-policyGroup"}`;
                anchorLink.textContent = item.text;
                anchorLi.appendChild(anchorLink);
                anchorLink.ariaDescription = ("Policies beginning with " + item.text);
            } else if (item.type === 'letter') {
                // Add the missing letter as non-clickable text (only for .a-z)
                anchorLi.textContent = item.text;
                anchorLi.ariaDescription = "inactive";
            }

            // Append the li to the ul
            anchorUl.appendChild(anchorLi);
        });

        // Insert the anchorUl at the beginning of the container element
        this.$policyAnchor.prepend(anchorUl);
    }

    createGroups() {
        const policyDirectory = this;
        const group = this.$policyGroup;
        var headerName = '';
        var accordionGroup = [];

        // Sort by Sponsoring Unit else Alphabetical
        if (policyDirectory.$sortPoliciesBy == 'sponsoringUnit') {
          for (let i = 0; i < this.$accordions.length; i++) {
            const $accordion = $(this.$accordions[i]);
            let headerNameFixed = '';
            headerNameFixed = $accordion.attr("data-policy-name");
            headerNameFixed = headerNameFixed.toLowerCase();

            $($accordion[0]).find('.policy-accordion__policy-title')[0].innerText = headerNameFixed;
            $accordion[0].setAttribute("data-policy-name", headerNameFixed);
            

            if (accordionGroup.length == 0) {
              headerName = $accordion.attr("data-policy-sponsoring-unit");
              accordionGroup.push($accordion[0]);
            } else if (headerName == $accordion.attr("data-policy-sponsoring-unit")) {
              accordionGroup.push($accordion[0]);
            } else {
              // Create Group
              var sponsoringUnit = $(accordionGroup[0]).attr("data-policy-sponsoring-unit");
              makeGroupFromAccordions(accordionGroup, group, sponsoringUnit, false, policyDirectory.$sortPoliciesBy);
              headerName = $accordion.attr("data-policy-sponsoring-unit");
              accordionGroup = [];
              accordionGroup.push($accordion[0]);
            }
          }
          if (accordionGroup.length > 0) {
            // Create Group from leftovers
            var sponsoringUnit = $(accordionGroup[0]).attr("data-policy-sponsoring-unit");

            makeGroupFromAccordions(accordionGroup, group, sponsoringUnit,false, policyDirectory.$sortPoliciesBy);
          }
        } else if (policyDirectory.$sortPoliciesBy == 'alphabetical') {
          for (let i = 0; i < this.$accordions.length; i++) {
            const $accordion = $(this.$accordions[i]);
            let headerNameFixed = '';
            headerNameFixed = $accordion.attr("data-policy-name");
            headerNameFixed = headerNameFixed.toLowerCase();



            $($accordion[0]).find('.policy-accordion__policy-title')[0].innerText = headerNameFixed;

            if (accordionGroup.length == 0) {
              headerName = headerNameFixed.charAt(0);
              $accordion[0].setAttribute("data-policy-name", headerNameFixed);
              accordionGroup.push($accordion[0]);
            } else if (headerName.charAt(0) == headerNameFixed.charAt(0)) {
              $accordion[0].setAttribute("data-policy-name", headerNameFixed);
              accordionGroup.push($accordion[0]);
            } else {
              // Create Group
              makeGroupFromAccordions(accordionGroup, group, false, headerName.charAt(0).toUpperCase(), policyDirectory.$sortPoliciesBy);

              headerName = $accordion.attr("data-policy-name");

              // if(headerName.startsWith("DU - ")) {
              //   headerName = headerName.substring(5);
              // }

              accordionGroup = [];
              $accordion[0].setAttribute("data-policy-name", headerNameFixed);
              accordionGroup.push($accordion[0]);
            }
          }


          if (accordionGroup.length > 0) {
            // Create Group from leftovers
            //var alphabetical = $(accordionGroup[0]).attr("data-policy-letter");

            makeGroupFromAccordions(accordionGroup, group, false, headerName.charAt(0).toUpperCase(), policyDirectory.$sortPoliciesBy);
          }
        }
        
        function makeGroupFromAccordions(accordions, parent, sponsoringUnit, alphabetical, sortby) {
            const anchorGroup = document.createElement('div');
            anchorGroup.classList.add('policy-accordion-group');
            if(alphabetical == false) {
              anchorGroup.setAttribute("data-sponsoring-unit", sponsoringUnit);
            } else {
              anchorGroup.setAttribute("data-policy-letter", alphabetical);
            }
            anchorGroup.classList.add('accordion-group');

            const policyGroupAccords = document.createElement('div');
            policyGroupAccords.classList.add('policy-accordion-group__accordions');
            policyGroupAccords.classList.add('accordion-group__accordions');

            const policyGroupHeader = document.createElement('div');
            policyGroupHeader.classList.add('policy-accordion-group__header');

            const policyGroupHeaderTitle = document.createElement('h3');
            policyGroupHeaderTitle.classList.add('policy-accordion-group__title');
            policyGroupHeaderTitle.classList.add('accordion-group__title');

            if(sortby == 'sponsoringUnit') {
              policyGroupHeaderTitle.textContent = $(accordions[0]).attr('data-policy-sponsoring-unit');
              policyGroupHeaderTitle.innerHTML = policyGroupHeaderTitle.textContent;
            } else {
              policyGroupHeaderTitle.textContent = $(accordions[0]).attr('data-policy-name').charAt(0).toUpperCase();
              policyGroupHeaderTitle.innerHTML = policyGroupHeaderTitle.textContent;
            }

            const policyGroupHeaderRow = document.createElement('div');
            policyGroupHeaderRow.classList.add('policy-accordion-group__header-row');

            const policyNameSpan = document.createElement('span');
            policyNameSpan.classList.add('policy-accordion-group__column-one');

            const policyCategorySpan = document.createElement('span');
            policyCategorySpan.classList.add('policy-accordion-group__column-two');

            policyGroupHeaderRow.appendChild(policyNameSpan);
            policyGroupHeaderRow.appendChild(policyCategorySpan);

            policyGroupHeader.appendChild(policyGroupHeaderTitle);
            policyGroupHeader.appendChild(policyGroupHeaderRow);
            policyGroupAccords.appendChild(policyGroupHeader);

            parent.appendChild(anchorGroup);

            accordions.forEach(accordion => {
              policyGroupAccords.appendChild(accordion);
            });
            anchorGroup.appendChild(policyGroupAccords);
            
        }
    }

    filterItems(filter) {
        const policyDirectory = this;
        const filterLower = filter.toLowerCase();
        let noResults = true;
        let groups = policyDirectory.groups.get();

        groups.forEach(group => {
            let groupText = this.extractText(group);
            let groupMatches = groupText.toLowerCase().includes(filterLower);
            
            const items = group.querySelectorAll('.policy-accordion');
            let anyItemMatches = false;

            items.forEach(item => {
                let itemText = this.extractText(item);
                let itemMatches = itemText.toLowerCase().includes(filterLower);

                if (itemMatches) {
                    item.classList.remove('hidden');
                    anyItemMatches = true;
                    noResults = false;
                } else {
                    item.classList.add('hidden');
                }
            });

            if (anyItemMatches || groupMatches) {
                group.classList.remove('hidden');
                noResults = false;
            } else {
                group.classList.add('hidden');
            }
        });

        var policyDirectorytoJS = policyDirectory.$el.get()[0];
        var anchors = policyDirectorytoJS.querySelector("#policy-anchors .anchor-links").getElementsByTagName("li");
        var policyGroups = policyDirectorytoJS.querySelectorAll('.policy-accordion-group:not(.hidden)');
        var sortby = policyDirectorytoJS.querySelector("#policyAccordions").attributes['data-sort-policies-by'].value;

        for (var i = 0; i < anchors.length; i++) {
            var anchor = anchors[i];
            var anchorText = anchor.innerText;
            var anchorTextLower = anchorText.toLowerCase();
            var anchorMatches = false;
            for (var j = 0; j < policyGroups.length; j++) {
                var policyGroup = policyGroups[j];
                var policyGroupText = '';
                if(sortby == 'alphabetical') {
                    policyGroupText = $(policyGroup).attr("data-policy-letter");
                } else {
                    policyGroupText = $(policyGroup).attr("data-sponsoring-unit");
                }
                var policyGroupTextLower = policyGroupText.toLowerCase();
                if (policyGroupTextLower.includes(anchorTextLower)) {
                    anchorMatches = true;
                    break;
                }
            }
            if (anchorMatches) {
                anchor.classList.remove('hidden');
            } else {
                anchor.classList.add('hidden');
            }
        }

        // Show or hide the "No results" message
        if (noResults) {
            this.$noResultsMessage.removeClass('hidden');
            //this.$policyAnchors.addClass('hidden'); // Hide .policy-anchors when no results are found
        } else {
            this.$noResultsMessage.addClass('hidden');
            //this.$policyAnchors.removeClass('hidden'); // Show .policy-anchors if there are results
        }
    }

    extractText(element) {
        let text = [];
        element.childNodes.forEach(function check(child) {
            if (child.nodeType === Node.TEXT_NODE) {
                text.push(child.nodeValue.trim());
            }
            child.childNodes.forEach(check);
        });
        return text.join(' ');
    }
}