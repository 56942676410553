import { connect } from "react-redux";
import RawHtml from "../../components/RawHtml.jsx";

const mapStateToProps = (state, ownProps) => {
  return {
    content: state.sidebar
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RawHtml);
