import PropTypes from "prop-types";
import React from "react";

function createMarkup(htmlstring) { return {__html: htmlstring}; }

/*
 * A component strictly for use outputting HTML directly to the page.
 * Only use with trusted sources.  Does not filter HTML in any way.
 */
export default class RawHtml extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { content } = this.props;

    return (
      <div dangerouslySetInnerHTML={ createMarkup(content) }></div>
    );
  }
}

const { string } = PropTypes;
RawHtml.propTypes = {
  content: string
};
