/** @module search/factory */

import R from "ramda";
import * as RA from "ramda-adjunct";
import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import rootReducer from "./redux/rootReducer";
import configureStore from "./redux/configureStore";
import createRoutingMiddleware from "./redux/createRoutingMiddleware";

// Starting point for the object create by calling factory functions
const baseApp = {
  searchId: null,
  mountNodes: {},
  initialState: null,
  reduxMiddleware: {},
  externalConfig: null,
  initialDispatch: null
};

// COMPOSABLE FACTORY FUNCTIONS
// These functions can be composed us R.pipe or R.compose to
// create a single factory function that can be used with createApp()

/**
 * Assign a search id
 * @function
 * @param {string} id
 * @returns {function(Object): Object} A function that modifies a search app object
 */
export const withSearchId = id => R.assoc("searchId", id);

// Specify a CSS selector where a react node should be mounted

/**
 * Add a binding to mount a React node to a CSS selector
 * @function
 * @param {string} selector
 * @param {ReactNode} reactNode
 * @returns {function(Object): Object} A function that modifies a search app object
 */
export const withNode = (selector, reactNode) =>
  R.assocPath(["mountNodes", selector], reactNode);

/**
 * Remove an already registered node by it's CSS selector
 * @function
 * @param {string} selector
 * @returns {function(Object): Object} A function that modifies a search app object
 */
export const withoutNode = selector => R.dissocPath(["mountNodes"], selector);

/**
 * Applies thunk redux middleware, taking two functions to be applied as extra arguments
 * @function
 * @param {function(string, Object): Promise} fetchSearchResults Invokes the fetch/ajax for fetching results from server
 * @param {function(Object, Object): Object} mapResponseToState Taking the raw response and current state, returns a new state
 * @returns {function(Object): Object} A function that modifies a search app object
 */
export const withServiceApi = (fetchSearchResults, mapResponseToState) =>
  R.assocPath(
    ["reduxMiddleware", "thunk"],
    thunk.withExtraArgument({ fetchSearchResults, mapResponseToState })
  );

/**
 * Applies query string routing middleware, taking two functions as arguments
 * @function
 * @param {function(Object): Object} mapStateToUrl Given current state, return an object mapping query param keys to values
 * @param {function(Object, Object): Object} handleUrlChange Given new query string params in the url, provide a new state object
 * @returns {function(Object): Object} A function that modifies a search app object
 */
export const withQueryStringRouting = (mapStateToUrl, handleUrlChange) =>
  R.assocPath(
    ["reduxMiddleware", "routing"],
    createRoutingMiddleware(mapStateToUrl, handleUrlChange)
  );

/**
 * Remove query string routing middleware
 * @function
 * @returns {function(Object): Object} A function that modifies a search app object
 */
export const withoutQueryStringRouting = () =>
  R.dissocPath(["reduxMiddleware", "routing"]);

/**
 * Assign an initial state object to the redux store. Optionally,
 * provide a function to transform the initial state, which is invoked
 * before the store is created. A good use of the transform function is
 * to dynamically alter the initial state at runtime, as a result of
 * outside configuration or computation. Non-dynamic initial state options should simply be
 * statically coded in the starting initial state object.
 * @function
 * @param {Object} initialState Object describing the initial state of the redux store
 * @param {function} [transformer] Optional function to apply tranformations to initialState before store is created
 * @returns {function(Object): Object} A function that modifies a search app object
 */
export const withInitialState = (initialState, transformer = R.identity) =>
  R.assoc("initialState", transformer(initialState));

/**
 * Specify a redux action creator to be dispatched immediately after store creation
 * @function
 * @param {function} actionFn The action creator function
 * @returns {function(Object): Object} A function that modifies a search app object
 */
export const withInitialDispatch = actionFn =>
  R.assoc("initialDispatch", actionFn);

// FACTORY LOGIC
// Internal bootstrap logic for the app

// Ensure that all mountNodes share the same searchId
const nodesMatchSearchId = (searchId, nodeSelectors) => {
  const ids = R.map(
    R.pipe(
      s => document.querySelector(s),
      e => e.getAttribute("data-search-id")
    )
  );

  return RA.allEqual(ids);
};

// Render the specified React components to their bound elements.
// Each component will be provided a redux store.
const mountReactNodes = (store, mountNodes) => {
  return R.forEachObjIndexed((node, selector) => {
    const elem = document.querySelector(selector);
    if (elem) {
      ReactDOM.render(<Provider store={store}>{node}</Provider>, elem);
    }
  }, mountNodes);
};

// Trigger a no-op popstate event to force handleUrlChange() to be called
// when the app is done bootstrapping.
const forceUrlChangeEvent = () => {
  const popStateEvent = new PopStateEvent("popstate", { state: History.state });
  window.dispatchEvent(popStateEvent);
};

/**
 * Initialize a search app according to the factory provided. Sets up redux store and mounts React nodes.
 * @function
 * @param {function} appFactory The factory function
 * @throws {Error}
 */
export const createApp = appFactory => {
  const app = appFactory(baseApp);
  return () => {
    // if we have an invalid or non-matching id, raise an error
    if (!app.searchId || !nodesMatchSearchId(app.searchId, app.mountNodes)) {
      throw new Error(
        "Could not initialize Thread search app. searchId was either not present, or provided mount nodes did not share the same searchId"
      );
    }

    const store = configureStore(
      rootReducer,
      app.initialState,
      R.values(app.reduxMiddleware)
    );

    // if we are using routing, we want to run handleUrlChange on load
    if (app.reduxMiddleware.routing) {
      forceUrlChangeEvent();
    }

    if (app.initialDispatch && typeof app.initialDispatch === "function") {
      store.dispatch(app.initialDispatch());
    }

    if (!R.map(n => document.querySelector(n), R.keys(app.mountNodes)).length) {
      throw new Error(
        "Could not initialize Thread search app. No mount nodes were provided, or mount node selectors could not resolve to elements in document."
      );
    }
    mountReactNodes(store, app.mountNodes);
  };
};
