// Setting up some common values and methods that interactive tables can share
import * as DOMPurify from "dompurify";
import { breakpoint } from "../../breakpoint";
import { getAriaLiveNode } from "../../a11y/ariaLiveUtils";
let throttler;
export class InteractiveTable {
  constructor($el) {
    // Consolidating instructions, labels, and messages, in case we need to import them from a Sitecore dictionary in future
    this.labelMobileToggle = "Refine Table Results"; // No HTML markup allowed

    this.$table = $el; // Vanilla DOM element
    this.$table.id = $el.id
      ? $el.id
      : "interactive-table_" +
        Date.now() +
        "#" +
        Math.ceil(1e5 * Math.random());
    this.$ariaLivePoliteNode = getAriaLiveNode("polite");
    this.$ariaLiveAssertiveNode = getAriaLiveNode("assertive");
    this.debounceDelay = 300;
    this.$form = null;
    this.formOuterWrapperId = `${this.$table.id}-formOuterWrapper`;
    this.accordionActiveIn = ["small-tablet", "small", "xsmall", "xxsmall"]; // Breakpoints to show the mobile accordion

    this.formOuterWrapper = this.createFormWrapper();
    this.formDivider = null;
  }

  // Create the form wrapper common to all interactive tables
  // Returns the DOM object
  createFormWrapper() {
    var outerThis = this;

    // Test to see if form wrapper already exists
    const $existingFormWrapper = document.getElementById(
      this.formOuterWrapperId,
    );
    if ($existingFormWrapper) {
      this.$form = $existingFormWrapper.getElementsByTagName("form")[0];
      return $existingFormWrapper;
    } else {
      // create and insert the form wrapper
      var formOuterWrapper = document.createElement("div");
      formOuterWrapper.id = this.formOuterWrapperId;
      formOuterWrapper.setAttribute(
        "class",
        "responsive-table__form-wrapper js-filterable-table-filters",
      );

      var formToggleWrapper = document.createElement("div");
      formToggleWrapper.setAttribute(
        "class",
        "responsive-table__filters is-open",
      ),
        formOuterWrapper.appendChild(formToggleWrapper);

      var formToggleHeaderDesktop = document.createElement("h3");
      (formToggleHeaderDesktop.innerText = DOMPurify.sanitize(
        this.labelMobileToggle,
      )),
        formToggleHeaderDesktop.setAttribute(
          "class",
          "responsive-table__filters-header--desktop",
        ),
        formToggleWrapper.appendChild(formToggleHeaderDesktop);

      var formToggleHeaderMobile = document.createElement("h3");
      formToggleHeaderMobile.setAttribute(
        "class",
        "responsive-table__filters-header--mobile",
      ),
        formToggleWrapper.appendChild(formToggleHeaderMobile);

      var formToggleHeaderButton = document.createElement("button");
      (formToggleHeaderButton.innerText = DOMPurify.sanitize(
        this.labelMobileToggle,
      )),
        formToggleHeaderButton.setAttribute("type", "button"),
        formToggleHeaderButton.setAttribute("aria-expanded", "false"),
        formToggleHeaderButton.setAttribute(
          "aria-controls",
          this.$table.id + "-form",
        ),
        formToggleHeaderButton.setAttribute(
          "id",
          this.$table.id + "-formheader",
        ),
        formToggleHeaderMobile.appendChild(formToggleHeaderButton);
      formToggleHeaderButton.addEventListener("click", function () {
        outerThis.mobileToggleHandler(this, formToggleWrapper);
      });

      var formToggle = document.createElement("div");
      formToggle.setAttribute("id", this.$table.id + "-form"),
        formToggle.setAttribute("class", "responsive-table__form-toggle"),
        formToggleWrapper.appendChild(formToggle);

      this.$form = document.createElement("form");
      this.$form.setAttribute("class", "responsive-table__form"),
        this.$form.setAttribute("action", "#" + this.$table.id),
        this.$form.setAttribute("method", "get"),
        formToggle.appendChild(this.$form);

      // Set a resize event to check for mobile (accordion)
      window.addEventListener("resize", () => {
        if (throttler) {
          window.clearTimeout(throttler);
        }
        throttler = setTimeout(() => {
          this.manageBreakpointBehaviors(
            formToggleWrapper,
            formToggleHeaderButton,
            formToggle,
          );
        }, 400);
      });

      // Initialize the accordion if needed
      if (this.isAccordion(breakpoint())) {
        this.accordionInitialize(
          formToggleWrapper,
          formToggleHeaderButton,
          formToggle,
        );
      }

      this.formDivider = document.createElement("hr");
      this.formDivider.setAttribute("class", "responsive-table__divider");

      var tableParent = this.$table.parentNode,
        thisTable = this.$table;
      tableParent.matches(".responsive-table__scroller") &&
        ((thisTable = tableParent), (tableParent = tableParent.parentNode)),
        tableParent.insertBefore(formOuterWrapper, thisTable),
        tableParent.insertBefore(this.formDivider, thisTable);

      return formOuterWrapper;
    }
  }

  // Method to handle toggling the mobile accordion menu
  mobileToggleHandler = function (buttonObj, wrapperObj) {
    // Swap aria-expanded values on button
    buttonObj.getAttribute("aria-expanded") === "true"
      ? buttonObj.setAttribute("aria-expanded", false)
      : buttonObj.setAttribute("aria-expanded", true);
    // Toggle .is-open class on wrapper
    wrapperObj.classList.contains("is-open") === true
      ? wrapperObj.classList.remove("is-open")
      : wrapperObj.classList.add("is-open");
  };

  // Method to check breakpoint and init/destroy accordions as needed
  manageBreakpointBehaviors = function (
    formToggleWrapper,
    formToggleHeaderButton,
    formToggle,
  ) {
    // First, check if we need to manage breakpoint behaviors (has the breakpoint actually changed)
    var newBreakpoint = breakpoint();
    if (this.currentBreakpoint !== newBreakpoint) {
      if (
        this.isAccordion(this.currentBreakpoint) === false &&
        this.isAccordion(newBreakpoint) === true
      ) {
        this.accordionInitialize(
          formToggleWrapper,
          formToggleHeaderButton,
          formToggle,
        );
      } else if (
        this.isAccordion(this.currentBreakpoint) === true &&
        this.isAccordion(newBreakpoint) === false
      ) {
        this.accordionDestroy(formToggleWrapper, formToggle);
      }
    }

    // Update the current breakpoint
    this.currentBreakpoint = breakpoint();
  };

  // Simple method to check if there should be an active accordion
  // Returns a boolean
  isAccordion = function (breakpointToCheck) {
    if (this.accordionActiveIn.indexOf(breakpointToCheck) > -1) {
      return true;
    } else {
      return false;
    }
  };

  // Initialize accordions
  accordionInitialize = function (
    formToggleWrapper,
    formToggleHeaderButton,
    formToggle,
  ) {
    // Remove .is-open class from wrapper
    formToggleWrapper.classList.remove("is-open");

    // Add .in-toggle-mode class to the wrapper
    formToggleWrapper.classList.add("in-toggle-mode");

    // Set button's aria-expanded = false
    formToggleHeaderButton.setAttribute("aria-expanded", "false");

    // Add aria-labelledby attribute to formtoggle
    formToggle.setAttribute("aria-labelledby", this.$table.id + "-formheader");

    // Add role=region to formtoggle
    formToggle.setAttribute("role", "region");
  };

  // Destroy accordions
  accordionDestroy = function (formToggleWrapper, formToggle) {
    // Add .is-open class to wrapper
    formToggleWrapper.classList.add("is-open");

    // Remove .in-toggle-mode class from the wrapper
    formToggleWrapper.classList.remove("in-toggle-mode");

    // Remove aria-labelledby attribute from formtoggle
    formToggle.removeAttribute("aria-labelledby");

    // Remove role=region from formtoggle
    formToggle.removeAttribute("role");
  };

  // Clear all table inputs and update them
  clearChangeHandler() {
    const $tableWrapper = this.$table.closest(".responsive-table");
    var $allInputs = $tableWrapper.querySelectorAll(".responsive-table__input");
    $allInputs.forEach(function (input) {
      input.value = "";
      if (input.nodeName === "SELECT") {
        input.dispatchEvent(new Event("change", { bubbles: true }));
      } else {
        // assuming text or search input
        input.dispatchEvent(new Event("input", { bubbles: true }));
      }
    });
  }
}
