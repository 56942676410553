/** @module search/initialState **/
import R from "ramda";
import RA from "ramda-adjunct";
import {
  RESULTS_DISPLAY_APPEND,
  RESULTS_DISPLAY_REFRESH,
} from "./resultsDisplay";

/**
 * The starting state of the redux store
 */
export const initialState = {
  query: {
    q: null,
    sortBy: "relevance",
    sortOrder: "asc",
    page: 1,
    perPage: 10,
    pageId: "00000000-0000-0000-0000-000000000000",
    loadAllPages: true,
    startDate: null,
    endDate: null,
  },
  url: "http://localhost:4000",
  errorMsg: null,
  loading: true,
  pagination: false,
  totalResults: 0,
  results: [],
  resultsDisplayStrategy: RESULTS_DISPLAY_APPEND,
  sorters: [],
  dateConstraints: {
    start: false,
    end: false,
  },
  dateQuickPicks: [],
  dictionary: {},
  facets: [],
  numOpenGroups: null,
  preSelectedFacets: {},
  isFirstLoad: true,
  mobileFacetsShown: false,
};

// given a set of external config objects (e.g., window.threadSearchConfigs)
// find the appropriate config for the searchId and deep merge it into
// the initial state
export const applyExternalConfig = (configArr, searchId) => {
  if (!configArr) {
    console.warn(
      "Search: applyExternalConfig for initialState received a null or undefined config object. No changes applied.",
    );
    return R.curry((obj1, obj2) => R.identity(obj2))({});
  }
  const config = R.find(R.propEq("searchId", searchId), configArr);

  return R.mergeDeepLeft(config);
};

// Change the key names of the initial state params,
// keyDef is object like: { oldName: "newName" }
export const applyRenameKeys = (keyDef) => RA.renameKeys(keydef);

// Given an array of property keys, remove them from the initial state
export const applyOmitKeys = (keys) => R.omit(keys);

// Based on the current value of "pagination", toggle the results display
// strategy ("append" (for no pagination) or "refresh" (for pagination))
export const applyResultDisplayStrategy = R.ifElse(
  R.propEq("pagination", true),
  R.assoc("resultsDisplayStrategy", RESULTS_DISPLAY_REFRESH),
  R.assoc("resultsDisplayStrategy", RESULTS_DISPLAY_APPEND),
);
