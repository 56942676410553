import { connect } from "react-redux";
import AdmissionRepResultList from "../../components/AdmissionReps/AdmissionRepResultList.jsx";
import {
  loadNextPage,
  loadNewSort,
  loadNewPage,
  loadFacetChange,
  showMobileFacets,
} from "../../redux/search/search.actions";
import { allSelectedFacets } from "../../redux/facets";

const mapStateToProps = (state) => {
  return {
    results: state.results,
    resultsDisplayStrategy: state.resultsDisplayStrategy,
    selectedFacets: allSelectedFacets(state.facets),
    totalResults: state.totalResults,
    loading: state.loading,
    isFirstLoad: state.isFirstLoad,
    sorters: state.sorters,
    query: state.query,
    pagination: state.pagination,
    dictionary: state.dictionary,
    searchId: state.searchId,
    listTitle: state.listTitle,
    defaultData: state.defaultData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextPage: () => {
      dispatch(loadNextPage());
    },
    changeSort: (sorters) => {
      dispatch(loadNewSort(sorters));
    },
    gotoPage: (pageNum) => {
      dispatch(loadNewPage(pageNum));
    },
    dismissFacet: (facetId) => {
      dispatch(loadFacetChange(facetId, false));
    },
    showMobileFacets: () => {
      dispatch(showMobileFacets());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdmissionRepResultList);
