import R from "ramda";
import {
  RESULTS_DISPLAY_APPEND,
  RESULTS_DISPLAY_REFRESH,
} from "../resultsDisplay";
import { selectedFacetIdsByGroup } from "../facets";

// Action Names
export const LOAD_RESULTS_START = "SEARCH/LOAD_RESULTS_START";
export const LOAD_RESULTS_FAIL = "SEARCH/LOAD_RESULTS_FAIL";
export const LOAD_RESULTS_SUCCESS = "SEARCH/LOAD_RESULTS_SUCCESS";
export const MERGE_QUERY_PARAMS = "SEARCH/MERGE_QUERY_PARAMS";
export const KEYWORDS_CHANGED = "SEARCH/KEYWORDS_CHANGED";
export const INCREMENT_PAGE = "SEARCH/INCREMENT_PAGE";
export const CHANGE_PAGE = "SEARCH/CHANGE_PAGE";
export const SORT_CHANGED = "SEARCH/SORT_CHANGED";
export const FACET_CHANGED = "SEARCH/FACET_CHANGED";
export const DEPENDENT_FACETS_CHANGED = "SEARCH/DEPENDENT_FACETS_CHANGED";
export const CLEAR_FACET_GROUP = "SEARCH/CLEAR_FACET_GROUP";
export const CLEAR_ALL_FACETS = "SEARCH/CLEAR_ALL_FACETS";
export const TOGGLE_MOBILE_FACETS = "SEARCH/TOGGLE_MOBILE_FACETS";
export const CHANGE_DATE_RANGE = "SEARCH/CHANGE_DATE_RANGE";
export const LOAD_ALL_PAGES = "SEARCH/LOAD_ALL_PAGES";

// Single-dispatch Action Creators
export function mergeQueryParams(params, facets, preSelectedFacets) {
  return {
    type: MERGE_QUERY_PARAMS,
    payload: { params, facets, preSelectedFacets },
  };
}

export function loadStart() {
  return {
    type: LOAD_RESULTS_START,
    payload: null,
  };
}

export function loadSuccess(response, resultsDisplay, mapResponseToState) {
  return {
    type: LOAD_RESULTS_SUCCESS,
    payload: { response, resultsDisplay, mapResponseToState },
  };
}

export function loadFail(err) {
  return {
    type: LOAD_RESULTS_FAIL,
    payload: err,
  };
}

export function sortChanged(sorters) {
  return {
    type: SORT_CHANGED,
    payload: sorters,
  };
}

export function incrementPage() {
  return {
    type: INCREMENT_PAGE,
    payload: null,
  };
}

export function getAllPages() {
  return {
    type: LOAD_ALL_PAGES,
    payload: null,
  };
}

export function changeDateRange(startDate, endDate) {
  return {
    type: CHANGE_DATE_RANGE,
    payload: {
      startDate: startDate,
      endDate: endDate,
    },
  };
}

export function changePage(pageNum) {
  return {
    type: CHANGE_PAGE,
    payload: pageNum,
  };
}

export function keywordsChanged(keywords) {
  return {
    type: KEYWORDS_CHANGED,
    payload: keywords,
  };
}

export function facetChanged(facetId, facetValue) {
  return {
    type: FACET_CHANGED,
    payload: { facetId, facetValue },
  };
}

// expects an updated facets array of objects
export function dependentFacetsChanged(facets) {
  return {
    type: DEPENDENT_FACETS_CHANGED,
    payload: { facets },
  };
}

export function clearFacetGroup(group) {
  return {
    type: CLEAR_FACET_GROUP,
    payload: group,
  };
}

export function clearAllFacets() {
  return {
    type: CLEAR_ALL_FACETS,
    payload: null,
  };
}

export function showMobileFacets() {
  return {
    type: TOGGLE_MOBILE_FACETS,
    payload: true,
  };
}

export function hideMobileFacets() {
  return {
    type: TOGGLE_MOBILE_FACETS,
    payload: false,
  };
}

// Multi-dispatch Action Creators
// Note that multiple dispatches inside an
// action creator only work when using the
// redux-thunk middleware. These action
// creators MUST return a function.
// https://github.com/gaearon/redux-thunk

export function loadResults(resultsDisplay = RESULTS_DISPLAY_APPEND) {
  return (dispatch, getState, { mapResponseToState, fetchSearchResults }) => {
    const state = getState();
    const url = state.url;

    // get our query params, and add any pre-selected facets we
    // might have
    const query = R.merge(state.query, state.preSelectedFacets);

    const facets = selectedFacetIdsByGroup(getState().facets);

    // merge with pre-selected facets
    R.merge(facets, state.preSelectedFacets);

    dispatch(loadStart());
    fetchSearchResults(url, R.mergeAll([query, facets]))
      .then((response) => {
        dispatch(loadSuccess(response, resultsDisplay, mapResponseToState));
      })
      .fail((error) => {
        dispatch(loadFail(error));
      });
  };
}

export function loadNextPage() {
  return (dispatch) => {
    dispatch(incrementPage());
    dispatch(loadResults());
  };
}

export function loadNewPage(pageNum) {
  return (dispatch) => {
    dispatch(changePage(pageNum));
    dispatch(loadResults());
  };
}

export function loadAllPages() {
  return (dispatch) => {
    dispatch(getAllPages());
    dispatch(loadResults());
  };
}

export function loadNewSort(sorters) {
  return (dispatch) => {
    dispatch(sortChanged(sorters));
    dispatch(loadResults());
  };
}

export function loadNewKeywords(keywords) {
  return (dispatch) => {
    dispatch(keywordsChanged(keywords));
    dispatch(loadResults());
  };
}

export function loadDateChange(startDate, endDate) {
  return (dispatch) => {
    dispatch(changeDateRange(startDate, endDate));
    dispatch(loadResults());
  };
}

export function loadFacetChange(facetId, facetValue) {
  return (dispatch) => {
    dispatch(facetChanged(facetId, facetValue));
    dispatch(loadResults());
  };
}

// Expects an associative array (obj) with facetId: facetValue pairs
export function loadDependentFacetsChange(facetsObj) {
  return (dispatch) => {
    dispatch(dependentFacetsChanged(facetsObj));
    dispatch(loadResults());
  };
}

export function loadClearFacetGroup(group) {
  return (dispatch) => {
    dispatch(clearAllFacets()); /* we don't do groups on this site... */
    dispatch(loadResults());
  };
}

export function loadClearAllFacets() {
  return (dispatch) => {
    dispatch(clearAllFacets());
    dispatch(loadResults());
  };
}
