import R from "ramda";
import { mergeQueryParams } from "./search/search.actions";
import { selectedFacetIdsByGroup, setSelectedOnFacetValues } from "./facets";

export function mapStateToUrl(state) {
  // choose which parts of the query
  // state we want exposed in the
  // url query string
  let exposedParams = state.exposedParams;

  // if we're in pagination mode,
  // we also want to expose the page num
  if (state.pagination && !exposedParams.includes("page")) {
    exposedParams.push("page");
  }

  let selectedFacets;
  if (state.facets.length > 0) {
    selectedFacets = selectedFacetIdsByGroup(state.facets);

    // turn selected facets ids into comma delimited string
    Object.keys(selectedFacets).forEach((k) => {
      selectedFacets[k] = selectedFacets[k].join(",");
    });
  } else {
    selectedFacets = state.preSelectedFacets;
  }

  return R.merge(selectedFacets, R.pick(exposedParams, state.query));
}

export function handleUrlChange(urlParams, store) {
  // a list of parameters that we know aren't
  // bound to facet group names
  const nonFacetParams = store.getState().exposedParams;

  let facets = store.getState().facets;

  // separate out the param types
  let queryParams = R.pick(nonFacetParams, urlParams);
  let facetParams = R.omit(nonFacetParams, urlParams);

  let newFacets = [];
  let preSelectedFacets = {};
  if (facets.length > 0) {
    // get an array of valueIds from { group: valueId, valueId } format
    // and set them to selected. If
    newFacets = setSelectedOnFacetValues(R.values(facetParams), true, facets);
  } else {
    // if facets is empty, it's because we haven't gotten
    // a response yet. So we'll also pass an object of
    // pre-selected facets we can pass to the query
    // Note: transforms comma-delimited strings to array

    preSelectedFacets = R.map(R.split(","), facetParams);
  }

  // Anytime we expose a number value to the query
  // string it will be sent back to us as a string.
  // Make sure to cast it back to a number here.
  const castToInt = R.curry(parseInt)(R.__, 10);
  queryParams = R.evolve(
    {
      page: castToInt,
    },
    queryParams,
  );

  // Provide a new facets object.
  // First, just grab all facets ids from
  // from the facetParams
  //pickIds = R.values();

  return store.dispatch(
    mergeQueryParams(queryParams, newFacets, preSelectedFacets),
  );
}
