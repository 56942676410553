import $ from "jquery";
import { breakpoint } from "../breakpoint";

let throttler;

export class ParallaxImageBlock {
  constructor($el) {
    this.$el = $el;
    this.$imageWrapper = this.$el.find(".parallax-scroll-cta__image-wrapper");
    this.$imageTarget = this.$el.find(".parallax-scroll-cta_image-for-parallax");
    this.currentBreakpoint = "";

    this.$imageWrapper.css("background-image", `linear-gradient(0deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,.7) 49%, rgba(0,0,0,0.4) 100%), url(${this.$imageTarget.attr("src")})`);

  }

  bindEvents() {
    let thisAccordion = this;


    thisAccordion.currentBreakpoint = breakpoint();

    $(window).on("resize", (event) => {
      if (throttler) {
        window.clearTimeout(throttler);
      }
      throttler = setTimeout(() => {
        thisAccordion.manageBreakpointBehaviors();
      }, 400);
    });
  }

  manageBreakpointBehaviors() {
    // First, check if we need to manage breakpoint behaviors (has the breakpoint actually changed)
    if (this.currentBreakpoint !== breakpoint()) {
      if (
        (this.activeIn && this.activeIn.indexOf(breakpoint()) > -1) ||
        !this.activeIn
      ) {
        this.$target.hide();
        this.$el.removeClass("is-open");
      } else {
        this.$target.show();
        this.$el.addClass("is-open");
      }
    }

    // Update the current breakpoint
    this.currentBreakpoint = breakpoint();
  }
}
