import { InteractiveTable } from "../common/InteractiveTable.js";
import { updateAriaLive } from "../../a11y/ariaLiveUtils";
import * as DOMPurify from "dompurify";

export class SearchableTable extends InteractiveTable {
  constructor($el) {
    super($el); // to access the parent InteractiveTable constructor

    // Consolidating instructions, labels, and messages, in case we need to import them from a Sitecore dictionary in future
    this.instructionSearchScreenReader = "(table data will update as you type)";
    this.labelClearAll = "clear all";
    this.labelSearch = "Search Entire Table";
    this.labelSearchPlaceholder = "Search";
    this.messageNoResultsVisual =
      "Sorry, there are no results matching your search.";

    // It's important that screenreader messages be short (or they may be cut off by screenreaders), and meaningful.
    this.messageNoResultsScreenreader = "No results found.";
    this.messageSearchClearedScreenreader = "Table search results cleared.";

    this.data = this.readTableData();
    this.ariaLiveMessage = "";
    this.$searchField = null;

    this.$noResults = document.createElement("div");
    this.$noResults.innerHTML = `<p><i>${DOMPurify.sanitize(
      this.messageNoResultsVisual,
    )}</i></p>`;

    this.renderForm();
  }

  // Reads in all of the original table data to search
  // Returns an array of arrays (each nested array representing a table row)
  readTableData() {
    var tableData = [],
      tableRows = this.$table.querySelectorAll("tbody > tr");

    tableRows.forEach((tableRow) => {
      const $rowCells = tableRow.querySelectorAll("td");
      let thisRowValues = [];
      $rowCells.forEach((cell, cellIndex) => {
        thisRowValues[cellIndex] = cell.getAttribute("data-value")
          ? cell.getAttribute("data-value")
          : cell.innerText;
      });

      tableData.push({
        $row: tableRow,
        values: thisRowValues,
      });
    });

    return tableData;
  }

  // Renders the search form (appears on both desktop and mobile)
  renderForm = function () {
    var outerThis = this;

    var searchInputWrapper = document.createElement("div");
    searchInputWrapper.setAttribute(
      "class",
      "responsive-table__search-wrapper",
    ),
      this.$form.appendChild(searchInputWrapper);

    var searchInputId = this.$table.id + "__search";
    var searchLabel = document.createElement("label");
    searchLabel.setAttribute("for", searchInputId),
      searchLabel.setAttribute("class", "responsive-table__label"),
      (searchLabel.innerHTML = DOMPurify.sanitize(this.labelSearch));
    searchInputWrapper.appendChild(searchLabel);

    var searchInput = document.createElement("input");
    searchInput.setAttribute("id", searchInputId),
      searchInput.setAttribute(
        "class",
        "responsive-table__input responsive-table__input--search",
      ),
      searchInput.setAttribute("type", "text"),
      searchInput.setAttribute("placeholder", this.labelSearchPlaceholder),
      searchInput.setAttribute("autocomplete", "on"),
      searchInput.setAttribute(
        "aria-describedby",
        searchInputId + "-description",
      ),
      searchInputWrapper.appendChild(searchInput);

    searchInput.addEventListener(
      "input",
      this.searchInputChangeHandler().bind(this),
    );

    this.$searchField = searchInput;

    var searchInputDescription = document.createElement("span");
    searchInputDescription.setAttribute("id", searchInputId + "-description"),
      searchInputDescription.setAttribute("class", "visually-hidden"),
      (searchInputDescription.innerText = this.instructionSearchScreenReader),
      searchInputWrapper.appendChild(searchInputDescription);

    // Clear all button
    var clearAllButton = document.createElement("button");
    clearAllButton.setAttribute("type", "button"),
      (clearAllButton.innerHTML = DOMPurify.sanitize(this.labelClearAll)),
      clearAllButton.setAttribute(
        "class",
        "responsive-table__button--clear-all",
      ),
      searchInputWrapper.appendChild(clearAllButton);
    clearAllButton.addEventListener("click", function () {
      outerThis.clearChangeHandler();
      updateAriaLive(outerThis.messageSearchClearedScreenreader, "polite");
    });
  };

  // Makes text lowercase, trimmed, normalized, etc. for easy comparison
  // Returns normalized text.
  normalizeText = function (text) {
    const normalized = String(text)
      .toLowerCase()
      .trim()
      .normalize("NFKD")
      .replace(/[\u0300-\u036f]/g, "");

    return normalized;
  };

  // Updates results in the table
  update = function () {
    let searchText = this.normalizeText(
      this.$form.querySelector(".responsive-table__input--search").value,
    );

    let rowsWithoutMatches = 0;
    this.data.forEach((tableRow, index) => {
      const rowCells = tableRow.values;
      const rowMatch = rowCells.some((cell) =>
        this.normalizeText(cell).includes(searchText),
      );
      rowMatch
        ? tableRow.$row.classList.remove("is-hidden--search")
        : (tableRow.$row.classList.add("is-hidden--search"),
          rowsWithoutMatches++);
    });

    if (rowsWithoutMatches === this.data.length) {
      // No results!
      // We need to show this visually and announce to screenreader users so they don't waste time typing more.
      this.$noResults.parentNode ||
        this.$table.parentNode.insertBefore(
          this.$noResults,
          this.$table.nextSibling,
        );
      updateAriaLive(
        DOMPurify.sanitize(this.messageNoResultsScreenreader),
        "assertive",
      );
    } else {
      // Results returned
      // No need to update via ARIA-live because the search input description makes it clear that the results will change.
      this.$noResults.parentNode &&
        this.$table.parentNode.removeChild(this.$noResults);
    }

    if (void 0 !== this.$table.classList) {
      this.$table.classList.remove("effect-flash-in");
      var v = this.$table;
      requestAnimationFrame(function () {
        requestAnimationFrame(function () {
          v.classList.add("effect-flash-in");
        });
      });
    }
  };

  // Handles the changes to the search input field
  searchInputChangeHandler = function () {
    return this.update;
  };
}
