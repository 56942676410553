import R from "ramda";
import { setSelectedOnFacetValues, selectedFacetsFromGroup } from "../facets";

import {
  RESULTS_DISPLAY_APPEND,
  RESULTS_DISPLAY_REFRESH,
} from "../resultsDisplay";

import {
  LOAD_RESULTS_START,
  LOAD_RESULTS_SUCCESS,
  LOAD_RESULTS_FAIL,
  MERGE_QUERY_PARAMS,
  SORT_CHANGED,
  KEYWORDS_CHANGED,
  INCREMENT_PAGE,
  LOAD_ALL_PAGES,
  CHANGE_PAGE,
  FACET_CHANGED,
  DEPENDENT_FACETS_CHANGED,
  CLEAR_FACET_GROUP,
  CLEAR_ALL_FACETS,
  TOGGLE_MOBILE_FACETS,
  CHANGE_DATE_RANGE,
} from "./search.actions";

const actionMap = {
  [LOAD_RESULTS_START]: loadStart,
  [LOAD_RESULTS_SUCCESS]: loadSuccess,
  [LOAD_RESULTS_FAIL]: loadError,
  [SORT_CHANGED]: updateSort,
  [MERGE_QUERY_PARAMS]: updateQueryParams,
  [KEYWORDS_CHANGED]: updateKeywords,
  [INCREMENT_PAGE]: incrementPage,
  [CHANGE_PAGE]: changePage,
  [LOAD_ALL_PAGES]: loadAllPages,
  [FACET_CHANGED]: changeFacet,
  [DEPENDENT_FACETS_CHANGED]: changeDependentFacets,
  [CLEAR_FACET_GROUP]: clearFacetGroup,
  [CLEAR_ALL_FACETS]: clearAllFacets,
  [TOGGLE_MOBILE_FACETS]: toggleMobileFacets,
  [CHANGE_DATE_RANGE]: changeDateRange,
};

export default function searchReducer(state = initialState, action) {
  const actionType = actionMap[action.type];
  return actionType ? actionType(state, action) : state;
}

function loadStart(state, action) {
  return R.merge(state, {
    loading: true,
  });
}

function loadSuccess(state, action) {
  const { mapResponseToState, response } = action.payload;
  return R.merge(mapResponseToState(response, state), {
    loading: false,
    isFirstLoad: false,
    preSelectedFacets: {},
  });
}

function loadError(state, action) {
  return R.merge(state, { loading: false, errorMsg: action.payload });
}

function updateSort(state, action) {
  const activeSorter = R.find(R.propEq("isActive", true), action.payload);

  return R.merge(state, {
    sorters: action.payload,
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      sortBy: activeSorter.value,
      sortOrder: activeSorter.direction,
      page: 1,
    }),
  });
}

function updateQueryParams(state, action) {
  return R.merge(state, {
    query: R.merge(state.query, action.payload.params),
    facets: action.payload.facets,
    preSelectedFacets: action.payload.preSelectedFacets,
  });
}

function incrementPage(state, action) {
  const pageNum = parseInt(state.query.page, 10) + 1;
  return R.merge(state, {
    resultsDisplayStrategy: RESULTS_DISPLAY_APPEND,
    query: R.merge(state.query, {
      page: pageNum,
    }),
  });
}

function changeDateRange(state, action) {
  return R.merge(state, {
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
    }),
  });
}

function changePage(state, action) {
  return R.merge(state, {
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      page: action.payload,
    }),
  });
}

function loadAllPages(state, action) {
  return R.merge(state, {
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      loadAllPages: true,
      page: Math.ceil(state.totalResults / state.query.perPage),
    }),
  });
}

function updateKeywords(state, action) {
  return R.merge(state, {
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      q: action.payload,
      page: 1,
    }),
  });
}

function changeFacet(state, action) {
  // We want to provide a new facet object to our
  // state with a single facet changed.

  // Lookup the path for the given facetId
  // and change it using assocPath
  const facetId = action.payload.facetId;
  const facetValue = action.payload.facetValue;

  const newFacets = setSelectedOnFacetValues(
    [facetId],
    facetValue,
    state.facets,
  );

  return R.merge(state, {
    facets: newFacets,
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      page: 1,
    }),
  });
}

function changeDependentFacets(state, action) {
  // All calculations are being done in
  // AdmissionRepFacetList beforehand

  return R.merge(state, {
    facets: action.payload.facets,
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      page: 1,
    }),
  });
}

function clearFacetGroup(state, action) {
  const group = action.payload;
  const selectedFacetIds = selectedFacetsFromGroup(group).map((v) => v.id);
  const newFacets = setSelectedOnFacetValues(
    selectedFacetIds,
    false,
    state.facets,
  );

  return R.merge(state, {
    facets: newFacets,
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      page: 1,
    }),
  });
}

function clearAllFacets(state, action) {
  const facets = state.facets;
  const selectedFacetIds = facets.reduce((all, group) => {
    return all.concat(selectedFacetsFromGroup(group).map((v) => v.id));
  }, []);

  const newFacets = setSelectedOnFacetValues(selectedFacetIds, false, facets);

  return R.merge(state, {
    facets: newFacets,
    resultsDisplayStrategy: RESULTS_DISPLAY_REFRESH,
    query: R.merge(state.query, {
      page: 1,
    }),
  });
}

function toggleMobileFacets(state, action) {
  return R.merge(
    {
      mobileFacetsShown: action.payload,
    },
    state,
  );
}
