import $ from "jquery";

/*
 * Alert
 * Close an alert banner and notify the BE that it's been read via the endpoint in data-endpoint
 */
export class Alert {
  constructor($el) {
    this.$el = $el;
    this.$close = this.$el.find(".js-alert__close");
    this.endpoint = this.$el.data("endpoint");
    this.id = this.$el.data("id");

    this.bindEvents();
  }

  bindEvents() {
    let thisAlert = this;

    thisAlert.$close.on("click", function() {
      thisAlert.$el.slideUp(function() {
        thisAlert.$el.remove();
      });
      fetch(thisAlert.endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify({
          "id": thisAlert.id
        })
      });
    });
  }
}
