import $ from "jquery";
import React from "react";
import classNames from "classnames";

import MediaPromo from "../components/MediaPromo.jsx";

import NewsListingContainer from "./NewsListingContainer.jsx";

import { array, bool, object, string } from "prop-types";

/*
  PromoGrid
  promo-grid styled list container
 */
export default class PromoGrid extends React.Component {

  static propTypes = {
    listItems: array,
    listTypeAttributes: object,
    moreToLoad: object,
    wrapperid: string.isRequired
  };

  UNSAFE_componentWillMount() {}



  renderItem = (item, i) => {
    const { wrapperid } = this.props;

    switch(item.type) {
      case "MediaPromo":
        return (
          <MediaPromo key={wrapperid + i}
            {...item}
          />
        );
      case "NewsListing":
        return (
          <NewsListingContainer key={wrapperid + i}
            {...item}
          />
        );
      default:
        return (
          <div>Type Not Supported</div>
        );
    }
  }

  render() {
    const { listItems, listTypeAttributes } = this.props;

    const columnCountClass = listTypeAttributes.count
      ? "layout-columns--" + listTypeAttributes.count + "-up"
      : false;

    const columnClasses = classNames("layout-columns", {
      [columnCountClass]: columnCountClass,
      "layout-columns--wide-gutter": listTypeAttributes.widemargin,
      "layout-columns--whitebar": listTypeAttributes.whitebar
    });

    const classList = classNames("promo-grid", {
      "promo-grid--wrap": listTypeAttributes.wrap
    });

    return (
      <div className={classList}>
        <div className={columnClasses}>
          { listItems.map( (item, i) => this.renderItem(item, i)) }
        </div>
      </div>
    );
  }
}
