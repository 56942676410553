import R from "ramda";
import PropTypes from "prop-types";
import React from "react";
import AdmissionRepRadioFacetGroup from "./AdmissionRepRadioFacetGroup.jsx";
import AdmissionRepTypeaheadGroup from "./AdmissionRepTypeaheadGroup.jsx";
import {
  setSelectedOnFacetValues,
  groupHasSelectedChildren,
  selectedFacetsFromGroup,
} from "../../redux/facets";
import { breakpoint } from "../../../breakpoint";
import classNames from "classnames";

// A small functional component for the Clear All links
const ClearAll = (props) => {
  const { handleClear } = props;
  return (
    <button
      onClick={handleClear}
      className="event-facets__group-clear"
      type="button"
    >
      Clear All
    </button>
  );
};

export default class AdmissionRepFacetList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentBreakpoint: breakpoint(),
      drawerOpen: !(breakpoint().indexOf("small") > -1),
      inToggleMode: breakpoint().indexOf("small") > -1,
      facetChoice: "",
    };

    this.facetIndex = [];
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.updateBreakpoint);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateBreakpoint);
  };

  updateBreakpoint = () => {
    const { currentBreakpoint } = this.state;
    const newBreakpoint = breakpoint();

    if (currentBreakpoint !== newBreakpoint) {
      // Only update if we're actually switching breakpoints
      // This avoids issues like filter dropdowns triggering viewport changes on selection on Android phones
      this.setState({
        ...this.state,
        currentBreakpoint: breakpoint(),
        drawerOpen: !(breakpoint().indexOf("small") > -1),
        inToggleMode: breakpoint().indexOf("small") > -1,
      });
    }
  };

  clearAll() {
    this.props.handleClearAll();
  }

  emptyFacetValuesArray = (group) => {
    let emptiedGroup = group;
    emptiedGroup.values = [];
    return emptiedGroup;
  };

  clearDependentFacetValues = (facetsClone, group) => {
    // Check and clear any dependent facets
    const thisFacetIndex = this.facetIndex.indexOf(group.id);
    const emptyFacetValuesArray = this.emptyFacetValuesArray;

    facetsClone.forEach(function (facet, i) {
      let newFacet = facet;
      if (i > thisFacetIndex) {
        newFacet = emptyFacetValuesArray(facet);
      }
      facetsClone[i] = newFacet;
    });

    return facetsClone;
  };

  onClearGroup = (group) => {
    // Set up a clone of the facets state that we can alter
    let facetsClone = this.props.facets;

    // Loop through all the facet values and set them all selected:false
    const selectedFacetIds = selectedFacetsFromGroup(group).map((v) => v.id);
    facetsClone = setSelectedOnFacetValues(
      selectedFacetIds,
      false,
      this.props.facets,
    );

    // Clear any dependent facets
    facetsClone = this.clearDependentFacetValues(facetsClone, group);

    // Fire the actions/dispatches
    this.props.handleDependentFacetChange(facetsClone);
  };

  onFacetChanges = (valueId, group) => {
    // Reminder: Even if this function is passed to a child via props,
    // the scope of "this" is still interpreted as AdmissionRepFacetList.

    // Let's put in a check to see if the values array is blank.
    // If it's blank, we bail out.
    if (group.values.length === 0) {
      return;
    }

    // Set up a clone of the facets state that we can alter
    let facetsClone = this.props.facets;

    let prevSelected = [];

    // Set selected
    if (group.type === "radio") {
      // loop through the values and set selected
      group.values.forEach((valueItem, index) => {
        const selected = valueItem.id === valueId ? true : false;

        const valueItemArr = [valueItem.id];

        facetsClone = setSelectedOnFacetValues(
          valueItemArr,
          selected,
          facetsClone,
        );
      });
    } else {
      // Typeahead
      // prevSelected = [previously selected value's ID]
      prevSelected = group.values.reduce((accum, value) => {
        if (value.selected) {
          accum.push(value.id);
        }
        return accum;
      }, []);

      // valueIdsOnly is the newly selected value's ID
      let valueIdsOnly = [valueId];

      // difference is an array with previous and next ids
      // Single value if no previous selection [newID]
      // Two values if previous selection [oldID, newID]
      let difference = prevSelected
        .filter((x) => !valueIdsOnly.includes(x))
        .concat(valueIdsOnly.filter((x) => !prevSelected.includes(x)));

      // Handle the facet change for both the previously selected (if present)
      // and the newly selected facet IDs
      difference.map((thisValueId) => {
        facetsClone = setSelectedOnFacetValues(
          [thisValueId],
          !prevSelected.includes(thisValueId),
          facetsClone,
        );
      });
    }

    // Clear any dependent facets
    facetsClone = this.clearDependentFacetValues(facetsClone, group);

    // Fire the actions/dispatches
    this.props.handleDependentFacetChange(facetsClone);
  };

  renderDependentFacets() {
    // Loop through all the facets
    return this.props.facets.map((group, i) => {
      if (group.values.length === 0 && group.childGroups.length === 0) {
        return null;
      }

      switch (group.type) {
        case "radio":
          return (
            <AdmissionRepRadioFacetGroup
              key={group.id}
              handleFacetChange={this.onFacetChanges}
              group={group}
            />
          );

        default:
          return (
            <AdmissionRepTypeaheadGroup
              key={group.id}
              handleFacetChange={this.onFacetChanges}
              handleClearGroup={this.onClearGroup}
              group={group}
            />
          );
      }
    });
  }

  toggleFacets = () => {
    this.setState({
      ...this.state,
      drawerOpen: !this.state.drawerOpen,
    });
  };

  render() {
    const AdmissionRepDependentFacets = this.renderDependentFacets();

    const { drawerOpen, inToggleMode } = this.state;
    const { showClearAll, facets } = this.props;

    const facetClass = classNames("event-facets", "admissionrep-facets", {
      "is-open": drawerOpen,
      "in-toggle-mode": inToggleMode,
    });

    // Create a facet index array that we can use later
    // to get the index of a facet by id
    if (this.facetIndex.length === 0 && facets.length > 0) {
      this.facetIndex = facets.map((facet) => {
        return facet.id;
      });
    }

    return (
      <div className={facetClass}>
        {!inToggleMode && <h3>Filter Results</h3>}
        {inToggleMode && (
          <h3>
            <button onClick={this.toggleFacets}>Filter Results</button>
          </h3>
        )}
        <div className="event-facets__all-facets">
          <div className="event-typeahead__group">
            {drawerOpen && AdmissionRepDependentFacets}
          </div>
          {drawerOpen && showClearAll && (
            <ClearAll handleClear={() => this.clearAll()} />
          )}
        </div>
      </div>
    );
  }
}

const { string, arrayOf, bool, shape, number, oneOfType, func } = PropTypes;
AdmissionRepFacetList.propTypes = {
  title: string,
  mobileFiltersShown: bool,
  hideMobileFacets: func,
  facets: arrayOf(shape({ id: oneOfType([number, string]) })),
  numOpenGroups: number,
  handleDependentFacetChange: func.isRequired,
  handleClearGroup: func.isRequired,
  handleClearAll: func.isRequired,
  showClearAll: bool,
};

ClearAll.propTypes = {
  handleClear: func,
};

AdmissionRepFacetList.defaultProps = {
  facets: [],
  numOpenGroups: null,
  showClearAll: false,
};
