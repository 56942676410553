import * as DOMPurify from "dompurify";

// Checks if an appropriate aria-live exists
// Gets it if it does, creates if it doesn't
// Returns a DOM node
export function getAriaLiveNode(ariaLiveType) {
  const liveMode = checkAriaLiveType(ariaLiveType);
  const $existingAriaLive = document.querySelector(
    "[aria-live='" + liveMode + "']",
  );
  if ($existingAriaLive) {
    return $existingAriaLive;
  } else {
    // create it
    const $newAriaLive = createAriaLive(liveMode);
    // insert it into the DOM
    document.body.appendChild($newAriaLive);
    return $newAriaLive;
  }
}

export function checkAriaLiveType(ariaLiveType) {
  if (ariaLiveType !== "polite" && ariaLiveType !== "assertive") {
    return "polite";
  }
  return ariaLiveType;
}

// We need to either pass in the node directly,
// or we need to have a function that returns the node?
export function updateAriaLive(message, ariaLiveType, ariaLiveId = null) {
  const ariaResetDelay = 350;
  const liveMode = checkAriaLiveType(ariaLiveType);
  const hasId = ariaLiveId !== null;
  const $areaToUpdate = hasId
    ? document.getElementById(hasId)
    : getAriaLiveNode(liveMode);

  $areaToUpdate.textContent = DOMPurify.sanitize(message);

  setTimeout(function () {
    $areaToUpdate.textContent = "";
  }, ariaResetDelay);
}

export function createAriaLive(ariaLiveType) {
  const liveMode = checkAriaLiveType(ariaLiveType);
  const $ariaLiveNode = document.createElement("div");
  $ariaLiveNode.setAttribute("aria-live", liveMode);
  $ariaLiveNode.classList.add("visually-hidden");
  return $ariaLiveNode;
}
