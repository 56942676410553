import React from "react";
import PropTypes from "prop-types";

import { facetValueShape } from "../../dataShapes";
import Select from "react-select";

/**
 *
 * A section for a single grouping of event facets.
 * ```
 *  <VideoTypeaheadGroup
      group={group}
      handleFacetChange={func}
      handleClearAll={func}
      placeholder={string} />
 * ```
 * @component
 * @param {object} props
 * @param {object} props.group The group of facets to show
 * @param {function} props.handleFacetChange Function called when a facet is selected/deselected
 * @param {function} props.handleClearGroup Function called when clearing all selected facets
 * @param {string} props.placeholder Optional placeholder text
 */

class VideoTypeaheadGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  onFacetChange = (value) => {
    if (!value) {
      this.onClearGroup();
      return;
    }

    const { group } = this.props;
    let localSelected = group.values.reduce((accum, value) => {
      if (value.selected) {
        accum.push(value.id);
      }
      return accum;
    }, []);

    let valueIdsOnly = Array.isArray(value)
      ? value.map((v) => v.id)
      : [value.id];

    let difference = localSelected
      .filter((x) => !valueIdsOnly.includes(x))
      .concat(valueIdsOnly.filter((x) => !localSelected.includes(x)));

    difference.map((facetId) => {
      this.props.handleFacetChange(facetId, !localSelected.includes(facetId));
    });
  };

  onClearGroup() {
    const group = this.props.group;
    this.props.handleClearGroup(group);
  }

  render() {
    const group = this.props.group;
    const reactSelectValues = group.values.map((value) => ({
      ...value,
      value: value.id,
      label: value.name,
    }));

    const available = reactSelectValues.filter((value) => {
      return !value.selected;
    });
    const selected = reactSelectValues.filter((value) => {
      return value.selected;
    });

    const multi = group.singleChoice === true ? false : true;
    const clearable = !multi;

    return (
      <div className="video-typeahead">
        <h3 className="txt-h4">
          <label id={group.id}>{group.label}</label>
        </h3>
        <Select
          isMulti={multi}
          value={selected}
          onChange={this.onFacetChange}
          options={available}
          isClearable={clearable}
          classNamePrefix="video-typeahead"
          maxMenuHeight={200}
          aria-labelledby={group.id}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default VideoTypeaheadGroup;

const { func, shape, bool, number, string, oneOfType, arrayOf } = PropTypes;

VideoTypeaheadGroup.propTypes = {
  dupeSelected: bool,
  handleFacetChange: func.isRequired,
  handleClearGroup: func,
  group: shape({
    id: oneOfType([number, string]).isRequired,
    label: string,
    values: arrayOf(facetValueShape).isRequired,
  }),
  placeholder: string,
};

VideoTypeaheadGroup.defaultProps = {
  dupeSelected: true,
};
