import React from "react";
import classNames from "classnames";

import { array, bool, object, string } from "prop-types";

/*
  MediaPromo
  media-promo styled list item
  See media-promo.md for options and typical fields
 */
export default class MediaPromo extends React.Component {

  static propTypes = {
    baseImage: object,
    images: array,
    isVideo: bool,
    mediaIcon: string,
    mediaPromoClass: string,
    mediaTag: object,
    summary: string,
    title: string,
    url: string
  };

  render() {
    const { baseImage, images, isVideo, mediaIcon, mediaPromoClass, mediaTag, summary, title, url } = this.props;

    const classList = classNames("media-promo", {
      [mediaPromoClass]: mediaPromoClass,
      "media-promo--icon": mediaIcon
    });

    return (
      <div className={classList}>
        {mediaIcon && <a className="media-promo__icon" href={url}><img src={mediaIcon} alt={title} /></a>}
        {baseImage && <a href={ url } className="media-promo__image-wrapper">
          <picture className="media-promo__image">
            { images.map( (image, i) => {
              let srcValue = `${image.lores} 1x, ${image.hires} 2x" media="(min-width: ${image.useat})`;

              return(<source key={i} srcSet={srcValue}/>);
            })}
            <img alt={ baseImage.alt } src={ baseImage.src } />
          </picture>
          { isVideo && <div className="media-promo__video-icon"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="2 2 21 21"><g fill="none" fillRule="evenodd"><path d="M21,12 C21,16.9704866 16.9704866,21 12,21 C7.02951343,21 3,16.9704866 3,12 C3,7.02951343 7.02951343,3 12,3 C16.9704866,3 21,7.02951343 21,12 Z M20.1428571,12 C20.1428571,7.50290036 16.4970996,3.85714286 12,3.85714286 C7.50290036,3.85714286 3.85714286,7.50290036 3.85714286,12 C3.85714286,16.4970996 7.50290036,20.1428571 12,20.1428571 C16.4970996,20.1428571 20.1428571,16.4970996 20.1428571,12 Z M16.2857143,12.2142857 L9.85714286,16.7142857 L9.85714286,7.71428571 L16.2857143,12.2142857 Z"/></g></svg></div>}
        </a>}
        <div className="media-promo__body">
          { mediaTag && <a className="media-promo__tag" href={ mediaTag.url }>{ mediaTag.text }</a> }
          <div className="media-promo__title">
            <a href={ url }>{ title }</a>
          </div>
          { summary && <div className="media-promo__summary">{ summary }</div>}
        </div>
      </div>
    );
  }
}
