import R from "ramda";
import * as RA from "ramda-adjunct";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import classnames from "classnames";

export default class ResultItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemWrapper = null;
  }

  componentDidMount() {
    this.itemWrapper.className = this.itemWrapper.className + " is-visible";
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return moment(date).format("MMMM D, YYYY");
  }

  renderImage() {
    const { image, contentUrl, title } = this.props;
    if (image) {
      return (
        <figure className="search-item__media">
          <a href={contentUrl}>
            <img src={image} alt={title} />
          </a>
        </figure>
      );
    } else {
      return null;
    }
  }

  renderEyebrow() {
    const { authors, date } = this.props;

    const transformedDate = date ? this.formatDate(date) : "";
    const transformedAuthors =
      authors.length > 0
        ? R.compose(
            R.splitEvery(2),
            R.intersperse(", "),
            RA.compact,
          )([...authors])
        : "";

    const itemPairs = R.compose(
      R.splitEvery(2),
      R.intersperse("|"),
      RA.compact,
    )([transformedDate, transformedAuthors]);

    if (!itemPairs.length) {
      return;
    }

    const items = RA.mapIndexed(
      ([val, sep], idx) => (
        <React.Fragment key={idx}>
          <span className="search-item__eyebrow-item">{val}</span>
        </React.Fragment>
      ),
      itemPairs,
    );

    return <div className="search-item__eyebrow">{items}</div>;
  }

  render() {
    const {
      title,
      authors,
      body,
      date,
      displayUrl,
      contentUrl,
      featured,
      shortDesc,
      featuredResultLabel,
    } = this.props;

    const classNames = classnames("search-item", {
      "search-item--featured": featured,
    });

    return (
      <article className={classNames} ref={(el) => (this.itemWrapper = el)}>
        {/* Screenreader label for featured results */}
        {featured && featuredResultLabel && (
          <div className="visually-hidden">{featuredResultLabel}: </div>
        )}
        {this.renderImage()}
        <div className="search-item__content">
          <div className="search-item__title">
            <a
              href={contentUrl}
              dangerouslySetInnerHTML={{ __html: title }}
            ></a>
          </div>
          {(date || authors.length > 0) && !featured && this.renderEyebrow()}
          {shortDesc && featured && (
            <div
              className="search-item__description search-item__description--short"
              dangerouslySetInnerHTML={{ __html: shortDesc }}
            ></div>
          )}
          {body && !featured && (
            <div
              className="search-item__description"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
          )}
          {displayUrl && (
            <div className="search-item__url">
              <a href={contentUrl}>{displayUrl}</a>
            </div>
          )}
        </div>
      </article>
    );
  }
}

const { arrayOf, string, bool } = PropTypes;
ResultItem.propTypes = {
  date: string.isRequired,
  authors: arrayOf(string),
  title: string.isRequired,
  body: string.isRequired,
  contentUrl: string.isRequired,
  displayUrl: string,
  image: string,
  featured: bool,
  shortDesc: string,
  featuredResultLabel: string,
};
