import $ from "jquery";
import Vimeo from "@vimeo/player";
import Youtube from "youtube-player";

let throttler;

/*
 * SlideshowVideo
 * Player and effect management for videos in slideshows
 */
export class SlideshowVideo {
  constructor($el) {
    this.$el = $el;
    this.$wrapper = this.$el.closest(".slideshow");
    this.$trigger = this.$el.find(".js-slideshow-video__button");
    this.$imagewrapper = this.$el.find(".js-slideshow-video__image");
    this.$caption = this.$el.find(".js-slideshow-video__caption");
    this.$parentSlideControls = this.$el
      .closest(".js-slider")
      .siblings(".js-slider-nav-wrapper")
      .find(".js-slider-nav");

    this.id = this.$el.data("id");
    this.videoid = this.$el.data("video");
    this.videotype = this.$el.data("videotype");
    this.videourl = this.$el.data("videourl");

    this.player = {};

    this.init();
    // this.bindEvents();
  }

  /* Load YouTube video */
  init() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      thisVideo.player = new Vimeo(thisVideo.id, { url: thisVideo.videourl });

      thisVideo.bindEvents();
    } else {
      // Assume it's YouTube
      thisVideo.player = Youtube(thisVideo.id, {
        videoId: thisVideo.videoid,
        playerVars: {
          rel: "0",
        },
      });

      thisVideo.bindEvents();
    }
  }

  handleStop() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      this.player.pause();
    } else {
      this.player.stopVideo();
    }
  }

  handleStart() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      this.player.play();
    } else {
      this.player.playVideo();
    }
  }

  bindEvents() {
    let thisVideo = this;

    /* If Slick throws a slidechange, turn off and reset things */
    $(window).on("slideChange", function () {
      thisVideo.closeCurtains();
    });

    /* Reset trigger point on resize */
    $(window).on("resize", (event) => {
      if (throttler) {
        window.clearTimeout(throttler);
      }
      throttler = setTimeout(() => {
        thisVideo.closeCurtains();
      }, 400);
    });

    thisVideo.$trigger.on("click", function (e) {
      e.preventDefault();

      thisVideo.openCurtains();
    });
  }

  openCurtains() {
    let thisVideo = this;

    thisVideo.$el.addClass("in-play-mode");

    // Constrained slideshows have slightly different animation parameters
    const isConstrained = thisVideo.$wrapper.hasClass("slideshow--constrained");

    /* Show video frame */
    thisVideo.$el.find("iframe").show();

    // Differentiate certain animations for full-bleed vs constrained slideshows
    const sliderNavAnimation = isConstrained
      ? {
          bottom: thisVideo.$parentSlideControls.outerHeight() * -1 + "px",
        }
      : {
          top:
            this.$imagewrapper.height() +
            thisVideo.$parentSlideControls.outerHeight() +
            "px",
        };

    /* Calculate new heights and animate "curtain raise" effect */
    thisVideo.$trigger.animate(
      {
        top: "-100%",
      },
      1000,
      "linear",
      function () {
        thisVideo.$trigger.hide();
      },
    );

    thisVideo.$parentSlideControls.animate(sliderNavAnimation, 1000, "linear");

    // Only animate the caption if the slider nav is not position: static
    if (thisVideo.$parentSlideControls.css("position") !== "static") {
      thisVideo.$caption.animate(
        { "padding-top": thisVideo.$parentSlideControls.outerHeight() + "px" },
        1000,
        "linear",
      );
    }

    thisVideo.$imagewrapper.animate(
      {
        top: "-100%",
      },
      1000,
      "linear",
      function () {
        if (thisVideo.$el.offset().top < $(window).scrollTop()) {
          $("html, body").animate(
            { scrollTop: thisVideo.$el.offset().top + "px" },
            500,
          ); /* get the video in view */
        }
        /* Trigger video play when animations have completed */
        thisVideo.handleStart();
      },
    );
  }

  closeCurtains() {
    let thisVideo = this;

    /* We don't animate here because the only way to stop the video is through YouTube controls (in which case the video remains visible) or a slide change call, so no point in waiting on the animations, just reset everything */
    if (thisVideo.$el.hasClass("in-play-mode")) {
      thisVideo.handleStop();
      /* Hide video iFrame to prevent inadvertant keyboard navigation while not visible */
      thisVideo.$el.find("iframe").hide();
      thisVideo.$imagewrapper.css({ top: "0" });
      thisVideo.$caption.css({ "padding-top": "" });
      thisVideo.$trigger.show();
      thisVideo.$trigger.css({ top: "0" });
      thisVideo.$parentSlideControls.css({ top: "", bottom: "" });
      thisVideo.$el.removeClass("in-play-mode");
    }
  }
}
