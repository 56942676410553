import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { throttle } from "lodash";

function createMarkup(htmlstring) {
  return { __html: htmlstring };
}

export default class ProgramItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemWrapper = null;
    this.state = {
      height: 0,
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  componentDidUpdate(prevProps) {
    /* Only do this if it hasn't already been done, to avoid infinite loop */
    if (!this.props.height || this.props.height != prevProps.height) {
      const { id, setHeights } = this.props;

      /* Get the current height */
      const newheight = this.divElement.clientHeight;
      /* Pass it up to the parent */
      setHeights(id, newheight);
    }
  }

  /* Update heights on resize, alert parent to change */
  handleResize = throttle(() => {
    this.setState(
      {
        height: this.state.height > 0 ? this.bodyElement.clientHeight : 0,
      },
      function () {
        setTimeout(() => {
          /* Increase the height to reveal the body, or decrease it to hide it */
          this.props.setHeights(this.props.id, this.divElement.clientHeight);
          /* and then send the new height to the parent so it can reshuffle if needed */
          this.props.handleShuffle();
        }, 210);
      },
    );
  }, 50);

  /* Toggle drawer, alert parent to change */
  handleClick = () => {
    this.setState(
      {
        height: this.state.height ? 0 : this.bodyElement.clientHeight,
      },
      function () {
        setTimeout(() => {
          /* Increase the height to reveal the body, or decrease it to hide it */
          this.props.setHeights(this.props.id, this.divElement.clientHeight);
          /* and then send the new height to the parent so it can reshuffle if needed */
          this.props.handleShuffle();
        }, 210);
      },
    );
  };

  render() {
    const {
      body,
      college,
      infoBlocks,
      program,
      title,
      contentUrl,
      linkText,
      top,
      left,
    } = this.props;

    const compStyle = {
      top: top,
      left: left,
    };

    const bodyStyle = {
      height: this.state.height + "px",
    };

    const classes = classnames("program-item", {
      "is-open": this.state.height,
    });

    return (
      <div
        className={classes}
        style={compStyle}
        ref={(divElement) => (this.divElement = divElement)}
      >
        <div className="program-item__inner">
          <div className="program-item__title-card">
            <h3 className="program-item__title" onClick={this.handleClick}>
              <button
                className="js-program-item__trigger"
                type="button"
                ref={(el) => (this.itemWrapper = el)}
              >
                <span>{title}</span>
              </button>
            </h3>
            {(college || program) && (
              <div className="program-item__info">
                <div className="program-item__college">{college}</div>
                <div className="program-item__program">{program}</div>
              </div>
            )}
          </div>
          <div className="program-item__body" style={bodyStyle}>
            <div
              className="program-item__body-inner"
              ref={(bodyElement) => (this.bodyElement = bodyElement)}
            >
              {body && (
                <div
                  className="rich-text"
                  dangerouslySetInnerHTML={createMarkup(body)}
                ></div>
              )}
              {contentUrl && linkText && (
                <p>
                  <a href={contentUrl} className="arrow-link">
                    {linkText}
                  </a>
                </p>
              )}
              {infoBlocks && (
                <div className="program-item__info-blocks">
                  {infoBlocks.map((block, i) => {
                    return (
                      <div
                        key={i}
                        className={block.class}
                        dangerouslySetInnerHTML={createMarkup(block.body)}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { array, func, number, string } = PropTypes;
ProgramItem.propTypes = {
  body: string,
  college: string,
  id: string,
  infoBlocks: array,
  program: string,
  title: string.isRequired,
  contentUrl: string,
  linkText: string,
  setHeights: func.isRequired,
  handleShuffle: func.isRequired,
  height: number,
  top: string,
  left: string,
  pid: string,
};
