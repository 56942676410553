import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export default class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.searchSubmit = null;

    this.setSearchSubmitRef = (element) => {
      this.searchSubmit = element;
    };

    this.state = {
      initiatedLoad: false,
      dirty: this.props.keywords ? true : false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) {
      this.setState({ initiatedLoad: false });
    }
  }

  handleChange = (event) => {
    this.setState({
      dirty: this.keywordInput.value ? true : false,
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    this.props.handleEntry(this.keywordInput.value);
    this.setState({ initiatedLoad: true });
  }

  handleClear = (event) => {
    event.preventDefault();
    this.keywordInput.value = "";
    this.searchSubmit.click();
    this.setState({
      dirty: this.keywordInput.value ? true : false,
    });
  };

  renderSearchIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M13.7883251,16.4522199 C12.6180677,17.1561916 11.2474286,17.5610762 9.78223446,17.5610762 C5.48379024,17.5610762 2,14.0778665 2,9.78053812 C2,5.48275442 5.48356701,2 9.78223446,2 L9.78223446,2.74921606 L9.78223446,2 C14.0801653,2 17.5641297,5.48303557 17.5641297,9.78053812 C17.5641297,11.5318838 16.985361,13.1480873 16.0086346,14.448387 C16.0211751,14.4606251 16.0335512,14.4731261 16.0457557,14.4858897 L20.8077165,19.4659996 C21.3796437,20.0641273 21.3584041,21.0126443 20.7602764,21.5845715 C20.1621487,22.1564988 19.2136317,22.1352592 18.6417044,21.5371315 L13.8797437,16.5570216 C13.8473714,16.5231663 13.8168995,16.4881885 13.7883251,16.4522199 Z M9.78223446,2.74921606 L9.78223446,3.49843212 C6.31104998,3.49843212 3.49843212,6.3103939 3.49843212,9.78053812 C3.49843212,13.2502494 6.31129556,16.0626441 9.78223446,16.0626441 C13.252448,16.0626441 16.0656975,13.249957 16.0656975,9.78053812 C16.0656975,6.31068623 13.2526935,3.49843212 9.78223446,3.49843212 L9.78223446,2.74921606 Z" />
        </g>
      </svg>
    );
  }

  render() {
    const { dictionary, keywords } = this.props;
    const { dirty } = this.state;
    const renderSearchIcon = this.renderSearchIcon;

    const inputClass = classNames("search-box__input", {
      "is-dirty": dirty,
    });

    return (
      <form
        className="search-box"
        onSubmit={(e) => this.handleSubmit(e)}
        noValidate
      >
        <div className={inputClass}>
          <input
            id="site-search"
            type="text"
            defaultValue={keywords || ""}
            ref={(input) => (this.keywordInput = input)}
            onChange={(e) => this.handleChange(e)}
          />
          <button
            type="submit"
            className="search-box__button"
            ref={this.setSearchSubmitRef}
          >
            {renderSearchIcon()}
            <span className="visually-hidden">Submit Search</span>
          </button>
          <label htmlFor="site-search" className="u-visuallyhidden">
            {dictionary.searchBoxLabel}
          </label>
        </div>
        <button
          type="button"
          className="search-box__clear"
          aria-label="Clear search"
          onClick={(e) => this.handleClear(e)}
        >
          &times;
        </button>
      </form>
    );
  }
}

const { string, func, shape, bool } = PropTypes;

SearchBox.propTypes = {
  keywords: string,
  handleEntry: func.isRequired,
  loading: bool,
  dictionary: shape({
    searchBoxLabel: string,
  }).isRequired,
};
