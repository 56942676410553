import $ from "jquery";
import Vimeo from "@vimeo/player";
import Youtube from "youtube-player";

export class VideoAccordion {
  constructor($el) {
    this.$el = $el;
    this.$trigger = this.$el.find(".js-video-accordion__trigger");
    this.$target = this.$el.find(".js-video-accordion__target");
    this.$playIcon = this.$el.find(".js-video-accordion__play");

    this.id = this.$el.data("id");
    this.videoid = this.$el.data("video");
    this.videotype = this.$el.data("videotype");
    this.videourl = this.$el.data("videourl");

    this.player = {};

    this.init();
  }

  init() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      thisVideo.player = new Vimeo(thisVideo.id, {url: thisVideo.videourl});

      thisVideo.bindEvents();
    }
    else {
      // Assume it's YouTube
      thisVideo.player = Youtube(thisVideo.id, {
        videoId: thisVideo.videoid,
        playerVars: {
          rel: "0"
        }
      });

      thisVideo.bindEvents();
    }
  }

  handleStop() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      this.player.pause();
    }
    else {
      this.player.stopVideo();
    }
  }

  handleStart() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      this.player.play();
    }
    else {
      this.player.playVideo();
    }
  }

  bindEvents() {
    let thisVideo = this;

    thisVideo.$trigger.on("click", function(e) {
      e.preventDefault();

      thisVideo.$target.slideToggle("fast", function() {
        if ($(this).is(":hidden")) {
          thisVideo.$playIcon.removeClass("is-open");
          thisVideo.handleStop();
        }
        else {
          thisVideo.$playIcon.addClass("is-open");
          thisVideo.handleStart();
        }
      });
    });
  }
}
