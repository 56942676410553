import PropTypes from "prop-types";
import React from "react";
import ProgramItem from "./ProgramItem.jsx";
import { isLastPage } from "../../paging";
import LoadMore from "../LoadMore.jsx";
import R from "ramda";

export default class ProgramResultList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      positioning: [],
      leftlength: 0,
      rightlength: 0,
      isViewAll: false,
      moreLoading: false,
    };

    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.renderPagingControl = this.renderPagingControl.bind(this);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resetSizes);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.results != prevProps.results) {
      /* If we've gotten new results, reset everything to force shuffle */
      this.setState({
        positioning: this.props.results.map(function (result) {
          return {
            pid: result.id,
          };
        }),
        leftlength: 0,
        rightlength: 0,
      });
    } else if (
      this.state.positioning.length &&
      !R.filter(R.propEq("height", undefined), this.state.positioning).length &&
      this.state.leftlength === 0
    ) {
      /* Should only run once heights are filled but positions are not yet calculated */
      const newstate = this.setPositions();

      this.setState(newstate);
    }

    /* On resize we'll need to check all the heights again, so trigger a reset */
    window.addEventListener("resize", this.resetSizes);

    // On Load More/All, find the last currently loaded item and focus it
    if (!prevState.moreLoading && this.state.moreLoading) {
      const resultsWrapper = document.getElementById("program-results");
      const itemTriggersBeforeLoad = resultsWrapper.querySelectorAll(
        ".js-program-item__trigger",
      );
      const currentLastItemTrigger = this.findLastNode(itemTriggersBeforeLoad);
      currentLastItemTrigger.focus();

      this.setState({
        moreLoading: false,
      });
    }
  };

  findLastNode(nodeList) {
    return nodeList[nodeList.length - 1];
  }

  isLast = (totalResults, perPage, page) => {
    return isLastPage(totalResults, perPage, page);
  };

  /* Clear left and right column heights and reset positions array to ids only */
  resetSizes = () => {
    this.setState({
      positioning: this.props.results.map(function (result) {
        return {
          pid: result.id,
        };
      }),
      leftlength: 0,
      rightlength: 0,
    });
  };

  /* Calculate positioning for each child and pass to child; also set container height */
  setPositions = () => {
    const { positioning } = this.state;

    return this.props.results.reduce(
      function (accum, curr, i) {
        const currpos = R.find(R.propEq("pid", curr.id), positioning);
        /* left is either 0, or 50% + space to equal half an SCSS $promo-spacer */
        const left =
          accum.leftlength <= accum.rightlength ? "0" : "calc(50% + 0.7145rem)";
        const top =
          accum.leftlength <= accum.rightlength
            ? accum.leftlength + "px"
            : accum.rightlength + "px";

        return {
          positioning: R.append(
            R.merge(currpos, { left: left, top: top }),
            accum.positioning,
          ),
          leftlength:
            accum.leftlength <= accum.rightlength
              ? accum.leftlength + currpos.height
              : accum.leftlength,
          rightlength:
            accum.leftlength <= accum.rightlength
              ? accum.rightlength
              : accum.rightlength + currpos.height,
        };
      },
      {
        positioning: [],
        leftlength: 0,
        rightlength: 0,
      },
    );
  };

  /* Take height from child and place in appropriate slot in state */
  setHeights = (id, height) => {
    this.setState((prevState) => ({
      positioning: prevState.positioning.map((el) =>
        el.pid === id ? { ...el, height } : el,
      ),
    }));
  };

  /* Just a wrapper to call setPositions and then apply that to state */
  handleShuffle = () => {
    const newstate = this.setPositions();
    this.setState(newstate);
  };

  renderInfoSvg() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M12.0000004,2 L12,2 C6.47715,2.00000024 2,6.47715 2,12 C2.00000024,17.52285 6.47715,22 12,22 C17.52285,21.9999998 22,17.52285 22,12 L22,12.0000004 C22,6.47715044 17.52285,2.00000044 12,2.00000044 L12.0000004,2 Z M12.8333338,17 C12.8333338,17.4602375 12.4602379,17.8333333 12.0000004,17.8333333 C11.5397629,17.8333333 11.1666671,17.4602375 11.1666671,17 C11.1666671,17 11.1666671,17 11.1666671,17 L11.1666671,11.1666667 L11.1666671,11.1666668 C11.166667,10.7064293 11.5397629,10.3333335 12.0000004,10.3333335 C12.4602379,10.3333334 12.8333337,10.7064293 12.8333337,11.1666668 L12.8333338,17 Z M12.0000004,8.66666667 C11.3096445,8.66666664 10.7500004,8.1070225 10.7500004,7.41666667 C10.7500004,6.72631083 11.3096445,6.16666667 12.0000004,6.16666667 C12.6903562,6.1666667 13.2500004,6.72631083 13.2500004,7.41666667 C13.2500004,8.10702245 12.6903562,8.66666661 12.0000004,8.66666661 Z" />
        </g>
      </svg>
    );
  }

  handleLoadMore = () => {
    this.setState({
      moreLoading: true,
    });
    if (!this.state.isViewAll) {
      this.props.nextPage();
      this.setState({
        isViewAll: true,
      });
    } else {
      this.props.loadAllPages();
    }
  };

  renderPagingControl() {
    if (
      this.props.loadMoreAll &&
      !this.isLast(
        this.props.totalResults,
        this.props.query.perPage,
        this.props.query.page,
      )
    ) {
      return (
        <LoadMore
          handleClick={this.handleLoadMore}
          totalPages={this.props.totalResults}
          dictionary={this.props.dictionary}
          isLoadAll={this.state.isViewAll}
          loading={this.props.loading}
        />
      );
    }
  }

  render() {
    const { isFirstLoad, query, results, searchId } = this.props;

    const { positioning, leftlength, rightlength } = this.state;

    const shouldShowNoResults = results.length === 0 && !isFirstLoad;

    const searchLinkTarget = searchId + "-jumptarget";

    const height = leftlength > rightlength ? leftlength : rightlength;
    const compStyle = {
      height: height + "px",
    };

    return (
      <div className="program-results-wrapper">
        <section
          id="program-results"
          className="program-results"
          style={compStyle}
        >
          <div className="program-results__item-wrapper" id={searchLinkTarget}>
            <div className="program-results__items">
              {shouldShowNoResults ? (
                <p>No results found to match your query.</p>
              ) : null}
              {results.map((item, i) => (
                <ProgramItem
                  key={item.id}
                  query={query}
                  index={i}
                  setHeights={this.setHeights}
                  handleShuffle={this.handleShuffle}
                  {...R.find(R.propEq("pid", item.id), positioning)}
                  {...item}
                />
              ))}
            </div>
          </div>
        </section>
        {this.renderPagingControl()}
      </div>
    );
  }
}

const { arrayOf, string, object, func, bool, number, shape, oneOfType } =
  PropTypes;

ProgramResultList.propTypes = {
  results: arrayOf(
    shape({
      id: oneOfType([number, string]),
    }),
  ),
  totalResults: number.isRequired,
  loading: bool,
  pagination: bool,
  loadMoreAll: bool,
  nextPage: func,
  loadAllPages: func,
  sorters: arrayOf(
    shape({
      id: oneOfType([number, string]).isRequired,
      label: string.isRequired,
      value: string.isRequired,
      direction: oneOfType([bool, string]),
      isActive: bool.isRequired,
    }),
  ),
  isFirstLoad: bool,
  query: shape({
    q: string,
    sortBy: string,
    page: number,
    perPage: number,
    pageId: string,
  }),
  searchId: string,
  dictionary: object,
};
