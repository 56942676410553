import PropTypes from "prop-types";
import React from "react";
import { isLastPage, isFirstPage, pageBlock } from "../paging";
import classNames from "classnames";

const Pagination = ({
  totalResults,
  resultsPerPage,
  currentPage,
  handlePageChange,
}) => {
  const block = pageBlock(totalResults, resultsPerPage, currentPage);
  const isLast = isLastPage(totalResults, resultsPerPage, currentPage);
  const isFirst = isFirstPage(currentPage);

  function getPage(visiblelabel, label, value, isDisabled) {
    const buttonClass = classNames("btn btn--link", {
      "is-disabled": isDisabled,
      "is-current": currentPage === visiblelabel,
      "search-results__prev-page": label === "Previous",
      "search-results__next-page": label === "Next",
    });

    return (
      <button
        className={buttonClass}
        key={label}
        type="button"
        onClick={() => onPageClick(value, isDisabled)}
        disabled={isDisabled}
      >
        {visiblelabel}
      </button>
    );
  }

  function onPageClick(pageNum, isDisabled) {
    if (!isDisabled) {
      handlePageChange(pageNum);
    }
  }

  return (
    <div className="search-results__pagination">
      <div className="search-results__pages">
        {getPage("", "Previous", currentPage - 1, isFirst)}
        {block.map((page) =>
          getPage(page.label, page.label, page.num, page.current),
        )}
        {getPage("", "Next", currentPage + 1, isLast)}
      </div>
    </div>
  );
};

const { number, func } = PropTypes;

Pagination.propTypes = {
  totalResults: number.isRequired,
  resultsPerPage: number.isRequired,
  currentPage: number.isRequired,
  handlePageChange: func.isRequired,
};

export default Pagination;
