import { connect } from "react-redux";
import FacetList from "../components/FacetList.jsx";
import {
  loadFacetChange,
  loadClearFacetGroup,
  loadClearAllFacets,
  loadDateChange,
  hideMobileFacets,
} from "../redux/search/search.actions";

const mapStateToProps = (state, ownProps) => {
  return {
    title: state.dictionary.facetsTitle,
    skipLinkText: state.dictionary.skipLinkText,
    facets: state.facets,
    numOpenGroups: state.numOpenGroups,
    mobileFacetsShown: state.mobileFacetsShown,
    searchId: state.searchId,
    startDate: state.query.startDate,
    endDate: state.query.endDate,
    dateQuickPicks: state.dateQuickPicks,
    dateConstraints: state.dateConstraints,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleFacetChange: (facetId, facetValue) => {
      dispatch(loadFacetChange(facetId, facetValue));
    },

    handleClearGroup: (group) => {
      dispatch(loadClearFacetGroup(group));
    },

    handleClearAll: () => {
      dispatch(loadClearAllFacets());
      dispatch(loadDateChange(null, null));
    },

    hideMobileFacets: () => {
      dispatch(hideMobileFacets);
    },

    handleDateChange: (startDate, endDate) => {
      dispatch(loadDateChange(startDate, endDate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacetList);
