import $ from "jquery";
import { breakpoint } from "../breakpoint";

let throttler;

/*
 * SimpleMore
 * Simply a button that toggles the element that immediately follows it and then disappears,
 *   remaining open permanently.
 * Can take "false" for activeIn to be active everywhere, or an array of breakpoints to be active
 *   in.  These need to match the breakpoint content values in the SCSS
 */
export class SimpleMore {
  constructor($el, activeIn) {
    // Only initialize if we're not in the Sitecore page editor
    if (
      !document.body.classList.contains("is-page-editor") &&
      !document.querySelector("html").classList.contains("is-page-editor")
    ) {
      this.$el = $el;
      this.activeIn = activeIn;
      this.$target = this.$el.next();
      /* Simple more assumes that there is only one button as a child of $el, and that $el itself is a wrapper and not the button */
      this.$button = this.$el.find("button");
      /* Find legit focusable elements... doing this instead of adding jQuery UI because we're not using it elsewhere */
      this.$targetFirstTabbable = this.$target
        .find("a, button, :input, [tabindex]")
        .first();

      /* Hide if for all devices, or if mobile only and in mobile mode */
      if (
        (this.activeIn && this.activeIn.indexOf(breakpoint()) > -1) ||
        !this.activeIn
      ) {
        this.$target.hide();
      }

      this.bindEvents();
    }
  }

  bindEvents() {
    let thisMore = this;

    thisMore.$button.on("click", function () {
      thisMore.$el.animate({ opacity: 0, height: 0 }, 300, function () {
        thisMore.$el.remove();
      });
      thisMore.$target.slideToggle();
      thisMore.$targetFirstTabbable.focus();
    });
  }
}
