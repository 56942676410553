import $ from "jquery";
import Vimeo from "@vimeo/player";
import Youtube from "youtube-player";
import getScrollBehavior from "../a11y/getScrollBehavior.js";
//import { getFirstFocusableChild } from "../a11y/focusUtils.js";

export class VideoGrid {
  constructor($el) {
    this.$el = $el;
    this.$allOverlays = this.$el.find(".js-video-grid__play-overlay");
    this.$allTriggers = this.$el.find(".js-video-grid__trigger");
    this.$allTargets = this.$el.find(".js-video-grid__target");
    this.$allContent = this.$el.find(".js-video-grid__content");
    this.$allPlayButtons = this.$el.find(".js-video-grid__play");
    this.$allCloseButtons = this.$el.find(".js-video-grid__close");
    this.$selectedVideo = null;
    this.$selectedTrigger = null;
    this.$selectedPanel = null;
    this.$selectedOverlay = null;
    this.$selectedTitle = null;
    this.$selectedClose = null;
    this.id = null;
    this.videoId = null;
    this.videourl = null;
    this.videotype = null;
    this.player = null;
    this.featureOpen = false;
    this.throttler = null;
    this.scrollBehavior = getScrollBehavior();
    this.transitionTime = this.scrollBehavior === "smooth" ? 350 : 0; // should match CSS transition in milliseconds
    this.handleEscapePress = this.handleEscapePress.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleTriggerClick = this.handleTriggerClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  init() {
    let _this = this;

    _this.initAria();
    _this.calculateAllHeights();
    _this.bindEvents();
    _this.setVideoContentHeights();

    //check for already open panels on initialization (Load More edge case)
    _this.checkForOpenPanels();
  }

  destroy() {
    let _this = this;

    _this.destroyEvents();
  }

  checkForOpenPanels() {
    let _this = this;

    const $activeVideoTitle = $(".video-grid-block__title--active");
    if ($activeVideoTitle.length > 0) {
      // set selected video
      _this.setSelectedVideo($activeVideoTitle.find("button")[0]);
    }
  }

  initAria() {
    let _this = this;

    _this.$allTriggers.get().map((trigger) => {
      const videoID = trigger.getAttribute("data-video");
      trigger.setAttribute("aria-controls", "video-grid-panel__" + videoID);
      trigger.setAttribute("aria-expanded", false);

      // Add the same to the close button as well
      const closeBtn = $(trigger)
        .closest(".video-grid-block")
        .find(".js-video-grid__close");
      closeBtn[0].setAttribute("aria-controls", "video-grid-panel__" + videoID);
      closeBtn[0].setAttribute("aria-expanded", false);
    });
  }

  destroyAria() {
    let _this = this;

    _this.$allTriggers.get().map((trigger) => {
      trigger.removeAttr("aria-controls");
      trigger.removeAttr("aria-expanded");
    });
    _this.$allCloseButtons.get().map((button) => {
      button.removeAttr("aria-controls");
      button.removeAttr("aria-expanded");
    });
  }

  toggleAria($trigger) {
    const expandedString = $trigger.attr("aria-expanded");
    const toggleTo = expandedString == "true" ? "false" : "true";
    $trigger.attr("aria-expanded", toggleTo);
  }

  destroyVideo() {
    let _this = this;

    if (_this.player) {
      if (_this.videotype === "vimeo") {
        _this.player.pause();
      } else {
        _this.player.pauseVideo();
      }

      // Destroy and nullify player
      _this.player.destroy();
      _this.player = null;
    }
  }

  // Not autoplaying, as that causes a11y issues
  loadVideo() {
    let _this = this;

    if (_this.videotype === "vimeo") {
      _this.player = new Vimeo(_this.id, { url: _this.videourl });
      //_this.player.play();
    } else {
      _this.player = Youtube(_this.id, {
        videoId: _this.videoid,
        playerVars: {
          rel: "0",
        },
      });
      //_this.player.playVideo();
    }
  }

  expandVideoPanel(time) {
    let _this = this;

    const transitionTime =
      typeof time == "number" ? time : _this.transitionTime;

    _this.featureOpen = true;

    // Temporarily disable CSS transitions
    const panelTransition = _this.$selectedPanel.css("transition");
    _this.$selectedPanel.css("transition", "");

    // On the next animation frame, set the height to zero as
    // a starting point, and re-instate the transition
    requestAnimationFrame(function () {
      _this.$selectedPanel.css({
        height: "0px",
        transition: panelTransition,
        display: "block",
      });

      // Then once those style changes are made, let's do the transition:
      requestAnimationFrame(function () {
        _this.toggleAria(_this.$selectedTrigger);
        _this.toggleAria(_this.$selectedClose);

        // Set the already-calculated panel height
        _this.setVideoPanelHeight(
          _this.$selectedPanel.attr("data-height"),
          transitionTime,
        );
        _this.showVideoIsActive();

        // After the transition is finished, scroll to and load the video
        setTimeout(function () {
          if (document.body.scrollIntoView) {
            _this.$selectedPanel[0].scrollIntoView(false, {
              top: 0,
              behavior: _this.scrollBehavior,
            });
          } else {
            const videoTop = _this.$selectedPanel[0].offsetTop;
            window.scrollTo({
              top: videoTop,
              behavior: _this.scrollBehavior,
            });
          }
          _this.loadVideo();
        }, transitionTime);
      });
    });
  }

  showVideoIsActive() {
    let _this = this;

    if (_this.$selectedVideo) {
      _this.$selectedVideo.addClass("video-grid-block--active");
      _this.$selectedOverlay.addClass("video-grid-block__play-overlay--active");
      _this.$selectedTitle.addClass("video-grid-block__title--active");
    }
  }

  showVideoIsInactive() {
    let _this = this;

    if (_this.$selectedVideo) {
      _this.$selectedVideo.removeClass("video-grid-block--active");
      _this.$selectedOverlay.removeClass(
        "video-grid-block__play-overlay--active",
      );
      _this.$selectedTitle.removeClass("video-grid-block__title--active");
    }
  }

  // Close one specific video panel
  // (with an optional time attribute, not currently used)
  closeVideoPanel(time) {
    let _this = this;

    const transitionTime =
      typeof time == "number" ? time : _this.transitionTime;

    // Destroy the video
    _this.destroyVideo();

    // Toggle Aria
    _this.toggleAria(_this.$selectedTrigger);
    _this.toggleAria(_this.$selectedClose);

    // Remove the active state
    _this.showVideoIsInactive();

    // Set the focus back to the trigger
    _this.$selectedTrigger.focus();

    // Set the heights to zero to collapse the panel (via CSS transition)
    _this.setVideoPanelHeight(0, transitionTime);

    // After the transition is finished, hide the panel, switch focus, and reset the vars
    setTimeout(function () {
      if (_this.$selectedVideo) {
        _this.$selectedVideo.focus();
        _this.$selectedPanel.css({
          display: "none",
        });
        _this.$selectedTrigger = null;
        _this.$selectedVideo = null;
        _this.$selectedPanel = null;
        _this.$selectedOverlay = null;
        _this.$selectedTitle = null;
        _this.$selectedClose = null;
      }
      _this.featureOpen = false;
    }, transitionTime);
  }

  // Closes all open video panels (no time option available)
  closeAllVideoPanels() {
    // Find any open video panels, and click on their close button
    const openVideoCloseButtons = $(".video-grid-block--active").find(
      ".js-video-grid__close",
    );
    openVideoCloseButtons.trigger("click");
  }

  getHiddenHeight(el) {
    const visibleStyles = {
      overflow: "visible",
      height: "auto",
      maxHeight: "none",
      opacity: "0",
      visibility: "hidden",
      display: "block",
    };

    let height = 0;

    if (!el?.cloneNode) {
      return height;
    }

    const clone = el.cloneNode(true);

    Object.assign(clone.style, { ...visibleStyles });

    el.parentNode.appendChild(clone);
    height = clone.offsetHeight;

    el.parentNode.removeChild(clone);

    return height;
  }

  calculateAllHeights() {
    let _this = this;

    _this.$allTargets.get().map((item) => {
      // Only set this if it's different, since we do this on every resize
      if (item.getAttribute("data-height") != _this.getHiddenHeight(item)) {
        item.setAttribute("data-height", _this.getHiddenHeight(item));
      }
    });
  }

  setVideoContentHeights(perRow) {
    let _this = this;
    let videoContentHeight = [];
    let videoRow = 0;

    // Get the position of the first video
    const firstVideo = _this.$allTriggers.get()[0];
    const firstVideoRect = firstVideo
      .closest(".video-grid-block")
      .getBoundingClientRect();

    // Get the index of the first video that wraps
    const wrappedVideo = _this.$allTriggers.get().findIndex((video, idx) => {
      const videoRect = video
        .closest(".video-grid-block")
        .getBoundingClientRect();
      if (idx > 0 && videoRect.x <= firstVideoRect.x) {
        return true;
      }
    });

    // If there's a wrapped video, its index is equal to the number of videos
    // there are per row. Otherwise, there are as many videos per row as there
    // are videos altogether
    const videosPerRow =
      wrappedVideo > 0 ? wrappedVideo : _this.$allTriggers.length;

    // Only set the content heights if we need to, i.e. if there is more than
    // one video per row
    if (videosPerRow > 1) {
      // Get the largest value for the natural height in each row
      _this.$allContent.get().map((item, idx) => {
        videoRow = Math.floor(idx / videosPerRow);

        videoContentHeight[videoRow] = videoContentHeight[videoRow] || 0;

        videoContentHeight[videoRow] = Math.max(
          _this.getHiddenHeight(item),
          videoContentHeight[videoRow],
        );
      });

      // Set each item in the row to use that largest height value
      _this.$allContent.get().map((item, idx) => {
        videoRow = Math.floor(idx / videosPerRow);

        if (
          !$(item).style ||
          ($(item).style &&
            $(item).style.height &&
            $(item).style.height !== videoContentHeight[videoRow])
        ) {
          $(item).css({
            height: videoContentHeight[videoRow],
          });
        }
      });
    } else {
      // Otherwise, there is just one video per row, so don't explicitly set the
      // height
      _this.$allContent.get().map((item) => {
        $(item).css({
          height: "",
        });
      });
    }
  }

  destroyAllHeights() {
    let _this = this;

    _this.$allTargets.get().map((item) => {
      item.removeAttr("data-height");
    });
  }

  setVideoPanelHeight(height, time) {
    let _this = this;

    // Setting this here to avoid race conditions
    const $selectedPanel = _this.$selectedPanel;
    const $selectedVideo = _this.$selectedVideo;

    if ($selectedPanel) {
      // Set the transition duration to 0
      $selectedPanel.css("transition-duration", "0s");
      $selectedVideo.css("transition-duration", "0s");

      // Flush the CSS
      $selectedPanel[0].offsetHeight;

      // Set the height and the transition duration again
      $selectedPanel.css({
        height: height + "px",
        "transition-duration": time + "ms",
      });
      $selectedVideo.css({
        "padding-bottom": height + "px",
        "transition-duration": time + "ms",
      });
    }
  }

  setSelectedVideo(buttonDomElement) {
    let _this = this;

    _this.$selectedTrigger = $(buttonDomElement); // button
    _this.$selectedVideo = _this.$selectedTrigger.closest(".video-grid-block");
    _this.$selectedPanel = _this.$selectedVideo.find(".js-video-grid__target");
    _this.$selectedOverlay = _this.$selectedVideo.find(
      ".js-video-grid__play-overlay",
    );
    _this.$selectedTitle = _this.$selectedVideo.find(
      ".js-video-grid-block__title",
    );
    _this.$selectedClose = _this.$selectedVideo.find(".js-video-grid__close");

    _this.id = _this.$selectedTrigger.data("id");
    _this.videoid = _this.$selectedTrigger.data("video");
    _this.videotype = _this.$selectedTrigger.data("videotype");
    _this.videourl = _this.$selectedTrigger.data("videourl");
  }

  handleEscapePress(e) {
    let _this = this;

    if (e.defaultPrevented) {
      return;
    }
    const key = event.key || event.keyCode;
    if (key === "Escape" || key === "Esc" || key === 27) {
      _this.closeAllVideoPanels();
    }
  }

  handleResize(e) {
    let _this = this;
    if (_this.throttler) {
      window.clearTimeout(_this.throttler);
    }
    _this.throttler = setTimeout(() => {
      // Re-calculate all heights, update the data attributes
      _this.calculateAllHeights();
      _this.setVideoContentHeights();

      // If a feature is open, fix its size
      if (_this.featureOpen) {
        _this.setVideoPanelHeight(_this.$selectedPanel.attr("data-height"), 0);
      }
    }, 50);
  }

  handleOverlayClick(e) {
    e.preventDefault();
    // Fire a click on this video's trigger
    const thisVideoTrigger = $(e.target)
      .closest(".video-grid-block")
      .find(".js-video-grid__trigger");
    thisVideoTrigger.trigger("click");
  }

  handleTriggerClick(e) {
    let _this = this;

    e.preventDefault();

    // Check to see if a panel is already open
    if ($(".video-grid-block--active").length > 0) {
      // Did we click on the already-open video?
      if (
        $(e.currentTarget)
          .parent(".video-grid-block__title")
          .hasClass("video-grid-block__title--active")
      ) {
        // Close the already-open panel
        _this.closeVideoPanel();
      } else {
        // Switch videos
        _this.closeAllVideoPanels();
        setTimeout(function () {
          _this.setSelectedVideo(e.currentTarget);
          _this.expandVideoPanel(_this.transitionTime);
        }, _this.transitionTime);
      }
    } else {
      // Open the new panel
      _this.setSelectedVideo(e.currentTarget);
      _this.expandVideoPanel(_this.transitionTime);
    }

    $(e.currentTarget).focus();
  }

  handleCloseClick(e) {
    let _this = this;
    e.preventDefault();
    _this.closeVideoPanel();
  }

  bindEvents() {
    let _this = this;

    // Reset bottom padding on window resize
    $(window).on("resize", this.handleResize);

    // Show feature video
    _this.$allOverlays.on("click", this.handleOverlayClick);
    _this.$allTriggers.on("click", this.handleTriggerClick);

    // Close feature video
    _this.$allCloseButtons.on("click", this.handleCloseClick);

    // Handling escape clicks
    $(document).on("keydown", this.handleEscapePress);
  }

  destroyEvents() {
    let _this = this;

    $(window).off("resize", this.handleResize);
    if (_this.$allOverlays !== null) {
      _this.$allOverlays.off("click", this.handleOverlayClick);
    }
    if (_this.$allTriggers !== null) {
      _this.$allTriggers.off("click", this.handleTriggerClick);
    }
    if (_this.$allCloseButtons !== null) {
      _this.$allCloseButtons.off("click", this.handleCloseClick);
    }
    $(document).off("keydown", this.handleEscapePress);
  }
}
