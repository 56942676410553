import PropTypes from "prop-types";
import React from "react";
import VideoTypeaheadGroup from "../Videos/VideoTypeaheadGroup.jsx";

export default class VideoFacetList extends React.Component {
  constructor(props) {
    super(props);
  }

  renderFacetGroups() {
    return this.props.facets.map((group, i) => {
      if (group.values.length === 0 && group.childGroups.length === 0) {
        return null;
      }

      return (
        <VideoTypeaheadGroup
          key={group.id}
          handleFacetChange={this.props.handleFacetChange}
          handleClearGroup={this.props.handleClearGroup}
          group={group}
          placeholder={this.props.placeholder}
        />
      );
    });
  }

  render() {
    const FacetGroups = this.renderFacetGroups();

    return (
      <div className="video-facets">
        <div className="video-facets__all-facets">{FacetGroups}</div>
      </div>
    );
  }
}

const { string, arrayOf, shape, number, oneOfType, func } = PropTypes;
VideoFacetList.propTypes = {
  title: string,
  facets: arrayOf(shape({ id: oneOfType([number, string]) })),
  numOpenGroups: number,
  handleFacetChange: func.isRequired,
  handleClearGroup: func,
  placeholder: string,
};

VideoFacetList.defaultProps = {
  facets: [],
  numOpenGroups: null,
};
