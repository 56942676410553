import { connect } from "react-redux";
import AdmissionRepFacetList from "../../components/AdmissionReps/AdmissionRepFacetList.jsx";
import {
  loadDependentFacetsChange,
  loadClearFacetGroup,
  loadClearAllFacets,
  hideMobileFacets,
} from "../../redux/search/search.actions";

const mapStateToProps = (state, ownProps) => {
  return {
    title: state.dictionary.facetsTitle,
    facets: state.facets,
    numOpenGroups: state.numOpenGroups,
    mobileFacetsShown: state.mobileFacetsShown,
    showClearAll: state.showClearAll,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDependentFacetChange: (facetsToSelect) => {
      dispatch(loadDependentFacetsChange(facetsToSelect));
    },

    handleClearGroup: (group) => {
      dispatch(loadClearFacetGroup(group));
    },

    handleClearAll: () => {
      dispatch(loadClearAllFacets());
    },

    hideMobileFacets: () => {
      dispatch(hideMobileFacets);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdmissionRepFacetList);
