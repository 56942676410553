/*
 * ResponsiveTable
 * Add pseudo headers to responsive table for use at mobile widths
 */
export class ResponsiveTable {
  constructor($el) {
    this.$el = $el; // Vanilla DOM element
    this.$el.id = $el.getAttribute("id");
    this.tableHeadersArray = [...this.$el.getElementsByTagName("th")];

    this.addResponsiveCellHeaders();
  }

  // Fixes issues when headers have encodable characters in them (ex: &)
  decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  addResponsiveCellHeaders() {
    try {
      const styleElm = document.createElement("style");
      document.head.appendChild(styleElm);
      const styleSheet = styleElm.sheet;

      this.tableHeadersArray.map((tableHeader, index) => {
        // prettier-ignore
        const decodedHTML = this.decodeHtml(tableHeader.innerHTML.trim());
        const thisHeaderRule =
          "#" +
          this.$el.id +
          " td:nth-child(" +
          (index + 1) +
          ")::before {content:'" +
          decodedHTML +
          "';}";

        styleSheet.insertRule(thisHeaderRule, styleSheet.cssRules.length);
      });
    } catch (e) {
      console.log("addResponsiveCellHeaders(): " + e);
    }
  }
}
