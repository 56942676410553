import PropTypes from "prop-types";

// allows us to define propTypes recursively
// https://github.com/facebook/react/issues/5676
const lazyF =
  (f) =>
  (...args) =>
    f().apply(this, args);

const { shape, oneOfType, string, number, bool, arrayOf } = PropTypes;

export const facetValueShape = shape({
  id: oneOfType([number, string]).isRequired,
  name: string.isRequired,
  count: number.isRequired,
  selected: bool.isRequired,
  childValues: arrayOf(lazyF(() => facetValueShape)),
});

export const facetGroupShape = shape({
  id: oneOfType([number, string]).isRequired,
  label: string.isRequired,
  values: arrayOf(facetValueShape).isRequired,
  childGroups: arrayOf(lazyF(() => facetGroupShape)),
});
