import $ from "jquery";

let throttler;
const keyCodes = {
  tab: 9,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  enter: 13,
  space: 32,
  escape: 27,
};

export class SearchFlyout {
  constructor($el) {
    this.$el = $el;
    this.$target = $("#" + this.$el.data("target"));
    this.$focus = $("#" + this.$el.data("focus"));

    this.bindEvents();
  }

  bindEvents() {
    let thisFlyout = this;

    // Click open behavior
    thisFlyout.$el.on("click", function (e) {
      let newHiddenState =
        thisFlyout.$target.attr("aria-hidden") === "true" ? "false" : "true";
      thisFlyout.$target.toggleClass("is-open");
      thisFlyout.$target.attr("aria-hidden", newHiddenState);

      if (newHiddenState === "false") {
        thisFlyout.$focus.focus();
        thisFlyout.$el.attr("tabindex", "-1");
        thisFlyout.$el.attr("aria-hidden", "true");
      } else {
        thisFlyout.$el.attr("tabindex", "0");
        thisFlyout.$el.attr("aria-hidden", "false");
      }
    });

    // Key open behavior
    thisFlyout.$el.on("keydown", function (e) {
      switch (e.keyCode) {
        case keyCodes.enter:
        case keyCodes.space:
          e.preventDefault();
          let newHiddenState =
            thisFlyout.$target.attr("aria-hidden") === "true"
              ? "false"
              : "true";
          thisFlyout.$target.toggleClass("is-open");
          thisFlyout.$target.attr("aria-hidden", newHiddenState);

          if (newHiddenState === "false") {
            thisFlyout.$focus.focus();
            thisFlyout.$el.attr("tabindex", "-1");
            thisFlyout.$el.attr("aria-hidden", "true");
          } else {
            thisFlyout.$el.attr("tabindex", "0");
            thisFlyout.$el.attr("aria-hidden", "false");
            thisFlyout.$el.focus();
          }
          break;
        default:
      }
    });

    // Esc to close
    thisFlyout.$target.on("keydown", function (e) {
      e.stopPropagation();

      switch (e.keyCode) {
        case keyCodes.escape:
          thisFlyout.$target.removeClass("is-open");
          thisFlyout.$target.attr("aria-hidden", "true");
          thisFlyout.$el.attr("tabindex", "0");
          thisFlyout.$el.focus();

          break;
        default:
      }
    });
  }
}
