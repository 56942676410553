import $ from "jquery";
import React from "react";
import R from "ramda";
import classNames from "classnames";

import PromoGrid from "../containers/PromoGrid.jsx";

import { string } from "prop-types";

/*
  ListContainer
  Fetch items to add to a list.
 */
export default class ListContainer extends React.Component {

  static propTypes = {
    endpoint: string.isRequired,
    wrapperid: string.isRequired
  };

  state = {
    isLoading: true,
    nextItem: 0,
    data: {
      listItems: []
    }
  };

  UNSAFE_componentWillMount() {
    const { endpoint, wrapperid } = this.props;
    const { nextItem } = this.state;

    let headers = {
      method: "get"
    };

    /* fetch doesn't build get strings, which is annoying, so steal param from jQuery */
    fetch(endpoint + "?" + $.param({ wrapperid, nextItem }), headers)
      .then( (response) => {
        if (!response.ok) {
          return {
            error: "There has been an error contacting the server. Server responded with: " + response.statusText,
            questions: []
          };
        }
        return response.json();
      })
      .then((json) => {
        this.setState({
          nextItem: this.state.nextItem + parseInt(json.perPage),
          data: json
        });
      });
  }

  renderList = () => {
    const { wrapperid } = this.props;
    const { data } = this.state;

    switch(data.listType) {
      case "PromoGrid":
        return (
          <PromoGrid key={wrapperid}
            wrapperid={wrapperid}
            {...data}
          />
        );
      case "DoubleList":
        return (
          <PromoGrid key={wrapperid}
            wrapperid={wrapperid}
            listItems={[data.column1, data.column2]}
            {...data}
          />
        );
      default:
        return (
          <div>Type Not Supported</div>
        );
    }
  }

  loadMore = () => {
    const { endpoint, wrapperid } = this.props;
    const { nextItem } = this.state;

    let headers = {
      method: "get"
    };

    fetch(endpoint + "?" + $.param({ wrapperid, nextItem }), headers)
      .then( (response) => {

        if (!response.ok) {
          return {
            error: "There has been an error contacting the server. Server responded with: " + response.statusText,
            questions: []
          };
        }
        return response.json();
      })
      .then((json) => {
        if (json.listType === "DoubleList") {
          this.setState({
            nextItem: this.state.nextItem + parseInt(json.perPage),
            data: R.merge(this.state.data, {
              column1: R.merge(this.state.data.column1, R.merge(json.column1, {
                listItems: R.concat(this.state.data.column1.listItems, json.column1.listItems)
              })),
              column2: R.merge(this.state.data.column2, R.merge(json.column2, {
                listItems: R.concat(this.state.data.column2.listItems, json.column2.listItems)
              }))
            })
          });
        }
        else {
          this.setState({
            nextItem: this.state.nextItem + parseInt(json.perPage),
            data: R.merge(this.state.data, R.merge(json, {
              listItems: R.concat(this.state.data.listItems, json.listItems)
            }))
          });
        }
      });
  }

  render() {
    const { linkToShow, moreToLoad } = this.state.data;

    const btnClassList = classNames("standalone-button", {
      "standalone-button--hide-mobile": moreToLoad && moreToLoad.hideMobile
    });

    return (
      <div className="list-app">
        { this.renderList() }
        { moreToLoad && <div className={ btnClassList }><button className="btn btn--primary btn--arrow" onClick={this.loadMore}>{ moreToLoad.text }</button></div> }
        { linkToShow && <div className={ btnClassList }><a href={ linkToShow.url } className="btn btn--primary">{ linkToShow.text }</a></div> }
      </div>
    );
  }
}
