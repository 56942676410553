import { connect } from "react-redux";
import SimpleResultList from "../components/SimpleResultList.jsx";
import { loadNextPage, loadNewPage } from "../redux/search/search.actions";

const mapStateToProps = state => {
  return {
    results: state.results,
    resultsDisplayStrategy: state.resultsDisplayStrategy,
    totalResults: state.totalResults,
    loading: state.loading,
    isFirstLoad: state.isFirstLoad,
    query: state.query,
    pagination: state.pagination,
    dictionary: state.dictionary
  };
};

const mapDispatchToProps = dispatch => {
  return {
    nextPage: () => {
      dispatch(loadNextPage());
    },
    gotoPage: pageNum => {
      dispatch(loadNewPage(pageNum));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleResultList);
