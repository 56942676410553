import { connect } from "react-redux";
import VideoResultList from "../../components/Videos/VideoResultsList.jsx";
import {
  loadNextPage,
  loadNewSort,
  loadNewPage,
  loadAllPages,
  loadFacetChange,
  //showMobileFacets,
} from "../../redux/search/search.actions";
import { allSelectedFacets } from "../../redux/facets";

const mapStateToProps = (state) => {
  return {
    //alertbanner: state.alertbanner,
    results: state.results,
    resultsDisplayStrategy: state.resultsDisplayStrategy,
    selectedFacets: allSelectedFacets(state.facets),
    totalResults: state.totalResults,
    listTitle: state.listTitle,
    loading: state.loading,
    //isFirstLoad: state.isFirstLoad,
    //sorters: state.sorters,
    query: state.query,
    pagination: state.pagination,
    dictionary: state.dictionary,
    searchId: state.searchId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextPage: () => {
      dispatch(loadNextPage());
    },
    loadAllPages: () => {
      dispatch(loadAllPages());
    },
    changeSort: (sorters) => {
      dispatch(loadNewSort(sorters));
    },
    gotoPage: (pageNum) => {
      dispatch(loadNewPage(pageNum));
    },
    dismissFacet: (facetId) => {
      dispatch(loadFacetChange(facetId, false));
    },
    //showMobileFacets: () => {
    //  dispatch(showMobileFacets());
    //},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoResultList);
