import $ from "jquery";
import { objectFitSupport, objectFit } from "../../polyfill/object-fit";

export class MotionHeroVideo {
  constructor($el) {
    this.$el = $el;
    this.$container = $el.parent();
    this.$media = $el.find("video");
    this.videoid = this.$media.attr("id");
    this.$playpause = $el.find(".js-animated-hero__pause");
    this.playPromise = document.querySelector("video").play();

    this.bindEvents();
  }

  bindEvents() {
    const thisVideo = this;

    // Use CSS in all browsers and only run the polyfill in IE
    if (!objectFitSupport()) {
      this.adjustMedia();
      $(window).on("resize", () => this.adjustMedia());
    }

    const $playPause = this.$playpause;

    // If autoplay is not supported, change the play to "pause"
    if (this.playPromise !== undefined) {
      this.playPromise
        .then(function () {
          // Automatic playback started!
        })
        .catch(function (error) {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
          $playPause.addClass("is-paused");
        });
    }

    this.$playpause.on("click", function () {
      if ($(this).hasClass("is-paused")) {
        document.getElementById(thisVideo.videoid).play();
        $(this).removeClass("is-paused");
      } else {
        document.getElementById(thisVideo.videoid).pause();
        $(this).addClass("is-paused");
      }
    });
  }

  adjustMedia() {
    objectFit(this.$media[0]);
  }
}
