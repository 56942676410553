import { createStore, applyMiddleware, compose } from "redux";

export default function configureStore(
  rootReducer,
  initialState = {},
  middlewares = {},
) {
  const middlewareEnhancer = applyMiddleware(...middlewares);

  // https://github.com/zalmoxisus/redux-devtools-extension#1-with-redux
  // Apply devToolsExtension if present on client, otherwise, just pass through an Identity function
  const devToolEnhancer = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (x) => x;

  // build our store!
  return createStore(
    rootReducer,
    initialState,
    compose(middlewareEnhancer, devToolEnhancer),
  );
}
