import $ from "jquery";
import { breakpoint } from "../breakpoint";
import Vimeo from "@vimeo/player";
import Youtube from "youtube-player";

let throttler;

/*
 * BleedHeroVideo
 * Player and effect management for videos in Bleed Heroes and Experience Grids
 */
export class BleedHeroVideo {
  constructor($el) {
    this.$el = $el;
    this.$trigger = this.$el.find(".js-bleed-video__trigger");
    this.$imagewrapper = this.$el.find(".js-bleed-video__image");
    this.$body = this.$el.find(".js-bleed-video__body");
    this.$bodyTop = this.$body.find(".bleed-hero__body-top");
    this.$bodyBottom = this.$body.find(".bleed-hero__body-bottom");
    this.bodyOffset = 0;
    this.currentBreakpoint = "";

    this.type = this.$body.length > 0 ? "bleed" : "experience";

    this.id = this.$el.data("id");
    this.videoid = this.$el.data("video");
    this.videotype = this.$el.data("videotype");
    this.videourl = this.$el.data("videourl");

    this.player = {};

    this.init();
  }

  /* Load video */
  init() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      thisVideo.player = new Vimeo(thisVideo.id, { url: thisVideo.videourl });
    } else {
      // Assume it's YouTube
      thisVideo.player = Youtube(thisVideo.id, {
        videoId: thisVideo.videoid,
        playerVars: {
          rel: "0",
        },
      });
    }
    thisVideo.getHeroBodyOffset();
    thisVideo.bindEvents();
  }

  getHeroBodyOffset() {
    let thisVideo = this;
    if (thisVideo.$bodyTop.length > 0) {
      thisVideo.bodyOffset = parseInt(thisVideo.$bodyTop.css("marginTop"));
    } else if (thisVideo.$bodyBottom.length > 0) {
      thisVideo.bodyOffset = parseInt(thisVideo.$bodyBottom.css("marginTop"));
    }
  }

  handleStop() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      this.player.pause();
    } else {
      this.player.stopVideo();
    }
  }

  handleStart() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      this.player.play();
    } else {
      this.player.playVideo();
    }
  }

  closeVideo(mode = "reset") {
    let thisVideo = this;
    thisVideo.handleStop();

    if (mode === "reset") {
      // Resetting (no animation)

      thisVideo.$el.find("iframe").hide();
      thisVideo.$imagewrapper.css({
        top: "0",
      });
      thisVideo.$el.css({
        paddingBottom: "",
      });

      // Reset to default top margin
      thisVideo.$bodyTop.css({
        marginTop: "",
      });
      thisVideo.$bodyBottom.css({
        marginTop: "",
      });

      // Then get the top margin offset for this breakpoint
      thisVideo.getHeroBodyOffset();

      thisVideo.$el.removeClass("in-play-mode");
    } else {
      // Animating!
      // Calculate new heights and animate "curtain drop" effect

      if (
        thisVideo.currentBreakpoint === "xxsmall" ||
        thisVideo.currentBreakpoint === "xsmall" ||
        thisVideo.currentBreakpoint === "small"
      ) {
        if (thisVideo.$bodyTop.length > 0) {
          thisVideo.$bodyTop.animate(
            {
              marginTop: thisVideo.bodyOffset,
            },
            1000,
            "linear",
          );
        } else if (thisVideo.$bodyBottom.length > 0) {
          thisVideo.$bodyBottom.animate(
            {
              marginTop: thisVideo.bodyOffset,
            },
            1000,
            "linear",
          );
        }
      } else {
        thisVideo.$el.animate(
          {
            paddingBottom:
              Math.floor(
                parseInt(thisVideo.$el.css("padding-bottom")) -
                  parseInt(thisVideo.$body.css("padding-bottom")) -
                  thisVideo.$body.height(),
              ) + "px",
          },
          1000,
          "linear",
        );
      }

      thisVideo.$imagewrapper.animate(
        {
          top: "0",
        },
        1000,
        "linear",
        function () {
          /* Hide video iFrame to prevent inadvertant keyboard navigation while not visible */
          thisVideo.$el.find("iframe").hide();
        },
      );
    }
  }

  openVideo() {
    let thisVideo = this;
    /* Show video frame */
    thisVideo.$el.find("iframe").show();

    /* Calculate new heights and animate "curtain raise" effect */

    if (
      thisVideo.currentBreakpoint === "xxsmall" ||
      thisVideo.currentBreakpoint === "xsmall" ||
      thisVideo.currentBreakpoint === "small"
    ) {
      if (thisVideo.$bodyTop.length > 0) {
        thisVideo.$bodyTop.animate(
          {
            marginTop: 0,
          },
          1000,
          "linear",
        );
      } else if (thisVideo.$bodyBottom.length > 0) {
        thisVideo.$bodyBottom.animate(
          {
            marginTop: 0,
          },
          1000,
          "linear",
        );
      }
    } else {
      thisVideo.$el.animate(
        {
          paddingBottom:
            Math.ceil(
              thisVideo.$body.height() +
                parseInt(thisVideo.$body.css("padding-bottom")) +
                parseInt(thisVideo.$el.css("padding-bottom")),
            ) + "px",
        },
        1000,
        "linear",
      );
    }

    thisVideo.$imagewrapper.animate(
      {
        top: "-100%",
      },
      1000,
      "linear",
      function () {
        if (thisVideo.$el.offset().top < $(window).scrollTop()) {
          $("html, body").animate(
            { scrollTop: thisVideo.$el.offset().top + "px" },
            500,
          ); /* get the video in view */
        }
        /* Trigger video play when animations have completed */
        thisVideo.handleStart();
      },
    );
  }

  bindEvents() {
    let thisVideo = this;
    thisVideo.currentBreakpoint = breakpoint();

    /* Reset trigger point on resize */
    $(window).on("resize", (event) => {
      if (throttler) {
        window.clearTimeout(throttler);
      }
      throttler = setTimeout(() => {
        thisVideo.currentBreakpoint = breakpoint();
        /* Reset video behaviors on resize */
        thisVideo.closeVideo();
      }, 400);
    });

    thisVideo.$trigger.on("click", function (e) {
      e.preventDefault();

      thisVideo.$el.toggleClass("in-play-mode");

      if (thisVideo.$el.hasClass("in-play-mode")) {
        thisVideo.openVideo();
      } else {
        thisVideo.closeVideo("animate");
      }
    });
  }
}
