import PropTypes from "prop-types";
import React from "react";
import EventTypeaheadGroup from "../Events/EventTypeaheadGroup.jsx";

export default class VisitFacetList extends React.Component {
  constructor(props) {
    super(props);
  }

  clearAll() {
    this.props.handleClearAll();
  }

  renderFacetGroups() {
    return this.props.facets.map((group, i) => {
      if (group.values.length === 0 && group.childGroups.length === 0) {
        return null;
      }

      switch (group.type) {
        default:
          return (
            <EventTypeaheadGroup
              key={group.id}
              handleFacetChange={this.props.handleFacetChange}
              handleClearGroup={this.props.handleClearGroup}
              group={group}
            />
          );
      }
    });
  }

  render() {
    const FacetGroups = this.renderFacetGroups();

    return <div className="visit-facets">{FacetGroups}</div>;
  }
}

const { string, arrayOf, bool, shape, number, oneOfType, func } = PropTypes;
VisitFacetList.propTypes = {
  title: string,
  mobileFiltersShown: bool,
  hideMobileFacets: func,
  facets: arrayOf(shape({ id: oneOfType([number, string]) })),
  numOpenGroups: number,
  handleFacetChange: func.isRequired,
  handleClearGroup: func.isRequired,
  handleClearAll: func.isRequired,
};

VisitFacetList.defaultProps = {
  facets: [],
  numOpenGroups: null,
};
