import $ from "jquery";
import Vimeo from "@vimeo/player";
import Youtube from "youtube-player";

export class Video {
  constructor($el) {
    this.$el = $el;
    this.$trigger = this.$el.find(".js-video__trigger");

    this.id = this.$el.data("id");
    this.videoid = this.$el.data("video");
    this.videotype = this.$el.data("videotype");
    this.videourl = this.$el.data("videourl");

    this.player = {};

    this.init();
  }

  init() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      thisVideo.player = new Vimeo(thisVideo.id, {url: thisVideo.videourl});

      thisVideo.bindEvents();
    }
    else {
      // Assume it's YouTube
      thisVideo.player = Youtube(thisVideo.id, {
        videoId: thisVideo.videoid,
        playerVars: {
          rel: "0"
        }
      });

      thisVideo.bindEvents();
    }
  }

  handleStart() {
    let thisVideo = this;

    if (thisVideo.videotype === "vimeo") {
      this.player.play();
    }
    else {
      this.player.playVideo();
    }
  }

  bindEvents() {
    let thisVideo = this;

    thisVideo.$trigger.on("click", function(e) {
      e.preventDefault();

      thisVideo.$trigger.hide();
      thisVideo.handleStart();
      thisVideo.$el.addClass("in-play-mode");
    });
  }
}
