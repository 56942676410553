/**
 * clickableCard - This function is used to avoid repeated links to the same
 * destination within a card component (for example, if a promo item needs its
 * image, title, and CTA to all link to the same URL).
 *
 * To use this script:
 * 1. Add a CSS class of .js-clickable-card to the card element.
 * 2. Include one main link inside the card element.  If more than one link is
 * present, add a CSS class of .js-clickable-card__link to the main link that
 * should apply to multiple clickable items.
 * 3. If the entire card should be clickable, that's all you need to do!
 * If you want to manually specify what should be clickable, add a CSS class
 * of .js-clickable-card-target to any element that should trigger a click
 * on the main link.
 * 4. The script will add a CSS class of .clickable-card--initialized,
 * which automatically adds a pointer cursor to indicate "clickability".
 * If the entire card is clickable, it will add a CSS class of
 * .clickable-card--whole-card.  Both of these classes can be used to
 * create more custom hover effects via CSS.
 *
 * @param {Event}       e        Click event from onClick
 * @param {HTMLElement} el       The card element the event is attached to
 *
 * @return {void} Nothing
 */

export default (el) => {
  function bindEvents(mainLink) {
    el.addEventListener("click", (e) => {
      triggerMainLink(e, el, mainLink);
    });
  }

  // Get the main link element
  // We'll look for a class of "js-clickable-card__link"
  // If we dont' find it, we'll fall back to the first link found
  const mainLink = el.querySelector(".js-clickable-card__link")
    ? el.querySelector(".js-clickable-card__link")
    : el.getElementsByTagName("a")[0];

  // Don't initialize if we're in the page editor,
  // or if there's no link to click!
  if (
    document.querySelector(".is-page-editor") ||
    mainLink.length > 1 ||
    !mainLink
  ) {
    return;
  }

  // Add a classname to indicate the whole card is clickable,
  // if no elements within have the .js-clickable-card__target class
  const clickableCardTargets = el.querySelectorAll(
    ".js-clickable-card__target",
  );
  if (clickableCardTargets.length === 0) {
    el.classList.add("clickable-card--whole-card");
  }

  el.classList.add("clickable-card--initialized");
  bindEvents(mainLink);
};

/**
 * triggerMainLink - trigger a link click if a click is registered on certain
 * clickable DOM objects within the specified container element.
 *
 * @param {Event}       e        Click event from onClick
 * @param {HTMLElement} el       The container element to check for clicks
 * @param {mainLink}    el       The link to trigger
 *
 * @return {void} Nothing
 */
const triggerMainLink = (e, el, mainLink) => {
  // Get the element that triggered the click
  const clickTarget = e.target;

  if (
    (el.classList.contains("clickable-card--whole-card") ||
      clickTarget.classList.contains("js-clickable-card-target")) &&
    clickTarget !== mainLink &&
    clickTarget.tagName !== "A"
  ) {
    mainLink.click();
  }
};
