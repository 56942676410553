import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

export default class EventItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemWrapper = null;
  }

  componentDidMount() {
    this.itemWrapper.className = this.itemWrapper.className + " is-visible";
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return moment(date).format("MMMM D, YYYY");
  }

  render() {
    const {
      allDay,
      contentUrl,
      endDate,
      full,
      image,
      imageAlt,
      location,
      startDate,
      title,
    } = this.props;

    const formattedCompare = (date) => {
      return moment(date).format("YYYYMMDD");
    };

    const formattedDay = (date) => {
      return moment(date).format("DD");
    };

    const formattedDayOfWeek = (date) => {
      return moment(date).format("dddd");
    };

    const formattedMonth = (date) => {
      return moment(date).format("MMM");
    };

    const formattedYear = (date) => {
      return moment(date).format("YYYY");
    };

    const formattedTime = (date) => {
      return moment(date).format("h:mm A");
    };

    const followLink = () => {
      window.location = contentUrl;
    };

    return (
      <div className="event-list-item" onClick={followLink}>
        <div className="event-list-item__date">
          {startDate && (
            <span className="event-list-item__date-day-of-week">
              {formattedDayOfWeek(startDate)}
            </span>
          )}
          {startDate && (
            <span className="event-list-item__date-month-day">
              {formattedMonth(startDate)} {formattedDay(startDate)}
            </span>
          )}
          {startDate && formattedYear(startDate) != formattedYear(endDate) && (
            <span className="event-list-item__date-year">
              {formattedYear(startDate)}
            </span>
          )}
          {formattedCompare(startDate) !== formattedCompare(endDate) && (
            <span className="event-list-item__divider">-</span>
          )}
          {endDate &&
            formattedCompare(startDate) !== formattedCompare(endDate) && (
              <span className="event-list-item__date-day-of-week">
                {formattedDayOfWeek(endDate)}
              </span>
            )}
          {endDate &&
            formattedCompare(startDate) !== formattedCompare(endDate) && (
              <span className="event-list-item__date-month-day">
                {formattedMonth(endDate)} {formattedDay(endDate)}
              </span>
            )}
          {endDate && (
            <span className="event-list-item__date-year">
              {formattedYear(endDate)}
            </span>
          )}
        </div>

        {allDay && <span className="event-list-item__time">All Day</span>}
        {!allDay && (
          <span className="event-list-item__time">
            {startDate && (
              <span className="event-list-item__time-entry">
                {formattedTime(startDate)}
                {endDate &&
                  formattedTime(startDate) !== formattedTime(endDate) &&
                  " -"}
              </span>
            )}
            {endDate && formattedTime(startDate) !== formattedTime(endDate) && (
              <span className="event-list-item__time-entry">
                {formattedTime(endDate)}
              </span>
            )}
          </span>
        )}
        <span className="event-list-item__summary">
          {image && (
            <span className="event-list-item__image">
              <img src={image} alt={imageAlt} />
            </span>
          )}
          <span className="event-list-item__body">
            {full && <span className="event-list-item__full">Full</span>}
            <span className="event-list-item__title">
              <a ref={(el) => (this.itemWrapper = el)} href={contentUrl}>
                {title}
              </a>
            </span>
            <span className="event-list-item__location">
              <span>{location}</span>
            </span>
          </span>
        </span>
      </div>
    );
  }
}

const { bool, number, shape, string } = PropTypes;
EventItem.propTypes = {
  allDay: bool,
  contentUrl: string.isRequired,
  full: bool,
  index: number,
  image: string,
  imageAlt: string,
  endDate: string,
  location: string,
  query: shape({
    q: string,
    sortBy: string,
    page: number,
    perPage: number,
    pageId: string,
  }),
  startDate: string,
  title: string.isRequired,
};
