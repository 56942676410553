import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { isLastPage } from "../../paging";
import VideoGridItem from "../Videos/VideoGridItem.jsx";
import LoadMore from "../LoadMore.jsx";
import LoadingSpinner from "../LoadingSpinner.jsx";
import { VideoGrid } from "../../../video/VideoGrid.js";
import $ from "jquery";

import {
  RESULTS_DISPLAY_REFRESH,
  RESULTS_DISPLAY_APPEND,
} from "../../redux/resultsDisplay";

const VideoResultsList = (props) => {
  const {
    results,
    resultsDisplayStrategy,
    totalResults,
    loading,
    nextPage,
    loadAllPages,
    query,
    dictionary,
    searchId,
  } = props;

  const [isViewAll, setIsViewAll] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);

  const isLast = isLastPage(totalResults, query.perPage, query.page);

  const searchLinkTarget = searchId + "-jumptarget";

  const gridEl = useRef(null);
  let videoGrid = null;

  // Dev note:  All the ARIA, animation/transitions, and event
  // binding are being handled by the VideoGrid.js script, since that's
  // functionality shared between the static and the dynamic Video Grids.

  // Initialize the video grid vanilla scripting
  useEffect(() => {
    if (!loading) {
      videoGrid = new VideoGrid($(gridEl.current));
      videoGrid.init();
    }

    return function cleanup() {
      if (videoGrid !== null) {
        videoGrid.destroy();
        videoGrid = null;
      }
    };
  }, [loading]);

  // When the View More/All button is clicked, set keyboard focus to a video
  // right before it, so keyboard users don't have to start over
  useEffect(() => {
    if (moreLoading) {
      // Find the last of the currently loaded videos and focus it
      const gridWrapper = document.getElementById(searchLinkTarget);
      const videoTriggersBeforeLoad = gridWrapper.querySelectorAll(
        ".js-video-grid__trigger",
      );
      const currentLastVideoTrigger = findLastNode(videoTriggersBeforeLoad);
      currentLastVideoTrigger.focus();
      setMoreLoading(false);
    }
  }, [moreLoading]);

  function wrapperClass() {
    const isRefreshing =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
    const isAppending =
      loading && resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
    return classnames("search-results", {
      "is-refreshing": isRefreshing,
      "is-appending": isAppending,
    });
  }

  function findLastNode(nodeList) {
    return nodeList[nodeList.length - 1];
  }

  function handleLoadMore() {
    setMoreLoading(true);
    if (!isViewAll) {
      nextPage();
      setIsViewAll(true);
    } else {
      loadAllPages();
    }
  }

  function renderPagingControl() {
    if (!isLast) {
      return (
        <LoadMore
          handleClick={handleLoadMore}
          totalPages={totalResults}
          dictionary={dictionary}
          isLoadAll={isViewAll}
          loading={loading}
        />
      );
    }
  }

  return (
    <section className={wrapperClass()}>
      <div className="search-results__item-wrapper" id={searchLinkTarget}>
        {loading && (
          <div className="search-results__loader">
            <LoadingSpinner />
          </div>
        )}
        <div className="search-results__items">
          <div ref={gridEl} className="video-grid">
            <div className="layout-columns layout-columns--4-up layout-columns--skinny-gutter">
              {results.map((item, i) => (
                <VideoGridItem
                  key={i}
                  query={query}
                  vidGridPlayMsg={dictionary.vidGridPlayMsg}
                  {...item}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {renderPagingControl()}
    </section>
  );
};

const { arrayOf, string, object, func, bool, number, shape, oneOfType } =
  PropTypes;

VideoResultsList.propTypes = {
  results: arrayOf(
    shape({
      id: oneOfType([number, string]),
    }),
  ),
  resultsDisplayStrategy: number,
  totalResults: number.isRequired,
  loading: bool,
  nextPage: func,
  loadAllPages: func,
  query: shape({
    q: string,
    sortBy: string,
    page: number,
    perPage: number,
    pageId: string,
  }),
  pagination: bool,
  dictionary: object,
  searchId: string,
  // alertbanner: object,
  // selectedFacets: arrayOf(object).isRequired,
  // sorters: arrayOf(
  //   shape({
  //     id: oneOfType([number, string]).isRequired,
  //     label: string.isRequired,
  //     value: string.isRequired,
  //     direction: oneOfType([bool, string]),
  //     isActive: bool.isRequired,
  //  })
  // ),
  // listTitle: string,
  // isFirstLoad: bool,
  // changeSort: func,
  // showMobileFacets: func,
  // dismissFacet: func,
};

VideoResultsList.defaultProps = {
  selectedFacets: [],
};

export default VideoResultsList;
