import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";
import DateQuickPick from "../components/DateQuickPick.jsx";
import moment from "moment";
import classNames from "classnames";
import { breakpoint } from "../../breakpoint";

export default class DateFacets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreakpoint: breakpoint(),
      datesOpen: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.updateBreakpoint);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateBreakpoint);
  };

  updateBreakpoint = () => {
    const { currentBreakpoint } = this.state;
    const newBreakpoint = breakpoint();

    if (currentBreakpoint !== newBreakpoint) {
      // Only update if we're actually switching breakpoints
      // This avoids issues like filter dropdowns triggering viewport changes on selection on Android phones
      this.setState({
        ...this.state,
        currentBreakpoint: breakpoint(),
      });
    }
  };

  handleChangeEnd = (date) => {
    let { handleDateChange, startDate } = this.props;
    let newEndDate = moment.isMoment(date) ? date.endOf().format() : null;

    handleDateChange(startDate, newEndDate);
  };

  handleNativeChangeEnd = (evt) => {
    let { handleDateChange, startDate } = this.props;
    let newEndDate = evt.target.value
      ? moment(evt.target.value).endOf().format()
      : null;

    handleDateChange(startDate, newEndDate);
  };

  handleChangeStart = (date) => {
    let { endDate, handleDateChange } = this.props;
    let newStartDate = moment.isMoment(date) ? date.startOf().format() : null;

    handleDateChange(newStartDate, endDate);
  };

  handleNativeChangeStart = (evt) => {
    let { endDate, handleDateChange } = this.props;
    let newStartDate = evt.target.value
      ? moment(evt.target.value).startOf().format()
      : null;

    handleDateChange(newStartDate, endDate);
  };

  handleToggleDateOpen = () => {
    this.setState({
      datesOpen: !this.state.datesOpen,
    });
  };

  renderCalendarIcon = () => {
    return (
      <span className="search-listing__icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <g fill="none" fillRule="evenodd" transform="translate(-2 -2)">
            <path d="M2.00000007,20.3333331 L2.00000007,20.3333334 C2.00000021,21.2538083 2.74619257,22 3.66666674,22 L20.3333334,22 C21.2538083,22 22,21.2538083 22,20.3333334 L22,11.1666668 L2,11.1666668 L2.00000007,20.3333331 Z M20.3333334,3.66666665 L17.8333334,3.66666665 L17.8333334,2.83333332 C17.8333334,2.37309583 17.4602376,2 17.0000001,2 C16.5397626,2 16.1666667,2.37309583 16.1666667,2.83333332 L16.1666667,3.66666665 L7.83333341,3.66666665 L7.83333341,2.83333332 C7.83333341,2.37309583 7.46023757,2 7.00000007,2 C6.53976257,2 6.16666674,2.37309583 6.16666674,2.83333332 L6.16666674,3.66666665 L3.66666674,3.66666665 C2.74619167,3.66666669 2,4.41285914 2,5.33333329 C2,5.33333329 2,5.33333329 2,5.33333329 L2,9.49999991 L22,9.49999991 L22,5.33333329 C22,4.4128583 21.2538075,3.66666665 20.3333333,3.66666665 Z M7.00000007,7.83333326 C6.53976254,7.83333324 6.1666667,7.46023743 6.1666667,6.99999994 C6.16666672,6.53976244 6.53976254,6.16666661 7.00000004,6.16666661 C7.46023754,6.16666663 7.83333337,6.53976244 7.83333337,6.99999994 C7.83333337,7.46023739 7.46023754,7.83333322 7.00000004,7.83333322 Z M17.0000001,7.83333326 C16.5397625,7.83333324 16.1666667,7.46023743 16.1666667,6.99999994 C16.1666667,6.53976244 16.5397625,6.16666661 17,6.16666661 C17.4602375,6.16666663 17.8333334,6.53976244 17.8333334,6.99999994 C17.8333334,7.46023739 17.4602375,7.83333322 17,7.83333322 Z" />
          </g>
        </svg>
      </span>
    );
  };

  render() {
    let {
      dateConstraints,
      dateQuickPicks,
      endDate,
      handleDateChange,
      idmodifier,
      searchId,
      showCheckbox,
      skipLinkText,
      startDate,
    } = this.props;
    let { currentBreakpoint, datesOpen } = this.state;
    let searchLinkTarget = "#" + searchId + "-jumptarget";
    let startDateMoment =
      !startDate || startDate === "*" ? null : moment(startDate);
    let endDateMoment = !endDate || endDate === "*" ? null : moment(endDate);
    let startDateNative = startDateMoment
      ? startDateMoment.format("YYYY-MM-DD")
      : null;
    let endDateNative = endDateMoment
      ? endDateMoment.format("YYYY-MM-DD")
      : null;
    let todayNative = moment().format("YYYY-MM-DD");
    let startpickerid = idmodifier
      ? "datepickerstartdate" + idmodifier
      : "datepickerstartdate";
    let endpickerid = idmodifier
      ? "datepickerenddate" + idmodifier
      : "datepickerenddate";
    let dateConstraintStart = dateConstraints.start
      ? moment(dateConstraints.start)
      : undefined;
    let dateConstraintEnd = dateConstraints.end
      ? moment(dateConstraints.end)
      : undefined;

    const toggleClass = classNames("search-listing__date-toggle", {
      "is-open": datesOpen,
    });

    return (
      <div className="search-listing__date-facets">
        <a
          className="visually-hidden focusable skip-link"
          href={searchLinkTarget}
        >
          {skipLinkText}
        </a>
        {currentBreakpoint !== "xxsmall" &&
          currentBreakpoint !== "xsmall" &&
          currentBreakpoint !== "small" &&
          showCheckbox && <h4>Date: </h4>}
        {currentBreakpoint !== "xxsmall" &&
          currentBreakpoint !== "xsmall" &&
          currentBreakpoint !== "small" && (
            <div className="search-listing__pickers">
              <div className="search-listing__date-quick-picks">
                <div className="date-quick-picks">
                  {dateQuickPicks.map((quickpick, i) => (
                    <DateQuickPick
                      key={i}
                      index={i}
                      endDate={endDate}
                      handleDateChange={handleDateChange}
                      idmodifier={idmodifier}
                      startDate={startDate}
                      showCheckbox={showCheckbox}
                      {...quickpick}
                    />
                  ))}
                </div>
              </div>
              <div className="search-listing__date-calendar-pickers">
                <div className="search-listing__date-calendar-picker search-listing__date-calendar-picker--start">
                  <DatePicker
                    minDate={dateConstraintStart}
                    maxDate={dateConstraintEnd}
                    dateFormat="M/D/YYYY"
                    id={startpickerid}
                    onChange={this.handleChangeStart}
                    selected={startDateMoment}
                  />
                  <label htmlFor={startpickerid}>
                    <span className="search-listing__picker-label">Start:</span>{" "}
                    {this.renderCalendarIcon()}
                  </label>
                </div>
                <div className="search-listing__date-calendar-picker-to">
                  {" "}
                  to{" "}
                </div>
                <div className="search-listing__date-calendar-picker search-listing__date-calendar-picker--end">
                  <DatePicker
                    minDate={dateConstraintStart}
                    maxDate={dateConstraintEnd}
                    dateFormat="M/D/YYYY"
                    id={endpickerid}
                    onChange={this.handleChangeEnd}
                    selected={endDateMoment}
                  />
                  <label htmlFor={endpickerid}>
                    <span className="search-listing__picker-label">End:</span>{" "}
                    {this.renderCalendarIcon()}
                  </label>
                </div>
              </div>
            </div>
          )}
        {(currentBreakpoint === "xxsmall" ||
          currentBreakpoint === "xsmall" ||
          currentBreakpoint === "small") && (
          <div className="search-listing__date-native-picker">
            <div>
              <button
                className={toggleClass}
                onClick={this.handleToggleDateOpen}
              >
                {!datesOpen && "All Dates"}
                {datesOpen && "Custom Dates"}
              </button>
            </div>
            {datesOpen && (
              <div>
                <div className="search-listing__date-calendar-picker search-listing__date-calendar-picker--start">
                  <input
                    type="date"
                    min={todayNative}
                    value={startDateNative}
                    onChange={this.handleNativeChangeStart}
                    id="nativestartdate"
                  />
                  <label htmlFor="nativestartdate">
                    <span className="search-listing__picker-label">Start:</span>{" "}
                    {this.renderCalendarIcon()}
                  </label>
                </div>
                <div className="search-listing__date-calendar-picker-to">
                  {" "}
                  to{" "}
                </div>
                <div className="search-listing__date-calendar-picker search-listing__date-calendar-picker--end">
                  <input
                    type="date"
                    min={todayNative}
                    value={endDateNative}
                    onChange={this.handleNativeChangeEnd}
                    id="nativeenddate"
                  />
                  <label htmlFor="nativeenddate">
                    <span className="search-listing__picker-label">End:</span>{" "}
                    {this.renderCalendarIcon()}
                  </label>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const { bool, string, arrayOf, func, object } = PropTypes;
DateFacets.propTypes = {
  searchId: string,
  skipLinkText: string,
  startDate: string,
  endDate: string,
  handleDateChange: func,
  dateQuickPicks: arrayOf(object),
  idmodifier: string,
  showCheckbox: bool,
  dateConstraints: object,
};

DateFacets.defaultProps = {
  facets: [],
};
