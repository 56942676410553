import $ from "jquery";

export class toggleText {
  constructor($el) {
    this.$el = $el;
    this.$toggleButton = this.$el.find(".toggleButton");
    this.$moreText = this.$el.find(".notice__description");
    this.$isShown = this.$moreText.attr("data-toggle-more-text");
    this.bindEvents();
  }
  // Add a click event listener to the button

  bindEvents() {
    const thisListing = this;
    const maxHeight = thisListing.$moreText.css("max-height");
    const overflow = thisListing.$moreText.css("overflow");
    const webkitBoxOrient = thisListing.$moreText.css("-webkit-box-orient");
    var hidingText;
    CheckIfTextIsHidden();
    ToggleMoreTest(thisListing.$isShown);


    $(window).on("resize", function () {
      CheckIfTextIsHidden();
      
    });

    function CheckIfTextIsHidden() {
      let scrollH = $(thisListing.$moreText).prop('scrollHeight');
      let clientH = $(thisListing.$moreText).prop('clientHeight');

      if(scrollH > clientH ){
        hidingText = true;
        HideCollapseButton(0);
      } else {
        hidingText = false;
        HideCollapseButton(1);
      }
    }

    function HideCollapseButton(tf) {
      
      if (tf == 0) {
        thisListing.$toggleButton.css("display", "block");
      } else {
        thisListing.$toggleButton.css("display", "none");
      }
    }

    function ToggleMoreTest(tf) {
      if (tf == 0) {
        // If it's NOT visible, show the rest of text and change the button text
        thisListing.$moreText.css("overflow", "visible");
        thisListing.$moreText.css("max-height", "none");
        thisListing.$moreText.css("-webkit-box-orient", "none");
        thisListing.$moreText.css("display", "block");
        thisListing.$toggleButton.text("« Collapse");
        thisListing.$isShown = true;
        thisListing.$moreText.attr("data-toggle-more-text", true);

      } else {
        // If it's showing, hide change the button text
        thisListing.$moreText.css("overflow", overflow);
        thisListing.$moreText.css("max-height", maxHeight);
        thisListing.$moreText.css("-webkit-box-orient", webkitBoxOrient);

        thisListing.$moreText.css("display", "-webkit-box");
        thisListing.$toggleButton.text("Continue Reading »");
        thisListing.$isShown = false;
        thisListing.$moreText.attr("data-toggle-more-text", false);
      }
    }

    thisListing.$toggleButton.on("click", () => {
      // Check if the "more" text is currently displayed
      //hidingText = thisListing.$isShown;

        ToggleMoreTest(thisListing.$isShown);

      
    });

  }

}
